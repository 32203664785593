import { useMutation, useQuery } from '@tanstack/react-query';
import axiosInstance from 'interceptor/axiosInstance';

const PeriodRemappingService = () => {
  const PeriodMappingDropDown = ({ provider = null, apiUrl = null, itemDependsOn = null }: any) => {
    const { data, isLoading, isFetching } = useQuery({
      enabled: !!provider && !!apiUrl,
      queryKey: ['periodMappingDropDown', provider, apiUrl, itemDependsOn],
      queryFn: async () => {
        const modifiedUrl = provider !== 'nielsen' ? `period/${provider}` : 'period';
        const response = await axiosInstance.get(`remapping/${modifiedUrl}/${apiUrl}`);
        return response.data;
      }
    });

    return {
      data,
      isLoading,
      isFetching
    };
  };
  const UpdatedPeriodColumn = () => {
    const { mutate, data, isLoading, isError } = useMutation({
      mutationFn: async ({ provider = null, value = null, id = null }: any) => {
        const modifiedUrl = provider && provider !== 'nielsen' ? `period/${provider}` : 'period';
        const response = await axiosInstance.put(`remapping/${modifiedUrl}/${id}`, value);
        return response.data;
      }
    });

    return {
      mutate,
      data,
      isLoading,
      isError
    };
  };

  return {
    PeriodMappingDropDown,
    UpdatedPeriodColumn
  };
};

export default PeriodRemappingService;
