import React, { useEffect } from 'react';
import LibraryService from 'services/LibraryService';
import 'bootstrap/dist/css/bootstrap.css';
// import InputText from 'shared/components/InputText-component/inputText';

// import SearchInputField from 'shared/components/Search-input-field/search-input-field';
// import Cards from 'shared/components/Card-component/Cards';
import Cards from 'shared/components/Card-component/Cards';
import TabComonent from 'shared/components/Tab-component/Tabs';
// import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
// import ToastComponent from 'shared/components/Toast-component/Toast-component';
import Databar from 'shared/components/Databar-component/Databar';
// import TableComponent from 'shared/components/Table-component/Table-component';
// import NavComponent from 'shared/components/Nav-component/Nav-component';
import CustomButton from 'shared/components/Button-component/Button';
import './dummy.scss';
import FullScreenModal from 'shared/components/FullScreenModal';
import EdDropdown from 'shared/components/EDDropdown';

const DesignLibrary: React.FC = () => {
  const { loading } = LibraryService();
  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleSelect = (item:any) => {
    setSelectedItem(item);
  };

  const items = ['Item 1', 'Item 2', 'Item 3'];

  useEffect(() => {
    //  fetchLibraryList()
  }, []);
  const options = [
    { label: 'All(22)', value: '' },
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ];



  if (loading === true) return <div>Loading....</div>;

  return (
    <div className='mainDiv'>
      <div className='leftDiv'></div>
      <div className='rightDiv'>
        <div className='rightDiv-sub'>
          {/* <NavComponent /> */}
          {/* Design Library */}
          {/* <input /> */}
          {/* <br /> */}
          {/* <InputText /> */}
          <br />
          <div className="rowDiv">
          <CustomButton buttonNav >A-</CustomButton>
          <CustomButton buttonNav active>A</CustomButton>
          <CustomButton buttonNav >A+</CustomButton>
        </div>
          {/* <CustomButton buttonAction >Primary</CustomButton> */}
          <br />
          {/* <p>Search input field</p> */}
          {/* <SearchInputField placeholder='username' /> */}
          <br />
          <p>Cards</p>
          <div className='filter-container'>
          {/* <EdDropdown
        options={options}
        
        defaultOption={options[0].value}
        onSelect={handleSelect}
      /> */}
      {/* <EdDropdown
        options={options}
        
        defaultOption={options[0].value}
        onSelect={handleSelect}
      /> */}
      </div>
      <p>Selected item: {selectedItem}</p>
          <FullScreenModal />
          <div className='dashboard'>
          <Cards heading="validations" value={1298} footer1Heading="Excel" footer1Value={10} footer2Heading="Csv" footer2Value={60} />
          <Cards heading="Audit" value={62} footer1Heading="Recent" footer1Value={2} footer2Heading="Old" footer2Value={60} />
          <Cards heading="Volatile Engine" value={29} footer1Heading="Files" footer1Value={10} footer2Heading="Videos" footer2Value={60} />
          <Cards heading="Source Agnostic Ingestion" value={29} footer1Heading="Files" footer1Value={10} footer2Heading="Videos" footer2Value={19}/>
          <Cards heading="Data Purging" value={29} footer1Heading="Files" footer1Value={10} footer2Heading="Videos" footer2Value={19}/>
          </div>
          <p>Tab Component</p>
          <Databar DataProvider="Nielsan" TotalRecords={2000} successStatus={49} loadedOn="11/05/2021, 07:34 PM"/>
          <div className='dashboard'>
          {/* <TabComonent /> */}
          </div>
          {/* <p>Breadcrumb Component</p> */}
          {/* <BreadcrumbComponent /> */}
          {/* <p>Toast Component</p> */}
          {/* <ToastComponent /> */}
          {/* <p>Table Component</p>
          <TableComponent /> */}
        </div>
      </div>
    </div>
  );
};

export default DesignLibrary;
