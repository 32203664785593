export function ConstructDqMetaData(remarkWithPipe) {
  let remarks = remarkWithPipe.split('|');

  const data = [];

  let incompleteCheck = [];

  remarks.map((item) => {
    const check_description_fields = item.split(':');
    if (check_description_fields.length >= 4 && check_description_fields.length <= 5) {
      if (check_description_fields.length == 5) {
        var object = {
          check: check_description_fields[0],
          status: check_description_fields[3],
          fileName: check_description_fields[1],
          type: check_description_fields[2]
        };

        if (object.status == 'Success') {
          object['check_message'] = check_description_fields[4] ? check_description_fields[4] : null;
        } else {
          object.check_message = check_description_fields[4]?.replaceAll('@#', '(|)');
        }

        data.push(object);
      } else {
        if (
          check_description_fields[2].includes('LevelCheck') ||
          check_description_fields[2] != 'success' ||
          check_description_fields[2] != 'error'
        ) {
          var object = {
            check: check_description_fields[0],
            status: check_description_fields[2],
            fileName: check_description_fields[1]
          };

          if (object.status == 'Success') {
            object['check_message'] = check_description_fields[3] ? check_description_fields[3] : null;
          } else {
            object.check_message = check_description_fields[3]?.replaceAll('@#', '(|)');
          }

          incompleteCheck.push(object);
        }
      }
    }
  });

  incompleteCheck.map((item) => {
    let flag = true;
    data.map((elem) => {
      if (item.check === elem.check) {
        flag = false;
      }
    });
    if (flag) {
      item['type'] = 'FileLevelCheck';
      data.push(item);
    }
  });

  return data;
}
