export const getSmlPcatTableColumns = [
  { header: 'SML ID', accessorKey: 'SML_ID', enableResizing: true, size: 110, enableColumnDragging: true, enableEditing: false },
  { header: 'Category Id', accessorKey: 'category_id', size: 110, enableResizing: true, enableColumnDragging: true },
  { header: 'Created', accessorKey: 'created', size: 110, enableResizing: true, enableColumnDragging: true },

  { header: 'GTC Item PCAT Id', accessorKey: 'GTC_ITEM_PCAT_ID', size: 110, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Total', accessorKey: 'DP_TOTAL', size: 110, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Category Group', accessorKey: 'DP_CATEGORY_GRP', size: 190, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Category', accessorKey: 'DP_CATEGORY', size: 150, enableResizing: true, enableColumnDragging: true, filterVariant: 'autocomplete' },

  { header: 'DP Market', accessorKey: 'DP_MARKET', size: 150, enableResizing: true, enableColumnDragging: true, filterVariant: 'autocomplete' },

  { header: 'DP Segment', accessorKey: 'DP_SEGMENT', size: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Subsegment', accessorKey: 'DP_SUBSEGMENT', size: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Type', accessorKey: 'DP_TYPE', size: 100, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Format', accessorKey: 'DP_FORMAT', size: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'DP_FORM', accessorKey: 'DP_FORM', size: 140, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Core Benefit', accessorKey: 'DP_CORE_BENEFIT', size: 180, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Target Area', accessorKey: 'DP_TARGET_AREA', size: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Target User', accessorKey: 'DP_TARGET_USER', size: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Pack Type', accessorKey: 'DP_PACK_TYPE', size: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Platform', accessorKey: 'DP_PLATFORM', size: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Machine VS Hand', accessorKey: 'DP_MACHINE_VS_HAND', size: 190, enableResizing: true, enableColumnDragging: true },

  { header: 'DP Subformat', accessorKey: 'DP_SUBFORMAT', size: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'Monitor Reason', accessorKey: 'MONITOR_REASON', size: 170, enableResizing: true, enableColumnDragging: true },

  { header: 'Monitor Reason LD', accessorKey: 'MONITOR_REASON_LD', size: 180, enableResizing: true, enableColumnDragging: true },

  { header: 'Map Updated', accessorKey: 'map_updated', size: 150, enableResizing: true, enableColumnDragging: true },
  { header: 'PCAT Id', accessorKey: 'PCat_Id', size: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'MIP PCAT Name', accessorKey: 'MIP_PCAT_NAME', size: 180, enableResizing: true, enableColumnDragging: true },

  { header: 'MIP Item ATTRS Id', accessorKey: 'MIP_ITEM_ATTRS_ID', size: 190, enableResizing: true, enableColumnDragging: true },

  { header: 'AG004', accessorKey: 'AG004', size: 120, enableResizing: true, enableColumnDragging: true },

  { header: 'Max Attri Format', accessorKey: 'Maxattriformat', size: 190, enableResizing: true, enableColumnDragging: true },

  { header: 'AG011', accessorKey: 'AG011', size: 130, enableResizing: true, enableColumnDragging: true },

  { header: 'Max Attriifrinseoff', accessorKey: 'Maxattriifrinseoff', size: 180, enableResizing: true, enableColumnDragging: true },

  { header: 'AG003', accessorKey: 'AG003', size: 120, enableResizing: true, enableColumnDragging: true },

  { header: 'Max Attri Pack Type', accessorKey: 'Maxattripacktype', size: 190, enableResizing: true, enableColumnDragging: true },

  { header: 'AG006', accessorKey: 'AG006', size: 120, enableResizing: true, enableColumnDragging: true },

  {
    header: 'Max Attri Benefit Claim',
    accessorKey: 'Maxattribenefitclaim',
    minSize: 220,
    enableResizing: true,
    enableColumnDragging: true
  },

  { header: 'AG007', accessorKey: 'AG007', size: 120, enableResizing: true, enableColumnDragging: true },

  { header: 'Max Attri Target Use', accessorKey: 'Maxattritargetuse', minSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'AG002', accessorKey: 'AG002', size: 120, enableResizing: true, enableColumnDragging: true },

  {
    header: 'Max Attri Laundry Variants',
    accessorKey: 'Maxattrilaundryvariants',
    minSize: 240,
    enableResizing: true,
    enableColumnDragging: true
  },

  { header: 'AG010', accessorKey: 'AG010', size: 120, enableResizing: true, enableColumnDragging: true },

  {
    header: 'Max Attri If Concentrate',
    accessorKey: 'Maxattriifconcentrate',
    minSize: 240,
    enableResizing: true,
    enableColumnDragging: true
  },

  { header: 'AG001', accessorKey: 'AG001', size: 120, enableResizing: true, enableColumnDragging: true },

  { header: 'Max Attri Gender', accessorKey: 'Maxattrigender', size: 180, enableResizing: true, enableColumnDragging: true },

  { header: 'AG012', accessorKey: 'AG012', size: 120, enableResizing: true, enableColumnDragging: true },

  { header: 'Max Attri If High Suds', accessorKey: 'Maxattriifhighsuds', minSize: 240, enableResizing: true, enableColumnDragging: true },

  { header: 'AG009', accessorKey: 'AG009', size: 120, enableResizing: true, enableColumnDragging: true },

  {
    header: 'Max Attri If Antiperspirant',
    accessorKey: 'Maxattriifantiperspirant',
    minSize: 240,
    enableResizing: true,
    enableColumnDragging: true
  },

  { header: 'AG008', accessorKey: 'AG008', size: 120, enableResizing: true, enableColumnDragging: true },

  { header: 'Max Attri Formation', accessorKey: 'Maxattriformation', minSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'AG013', accessorKey: 'AG013', size: 120, enableResizing: true, enableColumnDragging: true },

  { header: 'Max Attri Life Stage', accessorKey: 'Maxattrilifestage', minSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'Max Attri Fat Content', accessorKey: 'Maxattrifatcontent', minSize: 200, enableResizing: true, enableColumnDragging: true }
];
