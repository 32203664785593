import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import axiosInstance from 'interceptor/axiosInstance';
import { ResponseTypes } from 'enums/responseTypes';

const ProductMappedServices = () => {
  const ProductMappedRecords = ({ filename, filters, sorting, status = null, provider = null }: any) => {
    const { data, isLoading, error, isPreviousData, isFetching, fetchNextPage, refetch } = useInfiniteQuery({
      queryKey: ['product', filename, status, filters, sorting, provider],
      queryFn: async ({ pageParam = 0 }) => {
        const params = {
          offset: pageParam * 30,
          limit: 30,
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename,
          Confidencelevel: status.toUpperCase()
        };

        const modifiedURL = provider ? `product/${provider.toLowerCase()}` : 'product';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/mapped`, { params });
        return response.data['result'];
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    });

    return {
      data,
      isLoading,
      error,
      refetch,
      isPreviousData,
      isFetching,
      fetchNextPage
    };
  };

  const ProductMappedRecordsCount = ({ filename, filters, sorting, status = null, provider = null }: any) => {
    const { data, isError, isLoading, refetch } = useQuery({
      queryKey: ['productMappedCount', filename, status, filters, sorting, provider],
      queryFn: async () => {
        const params = {
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename,
          Confidencelevel: status.toUpperCase()
        };
        const modifiedURL = provider ? `product/${provider.toLowerCase()}` : 'product';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/mapped/count`, { params });
        return response.data['total_count'];
      }
    });
    return {
      data,
      isLoading,
      isError,
      refetch
    };
  };

  const ProductUnprocessedRecords = ({ filename, filters, sorting, provider = null, status = null }: any) => {
    const { data, isLoading, error, isPreviousData, isFetching, fetchNextPage, refetch } = useInfiniteQuery({
      queryKey: ['product-unprocessed', filename, filters, sorting, provider, status],
      queryFn: async ({ pageParam = 0 }) => {
        const params = {
          offset: pageParam * 30,
          limit: 30,
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };

        const modifiedURL = provider ? `product/${provider.toLowerCase()}` : 'product';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/unprocessed`, { params });
        return response.data['result'];
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

    return {
      data,
      isLoading,
      error,
      refetch,
      isPreviousData,
      isFetching,
      fetchNextPage
    };
  };

  const ProductUnprocessedRecordsCount = ({ filename, filters, sorting, provider = null }: any) => {
    const { data, isError, isLoading, refetch } = useQuery({
      queryKey: ['productUnprocessedCount', filename, filters, sorting, provider],
      queryFn: async () => {
        const params = {
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };
        const modifiedURL = provider ? `product/${provider.toLowerCase()}` : 'product';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/unprocessed/count`, { params });
        return response.data['total_count'];
      }
    });
    return {
      data,
      isLoading,
      isError,
      refetch
    };
  };

  const downloadMappedProductRecords = ({ filename, provider = null }: any) => {
    const { isLoading: isDownloading, mutateAsync: triggerDownload } = useMutation({
      mutationFn: async () => {
        const modifiedURL = provider ? `product/${provider.toLowerCase()}` : 'product';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/mapped/download`, {
          params: { Filename: filename },
          responseType: ResponseTypes.BLOB
        });
        const contentDisposition = await response?.headers['content-disposition'];
        const matchName = contentDisposition.replace('attachment; filename=', '');
        const filenames = matchName.split('.')[0] + '.xlsx';

        const urlLink = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = urlLink;
        link.setAttribute('download', filenames);

        document.body.appendChild(link);
        link.click();
        return response;
      }
    });
    return {
      isDownloading,
      triggerDownload
    };
  };

  const downloadUnprocessedProductRecords = ({ filename, provider = null }: any) => {
    const { isLoading: isDownloading, mutateAsync: triggerDownload } = useMutation({
      mutationFn: async () => {
        const modifiedURL = provider ? `product/${provider.toLowerCase()}` : 'product';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/unprocessed/download`, {
          params: { Filename: filename },
          responseType: ResponseTypes.BLOB
        });
        const contentDisposition = await response?.headers['content-disposition'];
        const matchName = contentDisposition.replace('attachment; filename=', '');
        const filenames = matchName.split('.')[0] + '.xlsx';

        const urlLink = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = urlLink;
        link.setAttribute('download', filenames);

        document.body.appendChild(link);
        link.click();
        return response;
      }
    });
    return {
      isDownloading,
      triggerDownload
    };
  };

  const ProductUAOLMappedRecords = ({ filename, filters, sorting, status = null }: any) => {
    const { data, isLoading, error, isPreviousData, isFetching, fetchNextPage, refetch } = useInfiniteQuery({
      queryKey: ['product-uaol-processed', filename, status, filters, sorting],
      queryFn: async ({ pageParam = 0 }) => {
        const params = {
          offset: pageParam * 30,
          limit: 30,
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };

        const response = await axiosInstance.get(`smart-mapping/product/uaol-processed`, { params });
        return response.data['result'];
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    });

    return {
      data,
      isLoading,
      error,
      refetch,
      isPreviousData,
      isFetching,
      fetchNextPage
    };
  };

  const ProductUAOLUnprocessedRecords = ({ filename, filters, sorting, provider = null }: any) => {
    const { data, isLoading, error, isPreviousData, isFetching, fetchNextPage, refetch } = useInfiniteQuery({
      queryKey: ['product-uaol-unprocessed', filename, filters, sorting, provider],
      queryFn: async ({ pageParam = 0 }) => {
        const params = {
          offset: pageParam * 30,
          limit: 30,
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };

        const response = await axiosInstance.get(`smart-mapping/product/uaol-unprocessed`, { params });
        return response.data['result'];
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    });

    return {
      data,
      isLoading,
      error,
      refetch,
      isPreviousData,
      isFetching,
      fetchNextPage
    };
  };

  const ProductUAOLMappedRecordsCount = ({ filename, filters, sorting }: any) => {
    const { data, isError, isLoading, refetch } = useQuery({
      queryKey: ['productprocessedCount', filename, filters, sorting],
      queryFn: async () => {
        const params = {
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };
        const response = await axiosInstance.get(`smart-mapping/product/uaol-processed/count`, { params });
        return response.data['total_count'];
      }
    });
    return {
      data,
      isLoading,
      isError,
      refetch
    };
  };

  const ProductUAOLUnprocessedRecordsCount = ({ filename, filters, sorting }: any) => {
    const { data, isError, isLoading, refetch } = useQuery({
      queryKey: ['productUnprocessedCount', filename, filters, sorting],
      queryFn: async () => {
        const params = {
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };
        const response = await axiosInstance.get(`smart-mapping/product/uaol-unprocessed/count`, { params });
        return response.data['total_count'];
      }
    });
    return {
      data,
      isLoading,
      isError,
      refetch
    };
  };

  const downloadUAOLMappedProductRecords = ({ filename }: any) => {
    const { isLoading: isDownloading, mutateAsync: triggerDownload } = useMutation({
      mutationFn: async () => {
        const response = await axiosInstance.get(`smart-mapping/product/uaol-processed/download`, {
          params: { Filename: filename },
          responseType: ResponseTypes.BLOB
        });
        const contentDisposition = await response?.headers['content-disposition'];
        const matchName = contentDisposition.replace('attachment; filename=', '');
        const filenames = matchName.split('.')[0] + '.xlsx';

        const urlLink = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = urlLink;
        link.setAttribute('download', filenames);

        document.body.appendChild(link);
        link.click();
        return response;
      }
    });
    return {
      isDownloading,
      triggerDownload
    };
  };

  const downloadUAOLUnprocessedProductRecords = ({ filename }: any) => {
    const { isLoading: isDownloading, mutateAsync: triggerDownload } = useMutation({
      mutationFn: async () => {
        const response = await axiosInstance.get(`smart-mapping/product/uaol-unprocessed/download`, {
          params: { Filename: filename },
          responseType: ResponseTypes.BLOB
        });
        const contentDisposition = await response?.headers['content-disposition'];
        const matchName = contentDisposition.replace('attachment; filename=', '');
        const filenames = matchName.split('.')[0] + '.xlsx';

        const urlLink = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = urlLink;
        link.setAttribute('download', filenames);

        document.body.appendChild(link);
        link.click();
        return response;
      }
    });
    return {
      isDownloading,
      triggerDownload
    };
  };

  return {
    ProductMappedRecords,
    ProductUnprocessedRecords,
    downloadMappedProductRecords,
    downloadUnprocessedProductRecords,
    ProductMappedRecordsCount,
    ProductUnprocessedRecordsCount,
    ProductUAOLMappedRecords,
    ProductUAOLUnprocessedRecords,
    ProductUAOLMappedRecordsCount,
    ProductUAOLUnprocessedRecordsCount,
    downloadUAOLMappedProductRecords,
    downloadUAOLUnprocessedProductRecords
  };
};

export default ProductMappedServices;
