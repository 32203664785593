import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './smlPcat.scss';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import { Button, Flex, Tooltip, ActionIcon, Loader, Text } from '@mantine/core';
import {
  MRT_TableOptions,
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_Virtualizer,
} from 'mantine-react-table';
import { getSmlPcatTableColumns } from './columns';
import { SmlPcat as Smlpcat } from 'models/SmlPcat/smlPcat.model';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import SmlPcatService from 'services/AdminService/SMLPcatService/smlPcat.service';
import { IconCheck, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import MantineTable from 'shared/components/MantineTable';



const SmlPcat = () => {

  const tableColumns: any = getSmlPcatTableColumns;

  const [tableElement, setTableElement] = useState<any>(null)

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [tempData, setTempData] = useState<any>([]);
  const [modifiedRecords, setModifiedRecords] = useState({ created: [], edited: [], deleted: [] });

  //Api Calls
  const { fetchSmalPcatRecords, createSmlPcatRecord, deleteSmlPcatRecords, updateSmalPcatRecords } = SmlPcatService();

  const { data, isError, isLoading, refetch, fetchNextPage, isFetching, isPreviousData } = fetchSmalPcatRecords({ sorting, columnFilters });


  const { createIsLoading, createMutate } = createSmlPcatRecord()

  const { updateIsLoading, updateMutate } = updateSmalPcatRecords()

  const { deleteIsLoading, deleteMutate } = deleteSmlPcatRecords()

  const smlPcatData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result) ?? []
  },
    [data],
  );

  useEffect(() => {
    setTempData(smlPcatData ? smlPcatData : []);
  }, [data]);

  const totalDBRowCount = data?.pages?.[0]?.count ?? 0;
  const totalFetched = smlPcatData.length;


  const handleClear = () => {
    setModifiedRecords({
      created: [],
      edited: [],
      deleted: []
    });
    setTempData(data ? data : []);
  };


  const handleSave: MRT_TableOptions<Smlpcat>['onEditingRowSave'] = async ({ values, table, row }) => {
    const editedRecords = [...modifiedRecords.edited, values];
    const editedIndex = row.index;
    setModifiedRecords((prev: any) => {
      const data = prev;
      data.edited = editedRecords;
      return data;
    });
    setTempData((prevTempData: any) => {
      const updatedTempData = prevTempData.map((record: any, index: any) => (index === editedIndex ? values : record));
      return updatedTempData;
    });
    table.setEditingRow(null);
  };

  const handleCreate: MRT_TableOptions<Smlpcat>['onCreatingRowSave'] = ({ values, exitCreatingMode }) => {
    const { SML_ID, ...rest } = values
    const createdRecords = [...modifiedRecords.created, rest];
    setModifiedRecords((prev: any) => {
      const data = prev;
      data.created = createdRecords;
      return data;
    });
    setTempData([values, ...tempData]);

    exitCreatingMode();
  };

  const handleSaveChanges = () => {
    modifiedRecords.created.length ? createMutate(modifiedRecords.created,
      {
        onSuccess: async (data) => {
          refetch()
          return (
            <>
              {
                notifications.show({
                  title: 'Success',
                  message: "Records Creation successfull",
                  color: 'green',
                  icon: <IconCheck />,
                  autoClose: 2000
                })
              }
            </>
          )
        },
        onError: async (data: any) => {
          refetch()
          return (
            <>
              {
                notifications.show({
                  title: 'Failure',
                  message: "Record Creation Unsuccessfull" + data.error.message,
                  color: 'green',
                  icon: <IconX />,
                  autoClose: 2000
                })
              }
            </>
          )
        }
      }
    ) : null;


    modifiedRecords.edited.length ? updateMutate(modifiedRecords.edited, {
      onSuccess: async (data) => {
        refetch()
        return (
          <>
            {
              notifications.show({
                title: 'Success',
                message: "Records Updation successfull",
                color: 'green',
                icon: <IconCheck />,
                autoClose: 2000
              })
            }
          </>
        )
      },
      onError: async (data: any) => {
        refetch()
        return (
          <>
            {
              notifications.show({
                title: 'Failure',
                message: "Updation Unsuccessfull " + data.error.message,
                color: 'green',
                icon: <IconX />,
                autoClose: 2000
              })
            }
          </>
        )
      }
    }) : null;

    modifiedRecords.deleted.length ? deleteMutate(modifiedRecords.deleted, {
      onSuccess: async (data) => {
        refetch()
        return (
          <>
            {
              notifications.show({
                title: 'Success',
                message: "Deletion successfull",
                color: 'green',
                icon: <IconCheck />,
                autoClose: 2000
              })
            }
          </>
        )
      },
      onError: async (data: any) => {
        refetch()
        return (
          <>
            {
              notifications.show({
                title: 'Failure',
                message: "Deletion Unsuccessfull " + data.error.message,
                color: 'green',
                icon: <IconX />,
                autoClose: 2000
              })
            }
          </>
        )
      },
    }


    ) : null;

    setModifiedRecords({
      created: [],
      edited: [],
      deleted: []
    });
  };




  const fetchMoreOnBottomReached = useCallback((containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      setTableElement(containerRefElement)

      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !isFetching &&
        totalFetched < totalDBRowCount
      ) {

        fetchNextPage();
      }
    }
  },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, columnFilters]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [tempData]);


  return (
    <DashboardContainer>
      <h3>SML PCAT</h3>
      <MantineTable
        data={tempData}
        columns={tableColumns}
        customActions={true}
        columnPinning={
          { left: ['mrt-row-actions'] }
        }
        enablePinning={true}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableStickyHeader={true}
        enablePagination={false}
        enableColumnActions={false}
        enableGlobalFilter={false}
        manualFiltering={true}
        manualSorting={true}
        onColumnFiltersChange={setColumnFilters}
        onSortingChange={setSorting}
        columnFilters={columnFilters}
        isLoading={isLoading}
        isFetching={isFetching}
        sorting={sorting}
        fetchMoreOnBottomReached={(val: any) => fetchMoreOnBottomReached(val)}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        tableContainerRef={tableContainerRef}
        isPreviousData={isPreviousData}
        mantineToolbarAlertBannerProps={
          isError
            ? {
              color: 'red',
              children: 'Error loading data'
            }
            : undefined
        }
        customActionButton={
          ({ row, table }: any) => (
            <Flex gap='md'>
              <Tooltip label='Edit' position='right'>
                <ActionIcon onClick={() => table.setEditingRow(row)}>
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <Tooltip label='Delete' position='left'>
                <ActionIcon
                  color='red'
                  onClick={() => {
                    const deleteRecords = [...modifiedRecords.deleted, row.original.SML_ID];
                    setModifiedRecords((prev: any) => {
                      const data = prev;
                      data.deleted = deleteRecords;
                      return data
                    });
                    tempData.splice(row.index, 1);
                    setTempData([...tempData]);
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
            </Flex>
          )
        }
        customRecordsButton={({ table }: any) => (
          <div style={{ display: 'flex', gap: '8px' }}>
            {
              !createIsLoading && !updateIsLoading && !deleteIsLoading ? (
                <>
                  <Button
                    onClick={() => {
                      table.setCreatingRow(true);
                      tableElement.scrollTop = 0;
                    }}
                  >
                    New Metadata
                  </Button>
                  <Button
                    color='green'
                    disabled={modifiedRecords.created.length === 0 && modifiedRecords.deleted.length === 0 && modifiedRecords.edited.length === 0}
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </Button>
                  <Button
                    color='red'
                    disabled={modifiedRecords.created.length === 0 && modifiedRecords.deleted.length === 0 && modifiedRecords.edited.length === 0}
                    onClick={handleClear}
                  >
                    Clear Changes
                  </Button>
                </>
              ) : <Loader size={38} />
            }

          </div>)
        }
        onEditingRowSave={handleSave}
        onCreatingRowSave={handleCreate}
        renderBottomToolbarCustomActions={() => {
          return (
            <div className='bottom-Action'>
              <p>Total Records : {totalDBRowCount}</p>
              <Text className='bottom-text'>
                Showing {totalFetched} of {totalDBRowCount} total rows.
              </Text>
            </div>
          );
        }
        }
        enableRowActions={true}
        enableEditing={true}
      />
    </DashboardContainer>
  );

}

export default SmlPcat;
