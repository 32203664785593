import React from 'react';

export const POSMarketMappedColumn = [
  {
    header: 'Extrenal',
    accessorKey: 'External',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Country',
        accessorKey: 'Country',
        size: 200
      },
      {
        header: 'File Name',
        accessorKey: 'FileName',
        size: 300
      },
      // {
      //   header: 'Date',
      //   accessorKey: 'Date',
      //   size: 220
      // },
      // {
      //   header: 'Retailer ID',
      //   accessorKey: 'RetailerID',
      //   size: 180
      // },
      // {
      //   header: 'Cell Coverage',
      //   accessorKey: 'CellCoverage',
      //   size: 240
      // }
    ]
  },
  {
    header: 'Internal',
    accessorKey: 'internal',
    size: 200,
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Direct Indirect',
        accessorKey: 'Direct_Indirect',
        size: 200
      },
      {
        header: 'ISO Country',
        accessorKey: 'ISOCountry',
        size: 180
      },
      // {
      //   header: 'Unique Tag',
      //   accessorKey: 'UniqueTag',
      //   size: 180
      // },
      // {
      //   header: 'Store Name',
      //   accessorKey: 'StoreName',
      //   size: 180
      // },
      // {
      //   header: 'Market Short',
      //   accessorKey: 'MarketShort',
      //   size: 180
      // },
      // {
      //   header: 'Market Long',
      //   accessorKey: 'MarketLong',
      //   size: 180
      // }
    ]
  }
];
