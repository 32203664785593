
import {serializable, alias, object, list, primitive} from 'serializr';

export class ConfidenceMapping { 

	@serializable(alias('provider', primitive()))
	provider?: string;

	@serializable(alias('high_count', primitive()))
	high?: number;

	@serializable(alias('medium_count', primitive()))
	medium?: number;

	@serializable(alias('low_count', primitive()))
	low?: number;

}