import React from 'react';

export const PeriodMappedColumn = [
  {
    header: 'Extrenal',
    accessorKey: 'External',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      // {
      //   header: 'External TAG',
      //   accessorKey: 'Tag',
      //   size: 140
      // },
      {
        header: 'External Short Desc',
        accessorKey: 'Short',
        size: 220
      },
      {
        header: 'External Long Description',
        accessorKey: 'Long',
        size: 260
      },
      // {
      //   header: 'Last Period',
      //   accessorKey: 'LastPeriodTagFormat',
      //   size: 140
      // }
    ]
  },
  {
    Header: 'Internal',
    accessorKey: 'internal',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Periodicity',
        accessorKey: 'Periodicity',
        size: 140
      },
      {
        header: 'Year',
        accessorKey: 'YearBr',
        size: 100
      },
      // {
      //   header: 'Quarter',
      //   accessorKey: 'QuarterBr',
      //   size: 100
      // },
      // {
      //   header: 'Month',
      //   accessorKey: 'MonthBr',
      //   size: 100
      // },
      // {
      //   header: 'Week',
      //   accessorKey: 'WeekBr',
      //   size: 100
      // },
      // {
      //   header: 'Start Date',
      //   accessorKey: 'PeriodStartDate',
      //   size: 150
      // },
      // {
      //   header: 'End Date',
      //   accessorKey: 'PeriodEndDate',
      //   size: 150
      // },
      // {
      //   header: 'Min Period Number',
      //   accessorKey: 'MinPeriodNumBr',
      //   size: 240
      // },
      // {
      //   header: 'Max Period Number',
      //   accessorKey: 'MaxPeriodNumBr',
      //   size: 240
      // },
      // {
      //   header: 'Country Week Start Day',
      //   accessorKey: 'WeekStartDayCountry',
      //   size: 280
      // },
      // ,
      // {
      //   header: 'Periodicity Identifier',
      //   accessorKey: 'PeriodicityIdentifier',
      //   size: 240
      // },
      // {
      //   header: 'Convention',
      //   accessorKey: 'Convention',
      //   size: 140
      // },
      // {
      //   header: 'Period Number',
      //   accessorKey: 'PeriodNumberBr',
      //   size: 140
      // }
    ]
  }
];
