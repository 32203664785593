import { useMsal } from '@azure/msal-react';
import useRedirect from 'shared/hooks/useRedirect';
import UserIcon from 'assets/icons/user.svg';
import AdminIcon from 'assets/icons/admin.svg';
import LogoutIcon from 'assets/icons/logout.svg';

const useNavConfig = () => {
  const { instance } = useMsal();

  const { redirectToAdminHome } = useRedirect();

  const handleLogout = () => instance.logout();

  const profileOptions = [
    {
      name: 'My Info',
      icon: UserIcon
    },
    {
      name: 'Admin',
      icon: AdminIcon,
      onClick: redirectToAdminHome
    },
    {
      name: 'Logout',
      icon: LogoutIcon,
      onClick: handleLogout
    }
  ];

  /* Comment for future use */
  // const languageDropdownOptions = [
  //   {
  //     value: 'english',
  //     label: 'English'
  //   },
  //   {
  //     value: 'arabic',
  //     label: 'Arabic'
  //   }
  // ];
  return {
    profileOptions
  };
};
export default useNavConfig;
