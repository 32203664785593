export const AppRoutes = {
  ROOT: '/',
  CHECKS: 'checks',
  ROLE_SELECTION: 'role-selection',
  VALIDATION_RECORDS: 'validation-records',
  VALIDATION_RECORD_DETAILS_NIELSEN: 'validation-record-details/nielsen/:id/:confidenceLevel',
  VALIDATION_RECORD_DETAILS_POS: 'validation-record-details/pos/:id/:confidenceLevel',
  VALIDATION_RECORD_DETAILS_RMS: 'validation-record-details/rms/:id/:confidenceLevel',
  FILE_VOLATALITY_RECORDS: 'file-volatility',
  FILE_VOLATILITY_HOME: 'reports',
  FILE_VOLATILITY_MAPPED: 'mapping/:id',
  FILE_VOLATILITY_MARKET_MAPPED: 'mapping/:id/market',
  OPERATION_GRAPH: '/home',
  LEADERSHIP_GRAPH: '/leadership',
  SSO_TEST: '/test',

  // fact routes
  VALIDATION: 'validations',
  VALIDATION_FACT: 'fact',
  VALIDATION_FACT_DETAILS_NIELSEN: 'fact/nielsen/:id/:confidenceLevel',
  VALIDATION_FACT_DETAILS_POS: 'fact/pos/:id/:confidenceLevel',
  VALIDATION_FACT_DETAILS_RMS: 'fact/rms/:id/:confidenceLevel',
  FILE_VOLATILITY_FACT_MAPPED: 'mapping/:id/fact',
  FILE_VOLATILITY_PERIOD_MAPPED: 'mapping/:id/period',

  // Period Dimensions routes
  VALIDATION_PERIOD: 'period',
  VALIDATION_PERIOD_DETAILS_NIELSEN: 'period/nielsen/:id/:confidenceLevel',
  VALIDATION_PERIOD_DETAILS_POS: 'period/pos/:id/:confidenceLevel',
  VALIDATION_PERIOD_DETAILS_RMS: 'period/rms/:id/:confidenceLevel',

  VALIDATION_MARKET: 'market',
  VALIDATION_MARKET_DETAILS_NIELSEN: 'market/nielsen/:id/:confidenceLevel',
  VALIDATION_MARKET_DETAILS_POS: 'market/pos/:id/:confidenceLevel',
  VALIDATION_MARKET_DETAILS_RMS: 'market/rms/:id/:confidenceLevel',

  DQ_CHECK: '/reports/dq-checks',

  //Configuration
  ADMIN: '/admin',
  GLOBAL_RMS_SML_MAPPING: '/admin/global-rms-sml-mapping',
  SML_PCAT: '/admin/global-rms-sml-mapping/sml-pcat',
  ACCEPTANCE_REPORT: '/reports/acceptance-report',
  ACCEPTANCE_REPORT_DETAILS: '/reports/acceptance-report/acceptance-report-details',
  GLOBAL_RMS_METADATA_MAPPING: '/admin/global-rms-metadata-mapping',
  FACT_METADATA: '/admin/global-rms-metadata-mapping/fact-metadata',
  CRITICAL_ATTRIBUTE: '/admin/global-rms-metadata-mapping/critical-attribute',
  CELL_CONTROL: '/admin/cell-control',
  MARKET_METADATA: '/admin/global-rms-metadata-mapping/market-metadata',
  PERIOD_METADATA: '/admin/global-rms-metadata-mapping/period-metadata',

  //INTELLIGENT_MAPPING ROUTES
  INTELLIGENT_MAPPING : '/checks/intelligent-mapping',
  INTELLIGENT_MAPPING_PRODUCT : 'product',
  INTELLIGENT_MAPPING_DIMENSION : '/checks/intelligent-mapping/:dimension',

  INTELLIGENT_MAPPING_DETAILS_LEVEL:'/checks/intelligent-mapping/:dimension/mapped',
  INTELLIGENT_MAPPING_DETAILS_STATUS:'/checks/intelligent-mapping/:dimension/:status',

};
  
