import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { removeFalsyKeys } from '../utils/removeFalsyKeys';

const useURLParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setHash = (hash: string) => {
    navigate({
      pathname: location.pathname,
      search: location.search,
      hash
    });
  };

  const getHash = () => location.hash.replaceAll('#', '');

  const getParams = (keys: string[]) => {
    const params: any = {};
    keys?.forEach((key) => {
      const value = createSearchParams(location.search).get(key);
      if (isNaN(value as any) || value === null || value === '') {
        params[key] = value;
      } else {
        params[key] = +(value || '');
      }
    });
    return params;
  };

  const setParams = (filters: any, hash?: string) => {
    const params = createSearchParams(removeFalsyKeys(filters));
    navigate({
      pathname: location.pathname,
      hash: hash ?? location.hash,
      search: params.toString()
    });
  };

  return {
    location,
    setHash,
    getHash,
    getParams,
    setParams
  };
};

export default useURLParams;
