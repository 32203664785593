import React from 'react';
import { Row, Col, Nav, Tab} from 'react-bootstrap';
import './VerticalTabs.scss';

// import Sonnet from '../../components/Sonnet';
import AboutSection from '../AboutSection';
import ToolsSection from '../ToolsSection';
import DataLogs from '../DataLogs';
const VerticalTabs = () => {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
      <Row className='tab-container-vertical'>
        <Col sm={3}>
          <Nav  className="flex-column tab-menu">
            <Nav.Item className='nav-item'>
              <Nav.Link eventKey="first" className='nav-link'>Data Logs</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Artefacts</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Data Harmonisation</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Artefacts</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Data Harmonisation</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <DataLogs />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <DataLogs />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default VerticalTabs;