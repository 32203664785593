import React, { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import './Buttons.scss';
import clsx from 'clsx';
import SkeletonLoader from '../SkeletonLoader';

interface ButtonProps {
  buttonNav?: boolean;
  buttonAction?: boolean;
  children: string | JSX.Element;
  active?: boolean;
  disabled?: boolean;
  primary?: boolean;
  status?: boolean;
  statusActive?: boolean;
  secondary?: boolean;
  height?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  SecondaryPressed?: boolean;
  skeletonLoading?: boolean;
  icon?: string;
  className?: string;
}

const CustomButton: React.FC<ButtonProps> = (props) => {
  const {
    children,
    buttonNav,
    active,
    disabled,
    primary,
    secondary,
    SecondaryPressed,
    status,
    statusActive,
    onClick,
    skeletonLoading,
    icon,
    className
  } = props;

  const getClassName = () =>
    clsx(
      buttonNav || active ? 'nav-font-buttons btn-styles' : 'btn-container primary',
      primary && 'primary',
      disabled && 'disabled',
      active && 'btn-active',
      secondary && 'secondary',
      SecondaryPressed && 'secondary pressed',
      status && 'status',
      statusActive && 'status pressed',
      className
    );

  return skeletonLoading ? (
    <SkeletonLoader />
  ) : (
    <Button variant={primary ? 'primary' : ''} onClick={onClick} className={getClassName()}>
      {icon && <img src={icon} alt='btn-icon' className='btn__icon' />}
      {children}
    </Button>
  );
};

export default CustomButton;
