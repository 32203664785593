import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import './VolatilityDashboard.scss';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import TabComponent from 'shared/components/Tab-component/Tabs';
import Status from './Status';
import Traffic from './Traffic';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import { useNavigate } from 'react-router';
import Summary from './Summary';

const VolatilityDashboard = () => {
  const navigate = useNavigate();

  return (
    <DashboardContainer>
      <Container fluid className='page-container'>
        <Row className='top-row'>
          <Col sm={6}>
            <div className='breadcrumb-container'>
              <p className='page-heading'>File Volatility</p>
              <BreadcrumbComponent
                links={[
                  { link: 'Checks', active: false, onClick: () => navigate(`/${AppRoutes.CHECKS}`) },
                  { link: 'File Volatility', active: true }
                ]}
              />
            </div>
          </Col>
          <TabComponent
            active='loadStatistics'
            components={[
              { key: 'traffic', title: 'Traffic', component: <Traffic /> },
              { key: 'status', title: 'Status', component: <Status /> },
              {
                key: 'loadStatistics',
                title: 'Load Statistics',
                component: <Summary />
              }
            ]}
          />
        </Row>
      </Container>
    </DashboardContainer>
  );
};

export default VolatilityDashboard;
