import React, { useEffect, useState } from 'react';
import ConstructMetaData from './constructMetaData';
import './tableRowExpand.scss';
import Table from '../DQCheckStatusGrid';
// import { Col, Row, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function TableRowExpand({ data }: any) {
  const [metaData, setMetaData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const metaData = ConstructMetaData({ data });
        setMetaData(metaData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (!metaData) {
    return <div>Loading...</div>;
  }

  let dqMetaObj: any = {};

  dqMetaObj['columnData'] = [
    {
      Header: 'File Name',

      minWidth: 280,
      columns: [
        {
          Header: '',
          accessor: 'FileName'
        }
      ]
    }
  ];

  metaData.forEach((val: any) => {
    val['rowData'].forEach((data: any) => {
      if (dqMetaObj['rowData']) {
        dqMetaObj['rowData'].push(data);
      } else {
        dqMetaObj['rowData'] = [data];
      }
    });
    dqMetaObj['columnData'].push({ Header: val['heading'], columns: val['column'] });
  });

  const rowObj: any = {};
dqMetaObj['columnData'].forEach((item:any,index:any)=>{
for(let i in item.columns){
  switch (item.columns[i].Header){
    case "NumberoFilesCheck":
      item.columns[i].toolTip = "Checks if the zip file contains 7 files";
      break;
    case "FileSizeCheck":
      item.columns[i].toolTip = "Checks if there is a 0 byte file present in the zip file";
      break;
    case "FileNameCheck":
      item.columns[i].toolTip = "Checks if all filenames align with the expected naming convention";
      break;
    case "FileDelimiterCheck":
      item.columns[i].toolTip = "Checks if the Product, Market, Period, Fact, fact_data and DIM files respect the delimiter value mentioned in the metadata file";
      break;
    case "FileEncodingCheck":
      item.columns[i].toolTip = "Checks if the  Product, Market, Period, Fact, fact_data and DIM files respect ASCII encoding values mentioned in the metadata file";
      break;
    case "SchemaCheck":
      item.columns[i].toolTip = "Checks if the mandatory attributes are present in Product, Market, Period, Fact and fact_data files";
      break;
    case "ConstraintCheck":
      item.columns[i].toolTip = "Checks if the Product, Market, Period and Fact TAGS present in their respective files are Unique and Non-Null";
      break;
    case "LastPeriodDeliveredCheck":
      item.columns[i].toolTip = "Checks if the TimeKey in the metadata matches with the TAG value in the Period file when filtered with maximum display order value";
      break;
    case "DimvsTransTagsCheck":
      item.columns[i].toolTip = "Checks if the TAGS present in Product, Market and Period are present in the transactional file i.e fact_data file";
      break;
  } 

}
});
  dqMetaObj['rowData'].forEach((item: any) => {
    if (rowObj[item['FileName']]) {
      rowObj[item['FileName']] = { ...rowObj[item['FileName']], ...item };
    } else {
      rowObj[item['FileName']] = item;
    }
  });

  dqMetaObj['rowData'] = Object.values(rowObj);

  return (
    <div className='checkStatusContainer'>
      <div className='section'>
        <div className='statusCheckTable'>
          <Table columns={dqMetaObj.columnData} data={dqMetaObj.rowData} />
        </div>
      </div>
    </div>
  );
}