import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './marketMetadata.scss';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import { Button, Flex, Tooltip, ActionIcon, Loader, Text } from '@mantine/core';
import {
  MRT_TableOptions,
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_Row,
  MRT_TableInstance,
  MRT_Virtualizer
} from 'mantine-react-table';
import { marketMetadataTableColumns } from './columns';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { IconCheck, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import MantineTable from 'shared/components/MantineTable';
import MarketMetadataService from 'services/AdminService/MarketMetadataService/marketMetadata.service';
import { MarketMetadata } from 'models/MarketMetadata/marketMetadata.model';

function MarketMeta() {
  const tableColumns: any = marketMetadataTableColumns;
  const [tableElement, setTableElement] = useState<any>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [tempData, setTempData] = useState<any>([]);
  const [modifiedRecords, setModifiedRecords] = useState<{ created: MarketMetadata[]; edited: MarketMetadata[]; deleted: number[] }>({
    created: [],
    edited: [],
    deleted: []
  });

  //Api calls
  const { fetchMarketMetadataRecords, createMarketMetadataRecords, deleteMarketMetadataRecords, updateMarketMetadataRecords } =
    MarketMetadataService();

  const { data, isError, isLoading, refetch, fetchNextPage, isFetching, isPreviousData } = fetchMarketMetadataRecords({
    sorting,
    columnFilters
  });

  const { createIsLoading, createMutate } = createMarketMetadataRecords();

  const { updateIsLoading, updateMutate } = updateMarketMetadataRecords();

  const { deleteIsLoading, deleteMutate } = deleteMarketMetadataRecords();

  const marketMetaData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result) ?? [];
  }, [data]);

  useEffect(() => {
    setTempData(marketMetaData ? marketMetaData : []);
  }, [data]);

  const totalDBRowCount = data?.pages?.[0]?.count ?? 0;
  const totalFetched = marketMetaData.length;

  const handleClear = () => {
    setModifiedRecords({
      created: [],
      edited: [],
      deleted: []
    });
    setTempData(data ? data : []);
  };

  const handleSaveChanges = () => {
    modifiedRecords.created.length
      ? createMutate(modifiedRecords.created, {
          onSuccess: async () => {
            refetch();
            return notifications.show({
              title: 'Success',
              message: 'Records Creation successfull',
              color: 'green',
              icon: <IconCheck />,
              autoClose: 2000,
              withBorder: true
            });
          },
          onError: async (data: any) => {
            refetch();
            return notifications.show({
              title: 'Failure',
              message: 'Record Creation Unsuccessfull' + data.error.message,
              color: 'green',
              icon: <IconX />,
              autoClose: 2000,
              withBorder: true
            });
          }
        })
      : null;

    modifiedRecords.edited.length
      ? updateMutate(modifiedRecords.edited, {
          onSuccess: async () => {
            refetch();
            return notifications.show({
              title: 'Success',
              message: 'Records Updation successfull',
              color: 'green',
              icon: <IconCheck />,
              autoClose: 2000,
              withBorder: true
            });
          },
          onError: async (data: any) => {
            refetch();
            return notifications.show({
              title: 'Failure',
              message: 'Updation Unsuccessfull ' + data.error.message,
              color: 'green',
              icon: <IconX />,
              autoClose: 2000,
              withBorder: true
            });
          }
        })
      : null;

    modifiedRecords.deleted.length
      ? deleteMutate(modifiedRecords.deleted, {
          onSuccess: async () => {
            refetch();
            return notifications.show({
              title: 'Success',
              message: 'Deletion successfull',
              color: 'green',
              icon: <IconCheck />,
              autoClose: 2000,
              withBorder: true
            });
          },
          onError: async (data: any) => {
            refetch();
            return notifications.show({
              title: 'Failure',
              message: 'Deletion Unsuccessfull ' + data.error.message,
              color: 'green',
              icon: <IconX />,
              autoClose: 2000,
              withBorder: true
            });
          }
        })
      : null;

    setModifiedRecords({
      created: [],
      edited: [],
      deleted: []
    });
  };

  const handleSave: MRT_TableOptions<MarketMetadata>['onEditingRowSave'] = async ({ values, table, row }) => {
    const editedRecords = [...modifiedRecords.edited, values];
    const editedIndex = row.index;
    setModifiedRecords((prev) => {
      const data = prev;
      data.edited = editedRecords;
      return data;
    });

    setTempData((prevTempData: any) => {
      const updatedTempData = prevTempData.map((record: MarketMetadata, index: number) => (index === editedIndex ? values : record));
      return updatedTempData;
    });
    table.setEditingRow(null);
  };

  const handleCreate: MRT_TableOptions<MarketMetadata>['onCreatingRowSave'] = ({ values, exitCreatingMode }) => {
    const { Id, ...rest } = values;
    const createdRecords = [...modifiedRecords.created, rest];
    setModifiedRecords((prev) => {
      const data = prev;
      data.created = createdRecords;
      return data;
    });
    setTempData([values, ...tempData]);
    exitCreatingMode();
  };

  const customActionButton = ({ row, table }: { row: MRT_Row<MarketMetadata>; table: MRT_TableInstance<MarketMetadata> }) => {
    const handleEdit = () => table.setEditingRow(row);
    const handleDelete = () => {
      if (row.original.Id) {
        const deleteRecords = [...modifiedRecords.deleted, row.original.Id];
        setModifiedRecords((prev) => {
          const data = prev;
          data.deleted = deleteRecords;
          return data;
        });
        tempData.splice(row.index, 1);
        setTempData([...tempData]);
      }
    };
    return (
      <Flex gap='md'>
        <Tooltip label='Edit' position='right'>
          <ActionIcon onClick={handleEdit}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Delete' position='left'>
          <ActionIcon color='red' onClick={handleDelete}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    );
  };

  const customRecordsButton = ({ table }: { table: MRT_TableInstance<MarketMetadata> }) => {
    //const handleNewMetadata = () => table.setCreatingRow(true);
    return !createIsLoading && !updateIsLoading && !deleteIsLoading ? (
      <div className='table-actions'>
        <Button
          onClick={() => {
            table.setCreatingRow(true);
            tableElement.scrollTop = 0;
          }}
        >
          New Metadata
        </Button>
        <Button
          color='green'
          disabled={modifiedRecords.created.length === 0 && modifiedRecords.deleted.length === 0 && modifiedRecords.edited.length === 0}
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
        <Button
          color='red'
          disabled={modifiedRecords.created.length === 0 && modifiedRecords.deleted.length === 0 && modifiedRecords.edited.length === 0}
          onClick={handleClear}
        >
          Clear Changes
        </Button>
      </div>
    ) : (
      <Loader size={38} />
    );
  };

  const alertBannerProps = {
    color: 'red',
    children: 'Error loading data'
  };

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        setTableElement(containerRefElement);

        if (scrollHeight - scrollTop - clientHeight < 400 && !isFetching && totalFetched < totalDBRowCount) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, columnFilters]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [tempData]);

  return (
    <DashboardContainer>
      <h3>Market</h3>
      <MantineTable
        data={tempData}
        columns={tableColumns}
        customActions={true}
        columnPinning={{ left: ['mrt-row-actions'] }}
        enablePinning={true}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableStickyHeader={true}
        enablePagination={false}
        enableColumnActions={false}
        enableGlobalFilter={false}
        manualFiltering={true}
        manualSorting={true}
        onColumnFiltersChange={setColumnFilters}
        onSortingChange={setSorting}
        columnFilters={columnFilters}
        isLoading={isLoading}
        sorting={sorting}
        isFetching={isFetching}
        fetchMoreOnBottomReached={(val: any) => fetchMoreOnBottomReached(val)}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        tableContainerRef={tableContainerRef}
        isPreviousData={isPreviousData}
        mantineToolbarAlertBannerProps={
          isError
            ? {
                color: 'red',
                children: 'Error loading data'
              }
            : undefined
        }
        customActionButton={customActionButton}
        customRecordsButton={customRecordsButton}
        onEditingRowSave={handleSave}
        onCreatingRowSave={handleCreate}
        enableRowActions={true}
        enableEditing={true}
        renderBottomToolbarCustomActions={() => {
          return (
            <div className='bottom-Action'>
              <p>Total Records : {totalDBRowCount}</p>
              <Text className='bottom-text'>
                Showing {totalFetched} of {totalDBRowCount} total rows.
              </Text>
            </div>
          );
        }}
      />
    </DashboardContainer>
  );
}

export default MarketMeta;
