export const marketMetadataTableColumns = [
  { header: 'Id', accessorKey: 'Id', maxSize: 70, enableResizing: true, enableColumnDragging: true, enableEditing: false },

  { header: 'Country', accessorKey: 'Country', maxSize: 300, enableResizing: true, enableColumnDragging: true },

  { header: 'Category', accessorKey: 'Category', maxSize: 250, enableResizing: true, enableColumnDragging: true },

  { header: 'Cell', accessorKey: 'Cell', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'Long', accessorKey: 'Long', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'HierName', accessorKey: 'HierName', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'HierLevelName', accessorKey: 'HierLevelName', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'Channel', accessorKey: 'Channel', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'UniqueTag', accessorKey: 'UniqueTag', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'TotalMarket', accessorKey: 'TotalMarket', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'Tag', accessorKey: 'Tag', maxSize: 200, enableResizing: true, enableColumnDragging: true }
];
