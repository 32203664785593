import React, { useState } from 'react';
import { Group, Drawer, ActionIcon, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronLeft } from '@tabler/icons-react';
import Remapping from 'views/SmartMapping/Remapping';
import './drawer.scss';

const MantineSidebar = ({ cell, handleRefetch }: any) => {
  const [opened, setOpened] = useDisclosure(false);


  const drawerClosed = () => {
    handleRefetch(setOpened)
  }

  return (
    <>
      <Drawer opened={opened} onClose={setOpened.close} position='right' size='75%' title='Remapping'>
        <div style={{ width: '100%' }}>
          <Remapping prop={cell} drawerClosed={drawerClosed} />
        </div>
      </Drawer>
      <Group>
        <ActionIcon>
          <IconChevronLeft onClick={setOpened.open} />
        </ActionIcon>
      </Group>
    </>
  );
};

export default MantineSidebar;
