import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
import UniliverLogo from 'assets/icons/uniliver-logo.svg';
import EdithLogo from 'assets/icons/edith-logo.svg';
import ClockImg from 'assets/icons/clock.svg';
import moment from 'moment';
import { defaultAdminRoute, sidebarDateTimeFormat } from 'constants/formats';
import useSidebarConfig, { SidebarMenuItem } from './useSidebarConfig';
import useRedirect from 'shared/hooks/useRedirect';
import clsx from 'clsx';
import { Col, NavDropdown } from 'react-bootstrap';

const Sidebar: React.FC = () => {
  const { defaultMenuItems, adminMenuItems } = useSidebarConfig();

  const { redirectToHome, location, redirect } = useRedirect();

  const [menuItems, setMenuItems] = useState<SidebarMenuItem[]>(defaultMenuItems);

  useEffect(() => {
    if (location.pathname.split('/').includes(defaultAdminRoute)) setMenuItems(adminMenuItems);
  }, [location.pathname]);

  const isActiveMenu = (url?: string) => url && location.pathname.split('/').includes(url);

  const handleSideBarClick = (url?: string, urlPrefix?: string) => () => {
    if (url) redirect(`/${urlPrefix ? `${urlPrefix}/` : ''}${url}`);
  };
  const navDropdown = <div className='profile-pic-wrapper'></div>;
  return (
    <div className='sidebar-container'>
      {/* <button className='sidebar-logo'
              onClick={redirectToHome}
              onKeyDown={redirectToHome}
            > */}
        <div className='sidebar-header'>
          {/* comment for future use */}
          {/* <img src={imgSrc} alt='menu' /> */}
          {/* <img src={UniliverLogo} alt='menu' /> */}
          {/* <img src={EdithLogo} alt='menu' className='sidebar-header-edith-icon' /> */}
          <h3 className='logo-name'>Dataverse</h3>
        </div>
      {/* </button> */}
      <div className='sidebar-menu-container'>
        {menuItems.map(({ name, icon, url, urlPrefix, submenu }) => (
          <button
            key={name}
            className={clsx('menu-item', isActiveMenu(url) && 'active')}
            onClick={handleSideBarClick(url, urlPrefix)}
            onKeyDown={handleSideBarClick(url, urlPrefix)}
          >
            <img className='menu-item__icon' src={icon} alt='menu-icon' />
            <p className='menu-item__name'> {name}</p>
            <Col className='container-right'>
              {!!submenu?.length && (
                <div className='icon-container'>
                  <NavDropdown title={navDropdown}>
                    {submenu.map(({ name, onClick }) => (
                      <NavDropdown.Item key={name} onClick={onClick}>
                        {name}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </div>
              )}
            </Col>
          </button>
        ))}
      </div>
      <div className='current-login'>
        <img src={ClockImg} alt='clock-icon' className='current-login__icon' />
        <div className='current-login__text'>
          <p className='current-login__text__title'>Current Login</p>
          <p className='current-login__text__time'>{moment().format(sidebarDateTimeFormat)}</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
