import { serializable, alias, object, list, primitive } from 'serializr';

export class SmartMappingList {
  @serializable(alias('Id', primitive()))
  id?: number;

  @serializable(alias('ExternalDataProvider', primitive()))
  dataProvider?: string;

  @serializable(alias('Category', primitive()))
  category?: string;

  @serializable(alias('Filename', primitive()))
  fileName?: string;

  @serializable(alias('Country', primitive()))
  country?: string;

  @serializable(alias('TotalRecords', primitive()))
  total?: number;

  @serializable(alias('TotalRecords', primitive()))
  TotalRecords?: number;

  @serializable(alias('TotalRecordsMappedBySME', primitive()))
  recordsmappedBySME?: number;

  @serializable(alias('TotalRecordsMappedBySME', primitive()))
  TotalRecordsMappedBySME?: number;

  @serializable(alias('TotalMappedRecords', primitive()))
  mappedRecordCount?: number;

  @serializable(alias('TotalMappedRecords', primitive()))
  TotalMappedRecords?: number;

  @serializable(alias('TotalUnprocessedRecords', primitive()))
  unmappedRecordCount?: number;

  @serializable(alias('TotalUnprocessedRecords', primitive()))
  TotalUnprocessedRecords?: number;

  @serializable(alias('Scenarioflag', primitive()))
  Scenarioflag?: string | number;

  @serializable(alias('High', primitive()))
  confidenceLevelLessThan70?: number;

  @serializable(alias('High', primitive()))
  High?: number;

  @serializable(alias('Medium', primitive()))
  confidenceLevel5070?: number;

  @serializable(alias('Medium', primitive()))
  Medium?: number;

  @serializable(alias('Low', primitive()))
  confidenceLevelLessThan50?: number;

  @serializable(alias('Low', primitive()))
  Low?: number;

  @serializable(alias('TotalUnprocessedRecords', primitive()))
  confidenceLevelUnprocessed?: number;

  @serializable(alias('CreatedOn', primitive()))
  createdAt?: string;

  @serializable(alias('CreatedOn', primitive()))
  createdOn?: string;

  @serializable(alias('DirectIndirect', primitive()))
  directIndirect?: string;

  @serializable(alias('DirectIndirect', primitive()))
  DirectIndirect?: string;

  @serializable(alias('Dataset', primitive()))
  dataset?: string;

  @serializable(alias('Dataset', primitive()))
  Dataset?: string;
}
