import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SMData from 'services/SmartMapping/SMListService/SmartMapping.service';
import { columns } from '../constants/SMColumns/ProductColumns';
import { PeriodColumn } from '../constants/SMColumns/PeriodColumns';
import { useNavigate, useParams } from 'react-router';
import { MarketColumns } from '../constants/SMColumns/MarketColumns';
import { FactColumns } from '../constants/SMColumns/FactColumns';
import { Text, Skeleton, ActionIcon, Flex, Tooltip } from '@mantine/core';
import { MRT_ShowHideColumnsButton, MRT_SortingState, MRT_Virtualizer } from 'mantine-react-table';
import MantineTable from 'views/SmartMapping/Shared/MantineTable';
import dayjs from 'dayjs';
import { IconRefresh } from '@tabler/icons-react';

const Table = ({ filter }: any) => {
  const columnData: any = {
    product: columns,
    fact: FactColumns,
    market: MarketColumns,
    period: PeriodColumn
  };

  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const { dimension } = useParams();
  const navigate = useNavigate();

  const { SMList, SMListCount } = SMData();

  const {
    data,
    isError: smIsError,
    isLoading: smIsLoading,
    fetchNextPage,
    isPreviousData,
    isFetching,
    refetch
  } = SMList(dimension ? dimension : null, filter, sorting, globalFilter);
  const {
    data: pageCount,
    isLoading: pageCountLoading,
    refetch: paginationRefetch
  } = SMListCount(dimension ? dimension : null, filter, globalFilter);

  const IMdata = useMemo(() => {
    return data?.pages.flatMap((page) => page);
  }, [data]);

  const totalDBRowCount: number | undefined = pageCount?.count;
  const totalFetched: number | undefined = IMdata?.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (totalFetched && totalDBRowCount) {
          if (scrollHeight - scrollTop - clientHeight < 400 && !isFetching && totalFetched < totalDBRowCount) {
            fetchNextPage();
          }
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [filter]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [IMdata]);

  const navigateDetails = async (fileName: string, dataProvider: string, total: number, createdOn: string, successStatus: string) => {
    if (!fileName || !dataProvider) return;

    const arr: any = ['fact', 'market', 'period'];

    if (!arr?.includes(dimension)) {
      navigate({
        pathname: `/checks/intelligent-mapping/product/high`,
        search: `?Filename=${fileName}&Provider=${dataProvider}&Total=${total}&Created=${dayjs(createdOn).format(
          'YY-MMM-DD hh:mm A'
        )}&Success=${successStatus}`
      });
    } else if (dimension) {
      navigate({
        pathname: `/checks/intelligent-mapping/${dimension}/mapped`,
        search: `?Filename=${fileName}&Provider=${dataProvider}&Total=${total}&Created=${dayjs(createdOn).format(
          'YY-MMM-DD hh:mm A'
        )}&Success=${successStatus}`
      });
    }
  };

  return (
    <>
      <MantineTable
        columns={dimension ? columnData[dimension] : columnData['product']}
        data={IMdata}
        enablePinning={true}
        positionGlobalFilter='left'
        manualFiltering={true}
        manualSorting={true}
        staticHeader={true}
        isPreviousData={isPreviousData}
        sorting={sorting}
        onSortingChange={setSorting}
        onGlobalFilterChange={setGlobalFilter}
        initialState={{ showGlobalFilter: true }}
        showGlobalFilter={true}
        enableColumnOrdering={false}
        enableGlobalFilterModes={true}
        enableGlobalFilter={true}
        mantineSearchTextInputProps={{
          placeholder: 'Search by File name',
          sx: { minWidth: '300px' },
          variant: 'filled'
        }}
        isLoading={smIsLoading || isFetching}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableColumnActions={false}
        enablePagination={false}
        mantineTableBodyRowProps={({ row }: any) => ({
          onClick: () => {
            const successStatus = ((+row?.original?.TotalMappedRecords / +row?.original?.TotalRecords) * 100).toFixed(2);
            navigateDetails(
              row?.original?.fileName,
              row?.original?.dataProvider.toLowerCase(),
              row?.original?.TotalRecords,
              row?.original?.createdOn,
              successStatus
            );
          },
          sx: {
            cursor: 'pointer'
          }
        })}
        renderBottomToolbarCustomActions={() => {
          return (
            <div className='bottom-Action'>
              {pageCountLoading ? <Skeleton height={30} width='15rem' /> : `Total Records : ${totalDBRowCount ? totalDBRowCount : 0}`}
              <Text className='bottom-text'>
                {pageCountLoading ? <Skeleton height={30} width='20rem' /> : `Showing ${totalFetched ? totalFetched : 0}  of ${totalDBRowCount ? totalDBRowCount : 0} total rows.`}
              </Text>
            </div> 
          );
        }}
        renderToolbarInternalActions={({ table }: any) => (
          <Flex gap='xs' align='center'>
            <Tooltip label='Refresh' position='top'>
              <ActionIcon
                onClick={() => {
                  refetch();
                  paginationRefetch();
                }}
              >
                <IconRefresh />
              </ActionIcon>
            </Tooltip>
            <MRT_ShowHideColumnsButton table={table} />
          </Flex>
        )}
        fetchMoreOnBottomReached={(val: any) => fetchMoreOnBottomReached(val)}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        tableContainerRef={tableContainerRef}
      />
    </>
  );
};

export default Table;
