import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import NewEDDropdown from 'shared/components/NewEDDropdown';
import EDDatePicker from 'shared/components/EDDatePicker';
import './leadershipDashboard.scss';
import FileHarmonizationStatus from './FileHarmonizationStatus';
import VolumeOfDataProcessed from './VolumeOfDataProcessed';
import TaskUpdates from './TaskUpdates';
import ConfidenceMapping from './ConfidenceMapping';
import MetaService from 'services/MetaService/meta.service';
import { MetaData } from 'models/MetaData/metaData.model';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import ProfileService from 'services/ProfileService/profile.service';

const LeadershipDashboard = () => {
  const { getUserName } = ProfileService();

  const { fetchMeta, loading } = MetaService();

  const [countryMeta, setCountryMeta] = useState<MetaData[]>([]);

  const [startDate, setStartDate] = React.useState<string>();

  const [endDate, setEndDate] = React.useState<string>();

  const [country, setCountry] = useState<string>('');

  useEffect(() => {
    fetchMeta(ApiRoutes.COUNTRY_META, setCountryMeta, MetaData);
  }, []);

  return (
    <DashboardContainer>
      <Container fluid className='leadership-dashboard'>
        <Row>
          <Col sm={12} className='top-row-container p-0'>
            <Col sm={6}>
              <div className='home-page-title'>
                <h2>Welcome {getUserName()}!!</h2>
                <p>Below are the analysis for this week.</p>
              </div>
            </Col>
            <Col sm={6}>
              <div className='filters-container'>
                {/* <span className='filters-container-txt'>Filters:</span> */}
                <div className='filter-container'>
                  <EDDatePicker
                    onDateSelected={(startDate, endDate) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                  />
                </div>
                <div className='filter-container'>
                  <NewEDDropdown
                    loading={loading}
                    optionItems={countryMeta}
                    value={country}
                    currentItemIndex={2}
                    resetFunc={() => setCountry('')}
                    labelText='Country'
                    defaultValue='All(22)'
                    updateValue={(value) => setCountry(value)}
                  />
                </div>
              </div>
            </Col>
          </Col>
        </Row>

        <Row>
          <Col sm={12} className='p-0'>
            <FileHarmonizationStatus startDate={startDate} endDate={endDate} country={country} />
          </Col>
        </Row>

        <Row>
          <Col sm={8} className='pl-0'>
            <ConfidenceMapping startDate={startDate} endDate={endDate} country={country} />
          </Col>
          <Col sm={4} className='pr-0'>
            <TaskUpdates />
          </Col>
        </Row>

        <Row className='graphs-card'>
          <Col sm={12}>
            <VolumeOfDataProcessed startDate={startDate} endDate={endDate} country={country} />
          </Col>
        </Row>
      </Container>
    </DashboardContainer>
  );
};

export default LeadershipDashboard;
