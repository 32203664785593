import './DetailScreen.scss';
import { useLocation, useParams } from 'react-router';
import React from 'react';
import './DetailScreen.scss';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import Databar from 'shared/components/Databar-component/Databar';
import MantineBreadcrumbs from 'shared/components/MantineBreadcrumbs';
import SegmentedTab from 'shared/components/SegmentedTab';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import DetailTable from './Table/Table';

const TabData: any = {
  product: [
    { value: 'high', label: 'High' },
    { value: 'medium', label: 'Medium' },
    { value: 'low', label: 'Low' },
    { value: 'unprocessed', label: 'Unprocessed' }
  ],
  market: [{ value: 'mapped', label: 'Mapped' }],
  fact: [{ value: 'mapped', label: 'Mapped' }],
  period: [{ value: 'mapped', label: 'Mapped' }]
};

const TabDataWithUAOL: any = {
  product: [
    { value: 'high', label: 'High' },
    { value: 'medium', label: 'Medium' },
    { value: 'low', label: 'Low' },
    { value: 'unprocessed', label: 'Unprocessed' },
    { value: 'uaol-processed', label: 'UAOL Processed' },
    { value: 'uaol-unprocessed', label: 'UAOL Unprocessed' }
  ],
  market: [
    { value: 'mapped', label: 'Mapped' },
    { value: 'unprocessed', label: 'Unprocessed' }
  ],
  fact: [
    { value: 'mapped', label: 'Mapped' },
    { value: 'unprocessed', label: 'Unprocessed' }
  ],
  period: [{ value: 'mapped', label: 'Mapped' }]
};

const IntelligentMappingDetails = () => {
  const navigate = useNavigate();
  const { dimension, status } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const handleNavigation = (value: any) => {
    if (value) {
      navigate(`/checks/intelligent-mapping/${dimension ? dimension : 'product'}/${value}${location.search} `);
    }
  };
  return (
    <DashboardContainer>
      <div>
        <p className='page-title'>Intelligent Mapping</p>
        <MantineBreadcrumbs />
        <div className='file-name'>
          <p className='file-name-text'>Filename:</p>
          <span>{searchParams.get('Filename')}</span>
        </div>
        <Databar
          DataProvider={searchParams.get('Provider') as string}
          TotalRecords={searchParams.get('Total') as any}
          successStatus={searchParams.get('Success') as any}
          loadedOn={searchParams.get('Created') as any}
        />

        <div className='segmented-tab'>
          {searchParams.get('Provider') && searchParams.get('Provider') === 'nielsen' ? (
            <SegmentedTab
              data={dimension ? TabDataWithUAOL[dimension] : TabDataWithUAOL['product']}
              value={status}
              onChange={handleNavigation}
            />
          ) : (
            <SegmentedTab data={dimension ? TabData[dimension] : TabData['product']} value={status} onChange={handleNavigation} />
          )}
        </div>
      </div>
      <DetailTable />
    </DashboardContainer>
  );
};

export default IntelligentMappingDetails;
