export const POSMarketMappedRemapping = [
  {
    key: 'country',
    attributeName: 'Country',
    external: 'Country',
    internal: null,
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: null,
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'filename',
    attributeName: 'File Name',
    external: 'FileName',
    internal: null,
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: null,
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'retailer-id',
    attributeName: 'Retailer ID',
    external: 'RetailerID',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'direct-indirect',
    attributeName: 'Direct Indirect',
    external: null,
    internal: 'Direct_Indirect',
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'iso-country',
    attributeName: 'ISO Country',
    external: null,
    internal: 'ISOCountry',
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'date',
    attributeName: 'Date',
    external: 'Date',
    internal: null,
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: null,
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'unique-tag',
    attributeName: 'Unique Tag',
    external: null,
    internal: 'UniqueTag',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: null,
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'provider-market-name',
    attributeName: 'Provider / Market name',
    external: null,
    internal: 'Provider/MarketName',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: null,
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'customer',
    attributeName: 'Customer',
    external: null,
    internal: 'Customer',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'customer',
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'store-name',
    attributeName: 'Store Name',
    external: null,
    internal: 'StoreName',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'store-name',
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'cell-coverage',
    attributeName: 'Cell Coverage',
    external: null,
    internal: 'CellCoverage',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: null,
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'market-short',
    attributeName: 'Market Short',
    external: null,
    internal: 'MarketShort',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'market-short',
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'market-long',
    attributeName: 'Market Long',
    external: null,
    internal: 'MarketLong',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'market-long',
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  }
];
