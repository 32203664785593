import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from '@mantine/core';

export const RMSProductMappedColumns = [
  {
    header: 'External',
    accessorKey: 'External',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'External Product Description',
        accessorKey: 'Externaldesc',
        size: 280
      },
      // {
      //   header: 'External Short Description',
      //   accessorKey: 'Short',
      //   size: 280
      // },
      // {
      //   header: 'External TAG',
      //   accessorKey: 'Tag',
      //   size: 200
      // },
      // {
      //   header: 'External Hierarchy Number',
      //   accessorKey: 'Hiernum',
      //   size: 240
      // },
      // {
      //   header: 'External Hierarchy Name',
      //   accessorKey: 'Hiername',
      //   size: 240
      // },
      // {
      //   header: 'External Hierarchy Level',
      //   accessorKey: 'Hierlevelnum',
      //   size: 240
      // },
      // {
      //   header: 'External Hierarchy Level Name',
      //   accessorKey: 'Hierlevelname',
      //   size: 280
      // },
      // {
      //   header: 'External Parent TAG',
      //   accessorKey: 'Parenttag',
      //   size: 200
      // },
      // {
      //   header: 'External Company',
      //   accessorKey: 'Company',
      //   size: 240
      // },
      {
        header: 'External Brand',
        accessorKey: 'Brand',
        size: 220
      },
      // {
      //   header: 'Segment',
      //   accessorKey: 'Segmentname',
      //   size: 150,
      //   id: 'externalSegment'
      // }
    ]
  },
  {
    header: 'Internal',
    accessorKey: 'Internal',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Internal Product Description',
        accessorKey: 'Internaldesc',
        size: 240
      },
      // {
      //   header: 'Category Name',
      //   accessorKey: 'Categoryname',
      //   size: 180
      // },
      // {
      //   header: 'Market Name',
      //   accessorKey: 'Marketname',
      //   size: 150
      // },
      {
        header: 'Corporate brand name',
        accessorKey: 'Corporatebrandname',
        size: 220
      },
      // {
      //   header: 'Product Form Name',
      //   accessorKey: 'Productformmediumname',
      //   size: 200
      // },
      // {
      //   header: 'Sub Product Form Varient Name',
      //   accessorKey: 'Spfvname',
      //   size: 280
      // },
      // {
      //   header: 'Division Name',
      //   accessorKey: 'Divisionname',
      //   size: 200
      // },
      // {
      //   header: 'Sector Name',
      //   accessorKey: 'Sectorname',
      //   size: 180
      // },
      // {
      //   header: 'Segment Name',
      //   accessorKey: 'Segmentname',
      //   size: 180,
      //   id: 'internalSegment'
      // },
      // {
      //   header: 'Form Name',
      //   accessorKey: 'Formname',
      //   size: 200
      // },
      // {
      //   header: 'Sub Form Name',
      //   accessorKey: 'Subformname',
      //   size: 200
      // },
      // {
      //   header: 'Product Pack Form Name',
      //   accessorKey: 'Productpackformname',
      //   size: 250
      // },
      // {
      //   header: 'Product Pack Size Name',
      //   accessorKey: 'Productpacksizename',
      //   size: 240
      // },
      // {
      //   header: 'Product Variant Name',
      //   accessorKey: 'Productvariantname',
      //   size: 240
      // },
      // {
      //   header: 'Product Code Name',
      //   accessorKey: 'Productcodename',
      //   size: 200
      // }
    ]
  },
  // {
  //   header: 'Scenario Flag',
  //   accessorKey: 'Scenarioflag',
  //   Cell: (row: any) => {
  //     let toolTip: any;
  //     switch (row.row.original.Scenarioflag) {
  //       case 'SC 0':
  //         toolTip = 'Mapped Already by IM Engine';
  //         break;
  //       case 'SC 1':
  //         toolTip = 'Mapped Based on External Skucode using Internal Material Master data file.';
  //         break;
  //       case 'SC 2':
  //         toolTip = 'Mapped Based on External ITEM/EAN Code using Internal Material Master data file.';
  //         break;
  //       case 'SC 3':
  //         toolTip = 'Mapped Based on Market,Brand and ProductForm for Unilever records.';
  //       case 'SC 4':
  //         toolTip = 'Mapped Based on Market/Brand for both Unilever and Competitor records';
  //         break;
  //     }

  //     return (
  //       <div className='cursor-pointer'>
  //         <Tooltip label={toolTip}>
  //           <p>{row.row.original.Scenarioflag}</p>
  //         </Tooltip>
  //       </div>
  //     );
  //   },
  //   size: 180
  // }
];
