import axiosInstance from 'interceptor/axiosInstance';
import { Library } from 'models/Library/library.models';
import { useState } from 'react';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { deserialize } from 'serializr';

const LibraryService: any = () => {
  const [loading, setLoading] = useState(false);
  const [libraryList, setLibraryList] = useState<Library[]>();

  const fetchLibraryList: any = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.LIBRARY);
      const data = deserialize(Library, response.data) as Library[];
      setLibraryList(data);
      console.log('Response is ', response);
    } catch (error) {
      console.error('Error is ', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    libraryList,
    fetchLibraryList
  };
};

export default LibraryService;
