import React, { FC } from 'react';
import './dimensionDetails.scss';
import { Col, Row } from 'react-bootstrap';
import CustomButton from 'shared/components/Button-component';
import DownloadArrow from 'assets/icons/download.svg';
import Table from 'shared/components/Table-component/Table-component';
import Loader from 'shared/components/Loader';
import EDPagination from 'shared/components/Pagination';
import { DqCheck } from 'models/DqCheck/dqCheck.model';
import { Pagination } from 'models/Pagination/pagination.model';
import { DQFilterParams } from '..';
import DqCheckService from 'services/DqCheckService/dqCheck.service';
import SkeletonLoader from 'shared/components/SkeletonLoader';
import rowleft from 'assets/icons/row-left.svg';
import rowDown from 'assets/icons/row-down.svg';
import TableRowExpand from './TableRowExpand';
import { ConstructDqMetaData } from 'shared/utils/constructDqMetaData';

interface DimensionDetailsProps {
  tableData: DqCheck[];
  loading: Boolean;
  pagination: Pagination | null | undefined;
  handlePagination: (pageNumber: number, recordsPerPage?: number) => void;
  filters: DQFilterParams;
  updateFilters: (filterName: keyof DQFilterParams, value: boolean) => void;
  paginationLoading?: boolean;
}

const DimensionDetails: FC<DimensionDetailsProps> = (props) => {
  const { tableData, loading, pagination, handlePagination, updateFilters, filters, paginationLoading } = props;

  const { downloadDQReport, buttonLoading } = DqCheckService();

  const columns = [
    {
      minWidth: 60,
      Header: () => null, // No header
      id: 'expander', // It needs an ID
      Cell: ({ row }: any) => {
        return (
          <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <img src={rowDown} alt='' /> : <img src={rowleft} alt='' />}</span>
        );
      }
    },
    {
      Header: 'Country',
      minWidth: 180,
      columns: [
        {
          Header: '',
          accessor: 'Country',
          minWidth: 180
        }
      ]
    },
    {
      Header: 'Dataset',
      minWidth: 180,
      columns: [
        {
          Header: '',
          accessor: 'Dataset',
          minWidth: 180
        }
      ]
    },
    {
      Header: 'DataProviderType',
      minWidth: 180,
      columns: [
        {
          Header: '',
          accessor: 'DirectIndirect',
          minWidth: 180
        }
      ]
    },
    {
      Header: 'External Category',
      minWidth: 180,
      columns: [
        {
          Header: '',
          accessor: 'Category',
          minWidth: 180
        }
      ]
    },
    {
      Header: 'Data Provider',
      minWidth: 150,
      columns: [
        {
          Header: '',
          accessor: 'DataProvider',
          minWidth: 150
        }
      ]
    },
    {
      Header: 'Cell Database',
      minWidth: 230,
      columns: [
        {
          Header: '',
          accessor: 'CellDatabase',
          minWidth: 220
        }
      ]
    },
    {
      Header: 'Delivery Period',
      minWidth: 180,
      columns: [
        {
          Header: '',
          accessor: 'DeliveryPeriod',
          minWidth: 180
        }
      ]
    },
    {
      Header: 'FileName',
      minWidth: 300,
      columns: [
        {
          Header: '',
          accessor: 'FileName',
          minWidth: 300
        }
      ]
    },
    {
      Header: 'Data Quality Status',
      accessor: '',
      minWidth: 300,
      columns: [
        {
          Header: 'Overall Status',
          accessor: 'OverallStatus',
          minWidth: 120,
          Cell: (cell: any) => {
            const status = cell.row.original?.OverallStatus;
            let style = {};
            switch (status) {
              case 'Success':
                style = { color: 'green' };
                break;

              case 'Failure':
                style = { color: 'red' };
                break;

              case 'On Hold':
                style = { color: 'rgb(255, 166, 0)' };
                break;
            }
            return <div style={style}>{cell.value}</div>;
          }
        },
        {
          Header: 'Checks Passed',
          accessor: 'Checks_Passed',
          minWidth: 80
        },
        {
          Header: 'Checks Failed',
          accessor: 'Checks_Failed',
          minWidth: 80
        }
        // {
        //   Header: 'Remarks',
        //   accessor: 'Remarks',
        //   minWidth: 400
        // }
      ]
    }
  ];

  //check:status:type:filename:message --previous
  //check:filename:type:status:message --current

    const rowExpand = React.useCallback((row) => {
      let remark=row.row.original.Remarks
      remark=remark.replaceAll("(|)","@#") 
      const data = ConstructDqMetaData(remark);
    return <TableRowExpand data={data} />;
  }, []);

  return (
    <div className='dimension-details'>
      <Row className='mt-5 aligh-item-center table-action-header'>
        <Col sm={6}>
          <CustomButton primary onClick={() => downloadDQReport(filters)}>
            <div className=''>
              {buttonLoading ? 'Downloading...' : ' Download Report'}
              <img className='download-report-image' src={DownloadArrow} alt='download-arrow' />
            </div>
          </CustomButton>
        </Col>
        <Col sm={6}>
          <div className='d-flex justify-content-end small-button gap-2'>
            <CustomButton
              secondary={!filters.filter_by_in_progress}
              SecondaryPressed={filters.filter_by_in_progress}
              onClick={() => updateFilters('filter_by_in_progress', !filters.filter_by_in_progress)}
            >
              In Progress
            </CustomButton>
            <CustomButton
              secondary={!filters.filter_by_in_success}
              SecondaryPressed={filters.filter_by_in_success}
              onClick={() => updateFilters('filter_by_in_success', !filters.filter_by_in_success)}
            >
              Success
            </CustomButton>
            <CustomButton
              secondary={!filters.filter_by_in_failure}
              SecondaryPressed={filters.filter_by_in_failure}
              onClick={() => updateFilters('filter_by_in_failure', !filters.filter_by_in_failure)}
            >
              Failure
            </CustomButton>
          </div>
        </Col>
      </Row>
      <div className='table-container table-container-scroll'>
        {loading ? <Loader /> : <Table columns={columns} data={tableData} renderRowSubComponent={rowExpand} />}
      </div>
      {paginationLoading ? (
        <SkeletonLoader />
      ) : (
        pagination && (
          <EDPagination
            page={pagination?.page ?? 0}
            pageSize={pagination?.pageSize ?? 0}
            totalCount={pagination?.totalCount ?? 0}
            onPageItemClick={handlePagination}
          />
        )
      )}
    </div>
  );
};

export default DimensionDetails;
