const ConstructMetaData = ({ data }: any) => {
  const Obj: any = {};

  data.map((object: any) => {
    if (Obj[object.type]) {
      Obj[object.type].push(object);
    } else {
      Obj[object.type] = [object];
    }
  });

  interface DataObject {
    check: string;
    fileName: string;
    status: string;
    check_message: string;
  }

  interface Column {
    Header: string;
    accessor: string;
    minWidth: number;
  }

  interface RowData {
    FileName: string;
    [check: string]: string | undefined;
  }

  interface MetaData {
    heading: string;
    column: Column[];
    rowData: RowData[];
  }

  function constructMetaData(data: DataObject[], title: string): MetaData {
    const uniqueChecks: string[] = [];
    const columns: Column[] = [];
    const rowData: RowData[] = [];

    for (const obj of data) {
      if (!uniqueChecks.includes(obj.check)) {
        uniqueChecks.push(obj.check);
        columns.push({
          Header: obj.check,
          accessor: obj.check,
          minWidth: 50
        });
      }

      let row = rowData.find((item) => item.FileName === obj.fileName);
      if (!row) {
        row = { FileName: obj.fileName };
        rowData.push(row);
      }

      row[obj.check] = obj.status;

      row[obj.check + '_message'] = obj.check_message;
    }
    return { heading: title, column: columns, rowData };
  }
  let metadata: any[] = [];

  Object.keys(Obj).map((key) => {
    metadata.push(constructMetaData(Obj[key], key));
  });

  metadata = metadata.filter((item): any => {
    if (item.rowData.length !== 0) return item;
  });
  return metadata;
};

export default ConstructMetaData;