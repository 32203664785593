import clsx from 'clsx';
import { displayDateTimeFormat } from 'constants/formats';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import VolatilityService from 'services/VolatilityService/volatility.service';
import BreadcrumbComponent from 'shared/components/Breadcrumb-component/Breadcrumb-component';
import useRedirect from 'shared/hooks/useRedirect';
import './header.scss';
import { Skeleton } from '@mantine/core';
import { IndividualFileVolatility } from 'models/IndividualFileVolatility/IndividualFileVolatility.model';

interface HeaderProps {
  individualFileVolatilityData?: IndividualFileVolatility;
  loading?: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const { loading, individualFileVolatilityData } = props;

  const { redirect } = useRedirect();

  const details = [
    {
      key: 'Data Provider',
      value: individualFileVolatilityData?.SOURCE
    },
    {
      key: 'Country',
      value: individualFileVolatilityData?.COUNTRY
    },
    {
      key: 'Category',
      value: individualFileVolatilityData?.CATEGORY
    },
    {
      key: 'Loaded On',
      value: moment.utc(individualFileVolatilityData?.LOADSTARTTIME).format(displayDateTimeFormat)
    }
  ];

  return (
    <div className='fv-mapping-header'>
      <h3>Mapping</h3>
      <BreadcrumbComponent
        links={[
          {
            link: 'Load Statistics',
            active: false,
            onClick: () => redirect(`/${AppRoutes.CHECKS}/${AppRoutes.FILE_VOLATALITY_RECORDS}`)
          },
          { link: 'Mapping', active: true }
        ]}
        section
      />
      {loading ? (
        <Skeleton height={25} radius='md' width='65%' />
      ) : (
        <p className='file-name-status'>
          <span className='file-name-status__title'>Filename: </span>
          <span className='file-name-status__name'>{individualFileVolatilityData?.FILENAME}</span>
          <span className='file-name-status__title'>Status: </span>
          <span
            className={clsx(
              'file-name-status__status',
              individualFileVolatilityData?.PIPELINESTATUS?.toLocaleLowerCase().split(' ').join('_')
            )}
          >
            {individualFileVolatilityData?.PIPELINESTATUS}
          </span>
        </p>
      )}

      {loading ? (
        <Skeleton height={45} radius='md' width='65%' />
      ) : (
        <div className='data-details'>
          {details.map(({ key, value }) => (
            <p key={key} className='data-details__detail'>
              <span className='data-details__detail__key'>{key}: </span>
              <span className='data-details__detail__value'>{value}</span>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Header;
