import { displayDateTimeFormat } from "constants/formats";
import moment from "moment";
import React from "react";


export const POSProductUnprocessedColumn = [
      {
        header: 'FileName',
        accessorKey: 'Filename',
        enableColumnFilter: false,
        enableSorting: false,
      },
      // {
      //   header: 'Tag',
      //   accessorKey: 'Tag'
      // },
      {
        header: 'Product Identifier Name',
        accessor: 'Productidentifiername',
        size: 240
      },
      {
        header: 'Product Identifier',
        accessor: 'Productidentifier',
        size: 220
      },
      {
        header: 'External Product Description',
        accessor: 'Externaldesc',
        size: 240
      },
      // {
      //   header: 'Externaldesc',
      //   accessorKey: 'Externaldesc',
      //   size: 200
      // },
      // {
      //   header: 'Created On',
      //   accessorKey: 'Createdon',
      //   enableColumnFilter: false,
      //   Cell: ({ cell }: any) => (
      //     <div role='link' tabIndex={0}>
      //       {moment.utc(cell.value).format(displayDateTimeFormat)}
      //     </div>
      //   )
      // },
      // {
      //   header: 'Remark',
      //   accessorKey: 'Remark',
      //   size: 160
      // }
]