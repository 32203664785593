import React, { useState } from 'react';
import './SSOscreen.scss';
import CustomButton from 'shared/components/Button-component/Button';
import UniliverLogo from 'assets/icons/unilever-logo-blue.svg';
import LoginCardLogo from 'assets/icons/login-card-logo.svg';
import EdithLogo from 'assets/icons/edith-logo-black.svg';
import { loginRequest } from 'authConfig';
import { useMsal } from '@azure/msal-react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { error } from 'console';

const SSOscreen = ({ authentication }: any) => {
  const { instance } = useMsal();
  const[email,setEmail]=useState<string>() 
  const [password,setPassword]=useState<string>()

  // const handleLogin = (loginType: string) => {
  //   if (loginType === 'redirect') {
  //     instance.loginRedirect(loginRequest).catch((e) => {
  //       console.log(e);
  //     });
  //   }
  // };

  const handleLogin=()=>{
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if(reg.test(email as string) && password==="welovedata")
    {
      authentication(true)
    }
    else{
      const showError=document.getElementById("error-msg")as HTMLElement;
      showError.style.setProperty('display','block');
    }
  }

  return (
    <div className='sso-container'>
      <div className='card-container'>
        <div className='card-header'>
          {/* <img src={UniliverLogo} alt='unilever-logo' /> */}
          {/* <img src={EdithLogo} alt='edith-logo' /> */}
          <h3 style={{color: 'black', margin: '1rem'}}><span style={{fontWeight:700}}>D</span>ataverse</h3>
        </div>
        <div className='icon-row'>
          <img src={LoginCardLogo} alt='edith-logo' />
          <div className='icon-row-text'>
            <h2>Please Login</h2>
            <div className='formSection'>
              <FloatingLabel controlId='floatingInput' label='Email address' className='mb-3'>
                <Form.Control type='email' placeholder='name@example.com' onChange={(e)=>{setEmail(e.target.value)}}/>
              </FloatingLabel>
              <FloatingLabel controlId='floatingPassword' label='Password'>
                <Form.Control type='password' placeholder='Password' onChange={(e)=>{setPassword(e.target.value)}}/>
              </FloatingLabel>
            </div>
            <p id='error-msg' style={{color:'red', display:"none"}}>Please enter the correct username & password.</p>
          </div>
        </div>
        <div className='btn-container-signin'>
          <CustomButton primary onClick={() => handleLogin()}>
            Login
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default SSOscreen;
