export const POSFactMappedRemapping = [
  {
    key: 'short',
    attributeName: 'Short Description',
    external: 'Short',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'harmonized-name',
    attributeName: 'Long Description',
    external: 'Externaldesc',
    internal: 'Harmonizedname',
    apiEndPoint: 'harmonized-name',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'currency',
    attributeName: 'Currency',
    external: 'Currency',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'unique-tag',
    attributeName: 'Unique Tag',
    external: null,
    internal: 'Uniqueidentifier',
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'fact-type',
    attributeName: 'Fact Type',
    external: null,
    internal: 'Facttype',
    apiEndPoint: 'fact-type',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  }
];
