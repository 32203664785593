import React from 'react'
import { Container, Col, Row} from 'react-bootstrap';
import './AboutSection.scss';
import StaticContent from 'assets/images/staticContent.svg';

const AboutSection = () => {
  return (
    <Container fluid className='about-container'>
      <Row>
        <Col sm={6} className='content-col'>
          <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, 
          lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo,
          vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque
          fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet .
          </p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, 
          lectus magna fringilla ur</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, 
            lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo,
            vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque
            fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet .
          </p>
        </Col>
        <Col sm={6} className='image-content'>
          <img src={StaticContent} alt='static content' />
        </Col>
      </Row>
    </Container>
  )
}

export default AboutSection