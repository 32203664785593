import { useQuery,useMutation,useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from 'interceptor/axiosInstance';
import { MRT_ColumnFiltersState, MRT_SortingState } from 'mantine-react-table';
import { Pagination } from 'models/Pagination/pagination.model';
import { PeriodMeta } from 'models/PeriodMeta/periodMeta.model';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { deserialize } from 'serializr';

interface Params {
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
}

interface ParamsObject {
  offset?: Number,
  limit?: Number,
  filters?: string,
  sorting?: string
}

const PeriodMetadataService = () => {

  const fetchPeriodRecords = ({ columnFilters, sorting }: Params) => {
   
  return useInfiniteQuery({
    queryKey: ['period', columnFilters, sorting],
    queryFn: async ({ pageParam = 0 }) => {
        const paramsObj: ParamsObject = {
            offset: pageParam * 30,
            limit: 30,
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting ?? [])
        }
        const response = await axiosInstance.get(ApiRoutes.PERIOD_META, { params: paramsObj })
        return response.data
    },
    getNextPageParam: (_lastGroup, groups) => groups.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
})

  };

const createPeriodRecord = () => {

      const { isLoading: createIsLoading, mutateAsync: createMutate } = useMutation({
            mutationFn: async (records: PeriodMeta[]) => {
                const response = await axiosInstance.post(ApiRoutes.PERIOD_META, { records });
                return response.data
            }
        })
        return {
            createIsLoading,
            createMutate
        }
};

  const updatePeriodRecords = () => {
    const { isLoading: updateIsLoading, mutateAsync: updateMutate } = useMutation({
      mutationFn: async (records: PeriodMeta[]) => {
          const response = await axiosInstance.put(ApiRoutes.PERIOD_META, { records })
          return response.data
      }
  })
  return {
      updateIsLoading,
      updateMutate
  }
};

const deletePeriodRecords = () => {
  const { isLoading: deleteIsLoading, mutateAsync: deleteMutate } = useMutation({
    mutationFn: async (ids: number[]) => {
        const response = await axiosInstance.delete(ApiRoutes.PERIOD_META, {
            data: {
                ids
            }
        });
        return response.data
    },
})

return {
    deleteIsLoading,
    deleteMutate
}
};


  return {
    fetchPeriodRecords,
    updatePeriodRecords,
    createPeriodRecord,
    deletePeriodRecords
  };
};

export default PeriodMetadataService;
