import React, { Fragment } from 'react';
import { useExpanded, useTable } from 'react-table';
import './dqCheckStatusGrid.css';
import Passed from 'assets/icons/passed.svg';
import Failed from 'assets/icons/failed.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';

interface Props {
  columns: any;
  data: any;
  tableFor?: string;
  renderRowSubComponent?: any;
}

const DqCheckDataGrid: React.FC<Props> = ({ columns, data, tableFor, renderRowSubComponent }: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
    {
      columns,
      data
    },
    useExpanded
  );

  return (
    <table style={{ width: '100%' }} className='table-component' {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (       
          <tr {...headerGroup.getHeaderGroupProps()} className='table-header'>
            {headerGroup.headers.map((column) => {
              const data = column.render('Header');
          
              if(data =="File Name" ||data =="FileLevelCheck" ||data =="DataLevelCheck" || data =="") {
                return (
                  <th
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width }
                    })}
                    className={column.columns ? 'check-type' : 'check-name'}
                  >
                   {data} 
                  </th>
                );
              }
              else {
                 const desc = column.render('toolTip');
                return (
                  <th
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width }
                    })}
                     className={column.columns ? 'check-type' : 'check-name'}
                  >
                   {data} 
                   {/* <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Tooltip className='description_popup'>
                              {desc}
                              </Tooltip>
                            }
                          >
                          <IconInfoCircle/>
                          </OverlayTrigger> */}
                  </th>
                );
              }
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className='table-body'>
        {rows.map((row: any, index: any) => {
          prepareRow(row);
          return (
            <Fragment key={index}>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                          wordBreak: 'break-all'
                        }
                      })}
                    >
                      {cell['value'] === 'Success' || cell['value'] === 'passed' ? (
                        cell.row.original[cell.column.Header + '_message'] === null ||
                        cell.row.original[cell.column.Header + '_message'] === undefined ||
                        cell.row.original[cell.column.Header + '_message'] === '' ? (
                          <img src={Passed} alt='Passed' />
                        ) : (
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Tooltip className='message_popup'>
                                {cell.row.original[cell.column.Header + '_message'].includes('Delimiter')
                                  ? cell.row.original[cell.column.Header + '_message'].replace('@#', '| ')
                                  : cell.row.original[cell.column.Header + '_message']}
                              </Tooltip>
                            }
                          >
                            <img src={Passed} alt='' />
                          </OverlayTrigger>
                        )
                      ) : cell['value'] === 'Failed' || cell['value'] === 'Error' ? (
                        cell.row.original[cell.column.Header + '_message'] === null ||
                        cell.row.original[cell.column.Header + '_message'] === undefined ||
                        cell.row.original[cell.column.Header + '_message'] === '' ? (
                          <img src={Failed} alt='Failed' />
                        ) : (
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Tooltip className='message_popup'>
                                {cell.row.original[cell.column.Header + '_message'].includes('Delimiter')
                                  ? cell.row.original[cell.column.Header + '_message'].replace('@#', '| ')
                                  : cell.row.original[cell.column.Header + '_message']}
                              </Tooltip>
                            }
                          >
                            <img src={Failed} alt='' />
                          </OverlayTrigger>
                        )
                      ) : cell['value'] ? (
                        cell['value']
                      ) : (
                        <OverlayTrigger
                          placement='bottom'
                          overlay={<Tooltip className='message_popup'>Not Applicable / Available</Tooltip>}
                        >
                          <p>-</p>
                        </OverlayTrigger>
                      )}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                </tr>
              ) : null}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
export default DqCheckDataGrid;