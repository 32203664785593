import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Cards from 'shared/components/Card-component/Cards';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import useRedirect from 'shared/hooks/useRedirect';
const GlobalRmsSmlMapping = () => {
  const { redirectToSmlPcat } = useRedirect();
  return (
    <DashboardContainer>
      <div className='reports-page-content'>
        <Row>
          <Col xs={11} className='page-title-text'>
            <h1 className='page-title'>Global RMS SML Mapping</h1>
          </Col>
        </Row>

        <Row className='cards-container'>
          <Col xs={4}>
            <Cards heading='SML PCAT' image={''} cardHeaderClickHandler={redirectToSmlPcat} />
          </Col>
        </Row>
      </div>
    </DashboardContainer>
  );
};
export default GlobalRmsSmlMapping;
