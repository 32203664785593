import React, { useEffect, useMemo, useState } from 'react';
import { Button, ScrollArea } from '@mantine/core';
import Select from 'react-select';
import { useParams } from 'react-router';
import { DIMENSION_META } from '../Detail/constants/dimension';
import './remapping.scss';
import { useImmer } from 'use-immer';
import { useSearchParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';

const Remapping = ({ prop, drawerClosed }: any) => {
  const { dimension, status }: any = useParams();
  const [metaData, setMetaData] = useImmer<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showButton, setShowButton] = useState<boolean>(true);
  const [remappingApiOptions, setRemappingApiOptions] = useState<any>({
    provider: null,
    apiUrl: null,
    itemDependsOn: [],
    status: null
  });

  if (!dimension && !status) return <></>;
  const provider: any = searchParams.get('Provider');

  const { remappingOption, remappingUpdate } = DIMENSION_META[dimension][status];

  let remapping = '';

  if (provider === 'nielsen' || provider == 'pos') {
    const { remappingMeta } = DIMENSION_META[dimension][status][provider];
    remapping = remappingMeta;
  } else {
    const { remappingMeta } = DIMENSION_META[dimension][status]['rms'];
    remapping = remappingMeta;
  }

  const { data, isLoading, isFetching } = remappingOption(remappingApiOptions);

  const { mutate, isLoading: sumbitLoading, isError } = remappingUpdate();

  const resetMetaData = () => {
    setMetaData((meta: any) => {
      meta.length = 0;
    });

    const meta = JSON.parse(JSON.stringify(remapping));
    meta.map((item: any) => {
      item.value = item.internal ? (prop[item.internal] ? prop[item.internal] : 'NULL') : null;
      setMetaData((element: any) => {
        element.push(item);
      });
    });
  };

  useMemo(() => {
    resetMetaData();
  }, []);

  useEffect(() => {
    if (isLoading && remappingApiOptions['apiUrl'] && isFetching) {
      setMetaData((meta: any) => {
        const element = meta.find((e: any) => e['apiEndPoint'] === remappingApiOptions['apiUrl'].split('?')[0]);
        if (element) {
          element.isLoading = true;
          element.option = [];
        }
      });
    }

    if (data && remappingApiOptions['apiUrl'] && !isFetching) {
      const modifiedData = data.map((item: any) => {
        return { label: item.name ? item.name : 'NULL', value: item.name ? item.name : 'NULL' };
      });

      setMetaData((meta: any) => {
        const element = meta.find((e: any) => e['apiEndPoint'] === remappingApiOptions['apiUrl'].split('?')[0]);

        if (element) {
          const jsonObject = modifiedData.map(JSON.stringify);
          const uniqueSet = new Set(jsonObject);
          element.option = Array.from(uniqueSet).map((value: any) => JSON.parse(value));
          element.isLoading = false;
        }
      });
    }
  }, [data, isLoading, remappingApiOptions]);

  function getOptionsAsync(item: any) {
    const api: any = [];
    item.apiDependsOn.forEach((attribute: string) => {
      metaData.map((obj: any) => {
        if (obj.internal == attribute) {
          const encodedValue = encodeURIComponent(obj.value);
          if (encodedValue !== 'NA') api.push(attribute + '=' + encodedValue);
        }
      });
    });
    let apiUri = '';
    if (api.length) apiUri = item.apiEndPoint + '?' + api.join('&');
    else apiUri = item.apiEndPoint;

    setRemappingApiOptions({
      provider: provider === 'nielsen' ? 'nielsen' : provider === 'pos' ? 'pos' : 'rms',
      apiUrl: apiUri,
      itemDependsOn: item.dependsOn,
      status: status
    });
  }

  useEffect(() => {
    let flag = true;
    metaData.forEach((element: any) => {
      if (element.internal) {
        if (!element.value) flag = false;
      }
    });
    setShowButton(!flag);
  }, [metaData]);

  const handleChange = (newValue: any, meta: any) => {
    const [key, internal] = meta.name.split('**');
    const resetArrays: any = [];

    setMetaData((meta: any) => {
      const element = meta.find((e: any) => e.key === key);
      element.value = newValue && newValue.value ? newValue.value : '';
    });

    metaData.forEach((meta: any, index: number) => {
      if (meta.dependsOn && meta.dependsOn.includes(internal)) {
        resetArrays.push(index);
      }
    });

    resetArrays.forEach((index: number) => {
      setMetaData((meta: any) => {
        meta[index].value = '';
      });
    });
  };

  const checkDisableStatus = (item: any) => {
    let status = false;
    item.dependsOn.forEach((attribute: string) => {
      metaData.forEach((obj: any) => {
        if (obj.internal === attribute) {
          if (!obj.value || obj.value === '') status = true;
        }
      });
    });
    return status;
  };

  const handleSubmit = () => {
    const attributes: any = {};
    metaData.forEach((item: any) => {
      if (item.value !== null) {
        attributes[item.internal] = item.value;
      }
    });

    const updateOptions = {
      provider: provider === 'nielsen' ? 'nielsen' : provider === 'pos' ? 'pos' : 'rms',
      value: attributes,
      id: prop['Id'],
      status: status
    };

    if (dimension === 'product' && status === 'unprocessed' && searchParams.get('Provider') === 'nielsen') {
      updateOptions['value']['Id'] = prop['Id'];
    }

    mutate(updateOptions, {
      onSuccess: async (data: any) => {
        drawerClosed();
        return (
          <>
            {notifications.show({
              title: 'Success',
              message: data.message,
              color: 'green',
              icon: <IconCheck />,
              autoClose: 2000,
              withBorder: true
            })}
          </>
        );
      },
      onError: async (data: any) => {
        return (
          <>
            {notifications.show({
              title: 'Failure',
              message: 'Updation Unsuccessfull ' + data.error.message,
              color: 'green',
              icon: <IconX />,
              autoClose: 2000,
              withBorder: true
            })}
          </>
        );
      }
    });
  };

  return (
    <>
      <div className='drawer-header'>
        <Button onClick={resetMetaData} loading={sumbitLoading} variant='outline' color='red'>
          Reset
        </Button>
        <Button style={{backgroundColor:'#09a75e'}}onClick={handleSubmit} disabled={showButton} loading={sumbitLoading}>
          Submit
        </Button>
      </div>
      <ScrollArea h={'80vh'}>
        <table style={{ tableLayout: 'fixed', width: '100%' }} className='remapping-table'>
          <thead>
            <tr>
              <th>Attributes</th>
              <th>External</th>
              <th>Internal</th>
            </tr>
          </thead>
          <tbody>
            {metaData.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.attributeName}</td>
                <td>{item.external ? prop[item.external] : 'N/A'}</td>
                <td style={{ minWidth: '400px' }}>
                  {item.internal ? (
                    item.showDropdown ? (
                      <Select
                        menuPlacement='auto'
                        minMenuHeight={300}
                        options={item.option}
                        onMenuOpen={() => getOptionsAsync(item)}
                        onChange={handleChange}
                        key={item.key}
                        isClearable={item.value ? true : false}
                        name={`${item.key}**${item.internal}`}
                        isLoading={item.isLoading}
                        value={
                          item.value
                            ? { label: item.value, value: item.value }
                            : item.value === ''
                            ? { label: '', value: '' }
                            : item.option
                            ? { label: item.option[0], value: item.option[0] }
                            : { label: '', value: '' }
                        }
                        isOptionDisabled={() => checkDisableStatus(item)}
                        placeholder=''
                      />
                    ) : (
                      <p>{item.value}</p>
                    )
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScrollArea>
    </>
  );
};

export default Remapping;
