import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { DqCheck } from '../../models/DqCheck/dqCheck.model';
import { Pagination } from './../../models/Pagination/pagination.model';
import { MetaData } from 'models/MetaData/metaData.model';
import { DqStats } from 'models/DqStats/dqStats.model';

const DqCheckService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [dqChecks, setDqChecks] = useState<DqCheck[]>([]);

  var stats={
    TotalCells:0,
    TotalFiles:0,
    FVSuccessRate:0,
    AverageFVError:0,
    DQScore:0,
    TotalDQScore:0
  }

  const [dqStats, setDqStats] = useState(stats);

  const [dqChecksPagination, setDqChecksPagination] = useState<Pagination | null>();

  const [deliveryPeriodMeta, setDeliveryPeriodMeta] = useState<MetaData[]>([]);

  const fetchDqChecks = async (params?: {
    limit?: number;
    offset?: number;
    filter_by_delivery_period?: string;
    filter_by_country?: string;
    filter_by_data_provider?: string;
    filter_by_category?: string;
  }) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.DQ_CHECKS, { params });
      const data = deserialize(DqCheck, response.data['result']) as DqCheck[];
      setDqChecks(data);
    } catch (error) {
      setDqChecks([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchDqChecksPaginationData = async (params?: {
    limit?: number;
    offset?: number;
    filter_by_delivery_period?: string;
    filter_by_country?: string;
    filter_by_data_provider?: string;
    filter_by_category?: string;
  }) => {
    try {
      setPaginationLoading(true);
      const response = await axiosInstance.get(ApiRoutes.DQ_CHECKS_COUNT, { params });
      const pagination = deserialize(Pagination, response.data);
      setDqChecksPagination(pagination);
    } catch (error) {
      setDqChecksPagination(null);
    } finally {
      setPaginationLoading(false);
    }
  };

  const fetchDQCardTotalCells = async () => {
    try {
      setButtonLoading(true);
      const TotalCells = await axiosInstance.get(ApiRoutes.DQ_CHECKS_TOTAL_CELL);
      const res = TotalCells.data.result
      stats["TotalCells"]=res.TotalCells
      setDqStats((prevState)=>({...prevState,TotalCells:res.TotalCells}))
    } catch (error) {
      // setDqStats(stats);
    } finally {
      setButtonLoading(false);
    }
  };

  const fetchDQCardTotalFiles = async () => {
    try {
      const TotalFiles = await axiosInstance.get(ApiRoutes.DQ_CHECKS_TOTAL_FILES);
      const res = TotalFiles.data.result
      setDqStats((prevState)=>({...prevState,TotalFiles:res.TotalFiles}))
    } catch (error) {
    } finally {
      setButtonLoading(false);
    }
  };

  const fetchDQCardFVSuccessRate = async () => {
    try {
      const FvSuccessRate = await axiosInstance.get(ApiRoutes.DQ_CHECKS_FV_SUCCESS_RATE);
      const res = FvSuccessRate.data.result
      setDqStats((prevState)=>({...prevState,FVSuccessRate:res.Success_Rate}));
    } catch (error) {
    } finally {
      setButtonLoading(false);
    }
  };

  const fetchDQCardAverageFVError = async () => {
    try {
      const AverageFvError = await axiosInstance.get(ApiRoutes.DQ_CHECKS_AVERAGE_FV_ERROR);
      const res = AverageFvError.data.result
      setDqStats((prevState)=>({...prevState,AverageFVError:res.AverageFailureRate}))
    } catch (error) {
    } finally {
      setButtonLoading(false);
    }
  };

  const fetchDQCardScore = async () => {
    try {
      const DqScore = await axiosInstance.get(ApiRoutes.DQ_CHECKS_DQ_SCORE);
      const res = DqScore.data.result
      setDqStats((prevState)=>({...prevState,DQScore:res.SuccessRate}));
    } catch (error) {
    } finally {
      setButtonLoading(false);
    }
  };
  const fetchDQCardTotalScore = async () => {
    try {
      const TotalDqScore = await axiosInstance.get(ApiRoutes.DQ_CHECKS_TOTAL_DQ_SCORE);
      const res = TotalDqScore.data.result
      setDqStats((prevState)=>({...prevState,TotalDQScore:res.TotalDqScore}));
    } catch (error) {
    } finally {
      setButtonLoading(false);
    }
  };

  const downloadDQReport = async (params?: {
    limit?: number;
    offset?: number;
    filter_by_delivery_period?: string;
    filter_by_country?: string;
    filter_by_data_provider?: string;
    filter_by_category?: string;
  }) => {
    try {
      setButtonLoading(true);
      const response = await axiosInstance.get(ApiRoutes.DQ_CHECKS_REPORT, {
        params,
        responseType: 'blob'
      });

      const contentDisposition = response.headers['content-disposition'];
      const matchName = contentDisposition.replace('attachment; filename=', '');
      const filename = matchName.split('.')[0] + '.xlsx';

      const urlLink = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = urlLink;
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      // setDqStats(stats);
    } finally {
      setButtonLoading(false);
    }
  };

  return {
    loading,
    dqChecks,
    dqChecksPagination,
    deliveryPeriodMeta,
    dqStats,
    buttonLoading,
    fetchDqChecks,
    fetchDQCardTotalCells,
    fetchDQCardTotalFiles,
    fetchDQCardFVSuccessRate,
    fetchDQCardAverageFVError,
    fetchDQCardScore,
    fetchDQCardTotalScore,
    downloadDQReport,
    fetchDqChecksPaginationData,
    paginationLoading
  };
};

export default DqCheckService;
