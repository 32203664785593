import { useMutation, useQuery,useInfiniteQuery } from '@tanstack/react-query';
import { deserialize } from 'serializr';
import axiosInstance from 'interceptor/axiosInstance';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { FactMeta } from 'models/FactMeta/factMeta.model';
import { Pagination } from 'models/Pagination/pagination.model';
import { type MRT_ColumnFiltersState, type MRT_SortingState } from 'mantine-react-table';

interface Params {
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
}

interface ParamsObject {
  offset?: Number;
  limit?: Number;
  filters?: string;
  sorting?: string;
}

const FactMetadataService = () => {
  const fetchFactMetaRecords = ({  columnFilters, sorting }: Params) => {
    return useInfiniteQuery({
      queryKey: ['factmeta', columnFilters, sorting],
      queryFn: async ({ pageParam = 0 }) => {
          const paramsObj: ParamsObject = {
              offset: pageParam * 30,
              limit: 30,
              filters: JSON.stringify(columnFilters ?? []),
              sorting: JSON.stringify(sorting ?? [])
          }
          const response = await axiosInstance.get(ApiRoutes.FACT_META, { params: paramsObj })
          return response.data
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
  })

  };

  const updateFactRecord = () => {
    const { isLoading: updateIsLoading, mutateAsync: updateMutate } = useMutation({
      mutationFn: async (records: FactMeta[]) => {
        const response = await axiosInstance.put(ApiRoutes.FACT_META, { records });
        return response.data;
      }
    });
    return {
      updateIsLoading,
      updateMutate
    };
  };

  const createFactRecord = () => {
    const { isLoading: createIsLoading, mutateAsync: createMutate } = useMutation({
      mutationFn: async (records: FactMeta[]) => {
        const response = await axiosInstance.post(ApiRoutes.FACT_META, { records });
        return response.data;
      }
    });
    return {
      createIsLoading,
      createMutate
    };
  };

  const deleteFactRecords = () => {
    const { isLoading: deleteIsLoading, mutateAsync: deleteMutate } = useMutation({
      mutationFn: async (ids: number[]) => {
        const response = await axiosInstance.delete(ApiRoutes.FACT_META, {
          data: {
            ids
          }
        });
        return response.data;
      }
    });

    return {
      deleteIsLoading,
      deleteMutate
    };
  };

  return {
    fetchFactMetaRecords,
    updateFactRecord,
    createFactRecord,
    deleteFactRecords
  };
};
export default FactMetadataService;
