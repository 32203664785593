import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React from 'react';

const MantineTable = (props: any) => {
  const table = useMantineReactTable({
    columns: props.columns,
    data: props.data ? props.data : [],
    enablePinning: props.enablePinning ? true : false,
    positionGlobalFilter: 'left',
    enableStickyHeader: props.staticHeader ? true : false,
    initialState: {
      showGlobalFilter: props.showGlobalFilter ? true : false,
      columnPinning: props.columnPinning ? props.columnPinning : {},
      columnVisibility: {
        'mrt-row-actions': props.showRemappingColumn ? props.showRemappingColumn : false
      }
    },
    manualFiltering: props.manualFiltering ? true : false,
    manualSorting: props.manualSorting ? true : false,
    state: {
      columnFilters: props.columnFilters ? props.columnFilters : [],
      sorting: props.sorting ? props.sorting : [],
      isLoading: props.isLoading ? true : false,
      showProgressBars: props.isLoading ? true : false
    },
    onColumnFiltersChange: props.onColumnFiltersChange ? props.onColumnFiltersChange : null,
    onSortingChange: props.onSortingChange ? props.onSortingChange : null,
    enableColumnOrdering: props.enableColumnOrdering ? true : false,
    enableGlobalFilter: props.enableGlobalFilter ? true : false,
    enableDensityToggle: props.enableDensityToggle ? true : false,
    enableFullScreenToggle: props.enableFullScreenToggle ? true : false,
    enableColumnActions: props.enableColumnActions ? true : false,
    enablePagination: props.enablePagination ? true : false,
    enableRowActions: props.enableRowActions ? true : false,
    enableRowNumbers: props.enableRowNumbers,
    enableColumnFilters: props.enableColumnFilters ? true : false,
    positionActionsColumn: props.positionActionsColumn,
    rowVirtualizerInstanceRef: props.rowVirtualizerInstanceRef ? props.rowVirtualizerInstanceRef : null,
    mantineTableBodyRowProps: ({ row }) =>( props.mantineTableBodyRowProps ? props.mantineTableBodyRowProps({ row }): <tr></tr> ),
    mantineTableContainerProps: {
      ref: props.tableContainerRef ? props.tableContainerRef : null,
      sx: {
        maxHeight: '60vh'
      },
      onScroll: (event) => props.fetchMoreOnBottomReached(event.target)
    },
    renderRowActions: ({ row, table }) => (props.customActions ? props.customActionButton({ row, table }) : <></>),
    renderTopToolbarCustomActions: ({ table }) =>
      props.renderTopToolbarCustomActions ? props.renderTopToolbarCustomActions({ table }) : <></>,
    mantineSearchTextInputProps: props.mantineSearchTextInputProps,
    mantineTableProps: {
      withColumnBorders: true,
      highlightOnHover: true
    },
    renderBottomToolbarCustomActions: props.renderBottomToolbarCustomActions,
    onGlobalFilterChange: props.onGlobalFilterChange ? props.onGlobalFilterChange : null,
    displayColumnDefOptions: props.displayColumnDefOptions ? props.displayColumnDefOptions : null,
    renderToolbarInternalActions: ({ table }) =>
      props.renderToolbarInternalActions ? props.renderToolbarInternalActions({ table }) : <></>
  });

  return <MantineReactTable table={table} />;
};

export default MantineTable;
