import React, { Children, useEffect } from 'react';
import { Routes, Route, Outlet, Navigate, useNavigate } from 'react-router-dom';
import type { RouterProps } from '../shared/types/route.type';
import { AppRoutes } from './routeConstants/appRoutes';
import DesignLibrary from 'views/DesignLibrary';
import ValidationDashboard from 'views/ValidationFlow/ProductDimension/ValidationDashboard';
import VolatilityDashboard from 'views/FileVolatility/VolatilityDashboard';
import VolatilityHome from 'views/FileVolatility/VolatilityHome';
import VolatilityMapping from 'views/FileVolatility/VolatilityMapping';
import OperationGraph from 'views/HomeView';
import { ProfileContent } from 'shared/components/SSOProfileContent';
import WorkUnderProgress from 'views/WorkUnderProgress';
import RoleSelectionFlow from 'views/RoleSelectionFlow';
import LeadershipDashboard from 'views/LeadershipDashboard';
import VolatilityMappingFact from 'views/FactFileVolatility/VolatilityMapping/VolatilityMappingFact';
import ValidationHome from 'views/ValidationFlow/ProductDimension/ValidationHome';
import DqCheckPage from 'views/DqCheckPage';
import GlobalRmsSmlMapping from 'views/Admin/GlobalRmsSmlMapping';
import SmlPcat from 'views/Admin/GlobalRmsSmlMapping/SmlPcat';
import { msalInstance } from 'authConfig';
import AccessDenied from 'shared/components/AccessDenied';
import AcceptanceReport from 'views/Reports/AcceptanceReport/AcceptanceReport';
import AcceptanceReportDetails from 'views/Reports/AcceptanceReport/AcceptanceReportDetails';
import CriticalAttribute from 'views/Admin/GlobalRmsMetadataMapping/CriticalAttribute';
import GlobalMetadataMapping from 'views/Admin/GlobalRmsMetadataMapping';
import FactMetaData from 'views/Admin/GlobalRmsMetadataMapping/Fact';
import MarketMetaData from 'views/Admin/GlobalRmsMetadataMapping/Market';
import CellControl from 'views/Admin/CellControl';
import PeriodMetada from 'views/Admin/GlobalRmsMetadataMapping/Period';
import SmartmappingSummary from 'views/SmartMapping/Summary';
import IntelligentMappingDetails from 'views/SmartMapping/Detail';
import FVMapping from 'views/FileVolatility/Mapping';

const AppRouter: React.FC = () => {
  const navigate = useNavigate();

  const routes: RouterProps[] = [
    {
      path: '*',
      element: <Navigate to={AppRoutes.OPERATION_GRAPH} />,
      children: []
    },
    {
      path: AppRoutes.LEADERSHIP_GRAPH,
      element: <LeadershipDashboard />,
      children: []
    },
    {
      path: AppRoutes.OPERATION_GRAPH,
      element: <OperationGraph />,
      children: []
    },
    {
      path: AppRoutes.SSO_TEST,
      element: <ProfileContent />,
      children: []
    },
    {
      path: AppRoutes.ROLE_SELECTION,
      element: <RoleSelectionFlow />,
      children: []
    },
    {
      path: AppRoutes.CHECKS,
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <ValidationHome />
        },
        {
          path: AppRoutes.FILE_VOLATALITY_RECORDS,
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <VolatilityDashboard />
            },
            {
              path: AppRoutes.FILE_VOLATILITY_MAPPED,
              element: <FVMapping />
            }
            // {
            //   path: AppRoutes.FILE_VOLATILITY_MARKET_MAPPED,
            //   element: <VolatilityMapping entity='Market' />
            // },
            // {
            //   path: AppRoutes.FILE_VOLATILITY_FACT_MAPPED,
            //   element: <VolatilityMapping entity='Fact' />
            // },
            // {
            //   path: AppRoutes.FILE_VOLATILITY_PERIOD_MAPPED,
            //   element: <VolatilityMapping entity='Period' />
            // }
          ]
        },
        {
          path: AppRoutes.INTELLIGENT_MAPPING,
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <SmartmappingSummary />
            },
            {
              path: ':dimension',
              element: <SmartmappingSummary />
            },
            {
              path: ':dimension/:status',
              element: <IntelligentMappingDetails />
            }
          ]
        }
      ]
    },
    {
      path: AppRoutes.FILE_VOLATILITY_HOME,
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <VolatilityHome />
        },

        {
          path: AppRoutes.DQ_CHECK,
          element: <DqCheckPage />
        },
        // {
        //   path: AppRoutes.ACCEPTANCE_REPORT,
        //   element: <Outlet />,
        //   children: [
        //     { path: '', element: <AcceptanceReport /> },
        //     {
        //       path: AppRoutes.ACCEPTANCE_REPORT_DETAILS,
        //       element: <AcceptanceReportDetails />
        //     }
        //   ]
        // }
      ]
    },
    {
      path: AppRoutes.ADMIN,
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <Navigate to={AppRoutes.GLOBAL_RMS_SML_MAPPING} />
        },
        {
          path: AppRoutes.GLOBAL_RMS_SML_MAPPING,
          element: <GlobalRmsSmlMapping />
        },
        {
          path: AppRoutes.SML_PCAT,
          element: <SmlPcat />
        },
        {
          path: AppRoutes.GLOBAL_RMS_METADATA_MAPPING,
          element: <GlobalMetadataMapping />
        },
        {
          path: AppRoutes.FACT_METADATA,
          element: <FactMetaData />
        },
        {
          path: AppRoutes.CRITICAL_ATTRIBUTE,
          element: <CriticalAttribute />
        },
        {
          path: AppRoutes.CELL_CONTROL,
          element: <CellControl />
        },
        {
          path: AppRoutes.MARKET_METADATA,
          element: <MarketMetaData />
        },
        {
          path: '*',
          element: <Navigate to={AppRoutes.GLOBAL_RMS_SML_MAPPING} />
        },
        {
          path: AppRoutes.PERIOD_METADATA,
          element: <PeriodMetada />
        }
      ]
    },
    {
      path: '/auth',
      element: <Navigate to={'/checks'} />,
      children: []
    },
    {
      path: '/access-denied',
      element: <AccessDenied />,
      children: []
    }
  ];

  if (process.env.REACT_APP_UNDER_DEVELOPMENT === 'true') {
    routes.push({
      path: AppRoutes.ROOT,
      element: <DesignLibrary />,
      children: []
    });
  }

  // useEffect(() => {
  //   const accountObj = msalInstance.getAllAccounts()[0];
  //   let nonEdithUser = true;
  //   accountObj.idTokenClaims?.roles?.forEach((role: string) => {
  //     role.toLowerCase() === 'edith_user' ? (nonEdithUser = false) : '';
  //   });
  //   if (!accountObj?.idTokenClaims?.roles && nonEdithUser) {
  //     navigate('/access-denied');
  //   }
  // }, []);

  return (
    <>
      <Routes>
        {routes?.map((route, index) => {
          return (
            <Route key={index} path={route.path} element={route.element}>
              {route.children &&
                route.children.map((childRoute) => (
                  <Route key={childRoute.path} path={childRoute.path} element={childRoute.element}>
                    {childRoute.children &&
                      childRoute.children.map((grandchildRoute) => (
                        <Route key={grandchildRoute.path} path={grandchildRoute.path} element={grandchildRoute.element} />
                      ))}
                  </Route>
                ))}
            </Route>
          );
        })}
      </Routes>
    </>
  );
};

export default AppRouter;
