import React from 'react';
import { Col, Row, Toast, ToastContainer } from 'react-bootstrap';
import './Toast-component.scss';
interface ToastComponentProps {
  showState: boolean;
  toggleShow: () => void;
  message: string;
  status?: string;
  className?: string;
}

const ToastComponent: React.FC<ToastComponentProps> = ({ showState, toggleShow, message, status, className }: ToastComponentProps) => {
  return (
    <div className={className}>
      <ToastContainer position='top-end' className='toast-component'>
        <Toast className='toast-component-toast' show={showState} onClose={toggleShow} delay={3000} autohide>
          <Toast.Header className='toast-component-header' closeVariant='white'>
            <Row>
              <Col className='toast-status' xl={4}>
                <strong>{status ?? 'Successful'} </strong>
              </Col>
              <Col className='toast-content'>{message}</Col>
            </Row>
          </Toast.Header>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default ToastComponent;
