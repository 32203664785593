export const POSProductMapppedRemapping = [
  {
    key: 'identifier-name',
    attributeName: 'Product Identifier Name',
    internal: null,
    external: 'Productidentifiername',
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'product-identifier',
    attributeName: 'Product Identifier',
    internal: null,
    external: 'Productidentifier',
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },

  {
    key: 'division-name',
    attributeName: 'Division Name',
    internal: 'Divisionname',
    external: null,
    apiEndPoint: 'division-name',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'category-name',
    attributeName: 'Category Name',
    internal: 'Categoryname',
    external: null,
    apiEndPoint: 'category-name',
    dependsOn: ['Divisionname'],
    apiDependsOn: ['Divisionname'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'market-name',
    attributeName: 'Market Name',
    internal: 'Marketname',
    external: null,
    apiEndPoint: 'market-name',
    dependsOn: ['Categoryname', 'Divisionname'],
    apiDependsOn: ['Categoryname', 'Divisionname'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'corporate-brand-name',
    attributeName: 'Corporate Brand Name',
    internal: 'Corporatebrandname',
    external: 'Brand',
    apiEndPoint: 'corporate-brand-name',
    dependsOn: ['Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Marketname', 'Categoryname', 'Divisionname'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'sector-name',
    attributeName: 'Sector Name',
    internal: 'Sectorname',
    external: null,
    apiEndPoint: 'sector-name',
    dependsOn: ['Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'segment-name',
    attributeName: 'Segment Name',
    internal: 'Segmentname',
    external: null,
    apiEndPoint: 'segment-name',
    dependsOn: ['Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'product-form-name',
    attributeName: 'Product Form Name',
    internal: 'Productformname',
    external: null,
    apiEndPoint: 'product-form-name',
    dependsOn: ['Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'form-name',
    attributeName: 'Form Name',
    internal: 'Formname',
    external: null,
    apiEndPoint: 'form-name',
    dependsOn: ['Productformname', 'Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Productformname', 'Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'sub-form-name',
    attributeName: 'Subform Name',
    internal: 'Subformname',
    external: null,
    apiEndPoint: 'sub-form-name',
    dependsOn: [
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'sub-product-form-variant-name',
    attributeName: 'Sub Product Form Variant Name',
    internal: 'Spfvname',
    external: null,
    apiEndPoint: 'sub-product-form-variant-name',
    dependsOn: [
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'product-pack-form-name',
    attributeName: 'Product Pack Form Name',
    internal: 'Productpackformname',
    external: null,
    apiEndPoint: 'product-pack-form-name',
    dependsOn: [
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'product-pack-size-name',
    attributeName: 'Product Packsize Name',
    internal: 'Productpacksizename',
    external: null,
    apiEndPoint: 'product-pack-size-name',
    dependsOn: [
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'product-variant-name',
    attributeName: 'Product Variant Name',
    internal: 'Productvariantname',
    external: null,
    apiEndPoint: 'product-variant-name',
    dependsOn: [
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'product-code-name',
    attributeName: 'Product Code Name',
    internal: 'Productcodename',
    external: null,
    apiEndPoint: 'product-code-name',
    dependsOn: [
      'Productvariantname',
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Productvariantname',
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'product-name',
    attributeName: 'Product Description',
    external: 'Externaldesc',
    internal: 'Internaldesc',
    apiEndPoint: 'product-name',
    dependsOn: [
      'Productcodename',
      'Productvariantname',
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    apiDependsOn: [
      'Productcodename',
      'Productvariantname',
      'Productpacksizename',
      'Productpackformname',
      'Spfvname',
      'Subformname',
      'Formname',
      'Productformname',
      'Segmentname',
      'Sectorname',
      'Corporatebrandname',
      'Marketname',
      'Categoryname',
      'Divisionname'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  }
];
