import { PeriodMeta } from 'models/PeriodMeta/periodMeta.model';
import React, { useEffect, useState ,useRef,useMemo,useCallback} from 'react';
import { periodMetadataColumn } from './column';
import { MRT_TableOptions, MRT_ColumnFiltersState, MRT_SortingState, MRT_Virtualizer } from 'mantine-react-table';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import MantineTable from 'shared/components/MantineTable';
import { Button, Flex, Tooltip, ActionIcon, Loader, Text } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import PeriodMetadataService from 'services/AdminService/periodmetadata.service';
import '../../GlobalRmsSmlMapping/SmlPcat/smlPcat.scss';

const PeriodMetada = () => {
  const tableColumns: any = periodMetadataColumn;
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [tableElement, setTableElement] = useState<any>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  const [tempData, setTempData] = useState<any>([]);
  const [modifiedRecords, setModifiedRecords] = useState({ created: [], edited: [], deleted: [] });

  //Api calls
  const { fetchPeriodRecords, updatePeriodRecords, createPeriodRecord, deletePeriodRecords } =
    PeriodMetadataService();

  const { data, isError, isLoading, refetch,fetchNextPage, isFetching, isPreviousData } = fetchPeriodRecords({ sorting, columnFilters });

  const { createIsLoading, createMutate } = createPeriodRecord();

  const { updateIsLoading, updateMutate } = updatePeriodRecords();

  const { deleteIsLoading, deleteMutate } = deletePeriodRecords();

  const periodMetaData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result) ?? []
  },
    [data],
  );

  useEffect(() => {
    setTempData(periodMetaData ? periodMetaData : []);
  }, [data]);

  const totalDBRowCount = data?.pages?.[0]?.count ?? 0;
  const totalFetched = periodMetaData.length;

  const handleClear = () => {
    setModifiedRecords({
      created: [],
      edited: [],
      deleted: []
    });
    setTempData(data ? data : []);
  };

  const handleSave: MRT_TableOptions<PeriodMeta>['onEditingRowSave'] = async ({ values, table, row }) => {
    const editedRecords = [...modifiedRecords.edited, values];
    const editedIndex = row.index;
    setModifiedRecords((prev: any) => {
      const data = prev;
      data.edited = editedRecords;
      return data;
    });
    setTempData((prevTempData: any) => {
      const updatedTempData = prevTempData.map((record: any, index: any) => (index === editedIndex ? values : record));
      return updatedTempData;
    });
    table.setEditingRow(null);
  };

  const handleCreate: MRT_TableOptions<PeriodMeta>['onCreatingRowSave'] = ({ values, exitCreatingMode }) => {
    const { Id, ...rest } = values;
    const createdRecords = [...modifiedRecords.created, rest];
    setModifiedRecords((prev: any) => {
      const data = prev;
      data.created = createdRecords;
      return data;
    });
    setTempData([values, ...tempData]);

    exitCreatingMode();
  };

  const handleSaveChanges = () => {
    modifiedRecords.created.length
      ? createMutate(modifiedRecords.created, {
          onSuccess: async (data) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Success',
                  message: 'Records Creation successfull',
                  color: 'green',
                  icon: <IconCheck />,
                  autoClose: 2000
                })}
              </>
            );
          },
          onError: async (data: any) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Failure',
                  message: 'Record Creation Unsuccessfull' + data.error.message,
                  color: 'green',
                  icon: <IconX />,
                  autoClose: 2000
                })}
              </>
            );
          }
        })
      : null;

    modifiedRecords.edited.length
      ? updateMutate(modifiedRecords.edited, {
          onSuccess: async (data) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Success',
                  message: 'Records Updation successfull',
                  color: 'green',
                  icon: <IconCheck />,
                  autoClose: 2000
                })}
              </>
            );
          },
          onError: async (data: any) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Failure',
                  message: 'Updation Unsuccessfull ' + data.error.message,
                  color: 'green',
                  icon: <IconX />,
                  autoClose: 2000
                })}
              </>
            );
          }
        })
      : null;

    modifiedRecords.deleted.length
      ? deleteMutate(modifiedRecords.deleted, {
          onSuccess: async (data) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Success',
                  message: 'Deletion successfull',
                  color: 'green',
                  icon: <IconCheck />,
                  autoClose: 2000
                })}
              </>
            );
          },
          onError: async (data: any) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Failure',
                  message: 'Deletion Unsuccessfull ' + data.error.message,
                  color: 'green',
                  icon: <IconX />,
                  autoClose: 2000
                })}
              </>
            );
          }
        })
      : null;

    setModifiedRecords({
      created: [],
      edited: [],
      deleted: []
    });
  };

  const fetchMoreOnBottomReached = useCallback((containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      setTableElement(containerRefElement)

      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !isFetching &&
        totalFetched < totalDBRowCount
      ) {

        fetchNextPage();
      }
    }
  },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, columnFilters]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [tempData])

  return (
    <DashboardContainer>
      <h3>Period Metadata</h3>
      <MantineTable
        data={tempData}
        columns={tableColumns}
        customActions={true}
        columnPinning={{ left: ['mrt-row-actions'] }}
        enablePinning={true}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableStickyHeader={true}
        enablePagination={false}
        enableColumnActions={false}
        enableGlobalFilter={false}
        manualFiltering={true}
        manualSorting={true}
        onColumnFiltersChange={setColumnFilters}
        onSortingChange={setSorting}
        columnFilters={columnFilters}
        isLoading={isLoading }
        isFetching = {isFetching}
        fetchMoreOnBottomReached={(val: any) => fetchMoreOnBottomReached(val)}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        tableContainerRef={tableContainerRef}
        isPreviousData={isPreviousData}
        sorting={sorting}
        mantineToolbarAlertBannerProps={
          isError 
            ? {
                color: 'red',
                children: 'Error loading data'
              }
            : undefined
        }
        customActionButton={({ row, table }: any) => (
          <Flex gap='md'>
            <Tooltip label='Edit' position='right'>
              <ActionIcon onClick={() => table.setEditingRow(row)}>
                <IconEdit />
              </ActionIcon>
            </Tooltip>
            <Tooltip label='Delete' position='left'>
              <ActionIcon
                color='red'
                onClick={() => {
                  const deleteRecords = [...modifiedRecords.deleted, row.original.Id];
                  setModifiedRecords((prev: any) => {
                    const data = prev;
                    data.deleted = deleteRecords;
                    return data;
                  });
                  tempData.splice(row.index, 1);
                  setTempData([...tempData]);
                }}
              >
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </Flex>
        )}
        customRecordsButton={({ table }: any) => (
          <div style={{ display: 'flex', gap: '8px' }}>
            {!createIsLoading && !updateIsLoading && !deleteIsLoading ? (
              <>
                <Button
                  onClick={() => {
                    table.setCreatingRow(true);
                    tableElement.scrollTop = 0;
                  }}
                >
                  New Metadata
                </Button>
                <Button
                  color='green'
                  disabled={
                    modifiedRecords.created.length === 0 && modifiedRecords.deleted.length === 0 && modifiedRecords.edited.length === 0
                  }
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
                <Button
                  color='red'
                  disabled={
                    modifiedRecords.created.length === 0 && modifiedRecords.deleted.length === 0 && modifiedRecords.edited.length === 0
                  }
                  onClick={handleClear}
                >
                  Clear Changes
                </Button>
              </>
            ) : (
              <Loader size={38} />
            )}
          </div>
        )}
        onEditingRowSave={handleSave}
        onCreatingRowSave={handleCreate}
        renderBottomToolbarCustomActions={() => {
          return (
             <div className='bottom-Action'>
              <p>Total Records : {totalDBRowCount}</p>
              <Text className='bottom-text'>
                Showing {totalFetched} of {totalDBRowCount} total rows.
              </Text>
            </div>
          );
        }}
        enableRowActions={true}
        enableEditing={true}
      />
    </DashboardContainer>
  );
};
export default PeriodMetada;
