import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "interceptor/axiosInstance";
import { ResponseTypes } from 'enums/responseTypes';


const PeriodMappedServices = () => {
  const PeriodMappedRecords = ({ filename, filters, sorting, status = null, provider = null }: any) => {

    const { data, isLoading, error, isPreviousData, isFetching, fetchNextPage, refetch } = useInfiniteQuery({
      queryKey: ['period', filename, status, filters, sorting, provider],
      queryFn: async ({ pageParam = 0 }) => {
        const params = {
          offset: pageParam * 30,
          limit: 30,
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };
        const modifiedURL = provider ? `period/${provider.toLowerCase()}` : 'period';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/mapped`, { params });
        return response.data['result'];
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });

    return {
      data,
      isLoading,
      error,
      refetch,
      isPreviousData,
      isFetching,
      fetchNextPage
    }
  }

  const PeriodMappedRecordsCount = ({ filename, filters, sorting, status = null, provider = null }: any) => {
    const { data, isError, isLoading, refetch } = useQuery({
      queryKey: ['periodMappedCount', filename, filters, sorting, provider],
      queryFn: async () => {
        const params = {
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };
        const modifiedURL = provider ? `period/${provider.toLowerCase()}` : 'period';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/mapped/count`, { params });
        return response.data['total_count'];
      }
    });
    return {
      data,
      isLoading,
      isError,
      refetch
    }
  }

  const downloadMappedPeriodRecords = ({ filename, provider = null }: any) => {
    const { isLoading: isDownloading, mutateAsync: triggerDownload } = useMutation({
      mutationFn: async () => {
        const modifiedURL = provider ? `period/${provider.toLowerCase()}` : 'period';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/mapped/download`, {
          params: { Filename: filename },
          responseType: ResponseTypes.BLOB
        });
        const contentDisposition =await response?.headers['content-disposition'];
        const matchName = contentDisposition.replace('attachment; filename=', '');
        const filenames = matchName.split('.')[0] + '.xlsx';

        const urlLink = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = urlLink;
        link.setAttribute('download', filenames);

        document.body.appendChild(link);
        link.click();

        return response
      }
    });
    return {
      isDownloading,
      triggerDownload
    };
  }

  return {
    PeriodMappedRecords,
    downloadMappedPeriodRecords,
    PeriodMappedRecordsCount,
  }
}

export default PeriodMappedServices;
