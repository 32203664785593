import React from "react";
import { useMantineReactTable, MantineReactTable } from 'mantine-react-table'
import { useMantineTheme } from "@mantine/core";

const MantineTable = (props: any) => {

    const { colorScheme } = useMantineTheme()

    const table = useMantineReactTable({
        columns: props.columns,
        data: props.data ? props.data : [],
        initialState: {
            columnPinning: props.columnPinning,
            density: 'md',
        },
       
        enablePinning: props.enablePinning ? true : false,
        enableDensityToggle: props.enableDensityToggle ? true : false,
        enableFullScreenToggle: props.enableFullScreenToggle ? true : false,
        enableStickyHeader: props.enableStickyHeader ? true : false,
        enablePagination: props.enablePagination ? true : false,
        enableColumnActions: props.enableColumnActions ? true : false,
        enableGlobalFilter: props.enableGlobalFilter ? true : false,
        // paginationDisplayMode: props.paginationDisplayMode ? "pages" : undefined,
        // manualPagination: props.manualPagination ? true : false,
        // rowCount: props.rowCount ? props.rowCount : 0,
        manualFiltering: props.manualFiltering ? true : false,
        manualSorting: props.manualSorting ? true : false,
        enableRowVirtualization: props.enableRowVirtualization ? true : false,
        // mantinePaginationProps: {
        //     showRowsPerPage: props.showrowsperpage,
        //     rowsPerPageOptions: props.rowsPerPageOptions
        // },
        mantineToolbarAlertBannerProps: props.mantineToolbarAlertBannerProps,
        enableRowNumbers: props.enableRowNumbers,
        mantineTableProps: {
            highlightOnHover: false,
            withColumnBorders: true,
        },
        mantineTableContainerProps: {
            ref: props.tableContainerRef,
            sx: {
                maxHeight: '60vh'
            },
            onScroll: (event) => props.fetchMoreOnBottomReached(event.target),
        },
        displayColumnDefOptions: {
            'mrt-row-actions': {
                size: 100,
            },
        },
        onColumnFiltersChange: props.onColumnFiltersChange,
        // onPaginationChange: props.onPaginationChange,
        onSortingChange: props.onSortingChange,
        state: {
            columnFilters: props.columnFilters,
            isLoading: props.isLoading,
            // pagination: props.pagination,
            sorting: props.sorting,
            showProgressBars: props.isFetching,
            showSkeletons:props.isLoading,
            showLoadingOverlay: props.isFetching && props.isPreviousData
        },
        renderRowActions: ({ row, table }) => (
            props.customActions ? props.customActionButton({ row, table }) : <></>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            props.customActions ? props.customRecordsButton({ table }) : <></>
        ),
        createDisplayMode: 'row',
        editDisplayMode: 'row',
        enableRowActions: props.enableRowActions ? true : false,
        positionActionsColumn: 'first',
        enableEditing: props.enableEditing ? true : false,
        onEditingRowSave: props.onEditingRowSave,
        onCreatingRowSave: props.onCreatingRowSave,
        renderBottomToolbarCustomActions: props.renderBottomToolbarCustomActions,
        rowVirtualizerInstanceRef: props.rowVirtualizerInstanceRef,
        rowVirtualizerProps: { overscan: 10 },
    })


    return (
        <>
            <MantineReactTable
                table={table}
            />
        </>

    )

}

export default MantineTable