import axiosInstance from 'interceptor/axiosInstance';
import { CriticalAttribute } from 'models/CriticalAttribute/CriticalAttribute.model';
import { Pagination } from 'models/Pagination/pagination.model';
import { useState } from 'react';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { deserialize } from 'serializr';
import { useMutation, useQuery,useInfiniteQuery } from '@tanstack/react-query';
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'mantine-react-table';

interface Params {
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
}

interface ParamsObject {
  offset?: Number;
  limit?: Number;
  filters?: string;
  sorting?: string;
}

const CriticalAttributeService = () => {

  const fetchCriticalAttrRecords = ({  columnFilters, sorting }: Params) => {
    return useInfiniteQuery({
      queryKey: ['criticalAttributes', columnFilters, sorting],
      queryFn: async ({ pageParam = 0 }) => {
          const paramsObj: ParamsObject = {
              offset: pageParam * 30,
              limit: 30,
              filters: JSON.stringify(columnFilters ?? []),
              sorting: JSON.stringify(sorting ?? [])
          }
          const response = await axiosInstance.get(ApiRoutes.CRITICAL_ATTRIBUTE, { params: paramsObj })
          return response.data
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
  })
  };

 
  const createCriticalAttrRecord = () => {
    const { isLoading: createIsLoading, mutateAsync: createMutate } = useMutation({
      mutationFn: async (records: CriticalAttribute[]) => {
        const response = await axiosInstance.post(ApiRoutes.CRITICAL_ATTRIBUTE, { records });
        return response.data;
      }
    });
    return {
      createIsLoading,
      createMutate
    };
  };

  const updateCriticalAttrRecords = () => {
    const { isLoading: updateIsLoading, mutateAsync: updateMutate } = useMutation({
      mutationFn: async (records: CriticalAttribute[]) => {
        const response = await axiosInstance.put(ApiRoutes.CRITICAL_ATTRIBUTE, { records });
        return response.data;
      }
    });
    return {
      updateIsLoading,
      updateMutate
    };
  };

  const deleteCriticalAttrRecords = () => {
    const { isLoading: deleteIsLoading, mutateAsync: deleteMutate } = useMutation({
      mutationFn: async (ids: string[]) => {
        const response = await axiosInstance.delete(ApiRoutes.CRITICAL_ATTRIBUTE, {
          data: {
            ids
          }
        });
        return response.data;
      }
    });

    return {
      deleteIsLoading,
      deleteMutate
    };
  };

  return {
    fetchCriticalAttrRecords,
    createCriticalAttrRecord,
    updateCriticalAttrRecords,
    deleteCriticalAttrRecords
  };
};
export default CriticalAttributeService;
