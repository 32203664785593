import { ActionIcon, Text, Flex, Skeleton, Tooltip, Button } from '@mantine/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconDownload, IconRefresh } from '@tabler/icons-react';
import { useParams, useSearchParams } from 'react-router-dom';
import MantineTable from 'views/SmartMapping/Shared/MantineTable';
import { DIMENSION_META } from '../constants/dimension';
import {
  MRT_ColumnFiltersState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleFiltersButton,
  MRT_Virtualizer
} from 'mantine-react-table';
import MantineSidebar from 'views/SmartMapping/Shared/Drawer';

const DetailTable = () => {
  const { dimension, status } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  if (!dimension || !status || !searchParams.get('Provider')) return <></>;

  const provider: any = searchParams.get('Provider');
  const { fetchData, downloadMethod, fetchCount } = DIMENSION_META[dimension][status];

  let columnHeader = '';

  if (provider === 'nielsen' || provider == 'pos') {
    const { column } = DIMENSION_META[dimension][status][provider];
    columnHeader = column;
  } else {
    const { column } = DIMENSION_META[dimension][status]['rms'];
    columnHeader = column;
  }

  const serviceParameters = {
    filename: searchParams.get('Filename'),
    filters: columnFilters,
    sorting: sorting,
    status: status,
    provider: provider === 'nielsen' ? null : provider === 'pos' ? 'pos' : 'rms'
  };

  const { data, isLoading, error, refetch, fetchNextPage, isPreviousData, isFetching } = fetchData(serviceParameters);
  const { data: count, isLoading: paginationLoading, refetch: paginationRefetch } = fetchCount(serviceParameters);
  const { triggerDownload, isDownloading } = downloadMethod(serviceParameters);



  const tableData = useMemo(() => {
    return data?.pages?.flatMap((page: any) => page);
  }, [data]);

  const handleRefetch = (close: any) => {
    close.close()
    refetch();
    paginationRefetch();
  };

  const totalDBRowCount: number | undefined = count;
  const totalFetched: number | undefined = tableData?.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (totalFetched && totalDBRowCount) {
          if (scrollHeight - scrollTop - clientHeight < 400 && !isFetching && totalFetched < totalDBRowCount) {
            fetchNextPage();
          }
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, columnFilters, globalFilter]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [tableData]);



  return (
    <MantineTable
      data={tableData ? tableData : []}
      columns={columnHeader ? columnHeader : []}
      enablePinning={true}
      positionGlobalFilter='left'
      manualFiltering={true}
      staticHeader={true}
      manualSorting={true}
      isPreviousData={isPreviousData}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      sorting={sorting}
      onSortingChange={setSorting}
      onGlobalFilterChange={setGlobalFilter}
      enableColumnOrdering={false}
      enableGlobalFilterModes={true}
      enableGlobalFilter={true}
      enableColumnFilters={true}
      positionActionsColumn={'last'}
      showRemappingColumn={
        status === 'unprocessed' ? (dimension === 'product' && provider.toLowerCase() === 'nielsen' ? true : false) : true
      }
      mantineSearchTextInputProps={{
        placeholder: 'Search by ...',
        sx: { minWidth: '300px' },
        variant: 'filled'
      }}
      customActions={true}
      customActionButton={({ row, table }: any) => (
        <Flex>
          {status !== 'unprocessed' || (status === 'unprocessed' && dimension === 'product' && provider.toLowerCase() === 'nielsen') ? (
            <MantineSidebar cell={row.original} handleRefetch={(val: any) => handleRefetch(val)} />
          ) : (
            <></>
          )}
        </Flex>
      )}
      enableRowActions={true}
      isLoading={isLoading || isFetching || paginationLoading}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableColumnActions={false}
      enablePagination={false}
      // initialState={{ columnPinning: { right: ['sidebar'] }, showGlobalFilter: true }}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: 'Remapping'
        }
      }}
      showGlobalFilter={true}
      columnPinning={{ right: ['mrt-row-actions'] }}
      renderBottomToolbarCustomActions={() => {
        return (
          <div className='bottom-Action'>
            {paginationLoading ? <Skeleton height={30} width='15rem' /> : `Total Records : ${totalDBRowCount ? totalDBRowCount : 0}`}
            <Text className='bottom-text'>
              {paginationLoading ? <Skeleton height={30} width='20rem' /> : `Showing ${totalFetched ? totalFetched : 0}  of ${totalDBRowCount ? totalDBRowCount : 0} total rows.`}
            </Text>
          </div>
        );
      }}
      renderToolbarInternalActions={({ table }: any) => (
        <Flex gap='xs' align='center'>
          <Tooltip label='Download'>
            <ActionIcon
              onClick={() => {
                triggerDownload();
              }}
              loading={isDownloading}
            >
              <IconDownload />
            </ActionIcon>
          </Tooltip>
          <Tooltip label='Refresh'>
            <ActionIcon
              onClick={() => {
                refetch();
                paginationRefetch();
              }}
            >
              <IconRefresh />
            </ActionIcon>
          </Tooltip>
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
        </Flex>
      )}
      fetchMoreOnBottomReached={(val: any) => fetchMoreOnBottomReached(val)}
      rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
      tableContainerRef={tableContainerRef}
    />
  );
};

export default DetailTable;
