import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import Arrow from 'assets/icons/CardArrowVector.svg';
import GreenDot from 'assets/icons/green-dot.svg';
import RedDot from 'assets/icons/red-dot.svg'
import './TasksDueHome.scss';
import CustomButton from '../Button-component/Button';
const tasksDue = [
  {
    title:'Correction in filename',
    text:'lorem ipsum dolor sit amet, consectur',
    status:'Passed',

  },
  {
    title:'Correction in filename',
    text:'lorem ipsum dolor sit amet, consectur',
    status:'Approaching'

  },
  {
    title:'Correction in filename',
    text:'lorem ipsum dolor sit amet, consectur',
    status:'Approaching'
  },
  {
    title:'Correction in filename',
    text:'lorem ipsum dolor sit amet, consectur',
    status:'Passed'
  },
  {
    title:'Correction in filename',
    text:'lorem ipsum dolor sit amet, consectur',
    status:'Passed'
  },
  {
    title:'Correction in filename',
    text:'lorem ipsum dolor sit amet, consectur',
    status:'Passed'
  },
  {
    title:'Correction in filename',
    text:'lorem ipsum dolor sit amet, consectur',
    status:'Passed'
  },
  

  

]
const TasksDueHome = () => {
  return (
    <Container fluid className='tasks-due-container pr-0'>
      {/* Header */}
      <Row className='task-due-header'>
        <Row className='tasks-header'>
        <div className='card-title-container'>
        <h3>Tasks Due Date</h3>
        <img src={Arrow} alt='arrow-icon' />
        </div>
        </Row>
        {/* Buttons */}
        <Row >
          <div className='tasks-btn-row'>
          {/* <div className='status-btn'>
            All (22)
          </div> */}
          <CustomButton statusActive>All(22)</CustomButton>
          <CustomButton status><div className='btn-content'><div>Approaching(07)</div><div><img src={GreenDot} alt='green-dot' /></div></div></CustomButton>
          <CustomButton status><div className='btn-content'><div>Passed(22)</div><div><img src={RedDot} alt='red-dot' /></div></div></CustomButton>
          </div>
        </Row>
      </Row>
      
      {/* List view */}
      <Row>
        <Col sm={12} className='list-container'>
        {tasksDue.map((item, index)=>{
          return (
            <div className='list-item'>
              <div className='first-row'>
              <h3>{item.title}</h3>
              {item.status === 'Approaching' && <p className='success-tag'>{item.status}</p> }
              {item.status === 'Passed' && <p className='failed-tag'>{item.status}</p>}
              </div>
              <div className='second-row'>
              <p>{item.text}</p>
              </div>
            </div>
          )
        })}
        </Col>
      </Row>
    </Container>
  )
}

export default TasksDueHome