
export const factMetadataColumn = [

    { header: 'ID', accessorKey: 'Id', maxSize: 60, enableResizing: true, enableColumnDragging: true, enableEditing: false },

    { header: 'Unique Tag', accessorKey: 'UniqueTag', maxSize: 150, enableResizing: true, enableColumnDragging: true },

    { header: 'Tag Long', accessorKey: 'TagLong', maxSize: 60, enableResizing: true, enableColumnDragging: true },

    { header: 'Tag Internal', accessorKey: 'TAG_Internal', maxSize: 120, enableResizing: true, enableColumnDragging: true },

    { header: 'Short Internal', accessorKey: 'SHORT_Internal', maxSize: 120, enableResizing: true, enableColumnDragging: true },

    { header: 'Long Internal', accessorKey: 'LONG_Internal', maxSize: 220, enableResizing: true, enableColumnDragging: true },

    { header: 'Cell', accessorKey: 'Cell', maxSize: 80, enableResizing: true, enableColumnDragging: true },

    { header: 'CountryName', accessorKey: 'CountryName', maxSize: 70, enableResizing: true, enableColumnDragging: true },

    { header: 'Nielsen MarketName', accessorKey: 'NielsenMarketName', maxSize: 80, enableResizing: true, enableColumnDragging: true },

    { header: 'FactType Internal', accessorKey: 'Fact_Type_Internal', maxSize: 100, enableResizing: true, enableColumnDragging: true },

    { header: 'Google Trans', accessorKey: 'GOOGLETRANS', maxSize: 150, enableResizing: true, enableColumnDragging: true },

    { header: 'Harmonised Attribute', accessorKey: 'HarmonisedAttribute', maxSize: 80, enableResizing: true, enableColumnDragging: true },

    { header: 'Global Harmonised Attribute', accessorKey: 'GlobalHarmonisedAttribute', maxSize: 130, enableResizing: true, enableColumnDragging: true },

];


