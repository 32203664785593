import { MRT_ColumnDef } from 'mantine-react-table';
import { Mappings } from 'models/Mappings/mappings.model';
import React from 'react';
import MarkedIcon from './../../../../assets/icons/marked.svg';
import UnMarkedIcon from './../../../../assets/icons/unmarked.svg';
import ReactSelect from 'shared/components/ReactSelect';
import Info from 'assets/icons/info-icon.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const getfileVolatilityMappingColumns = (
  uniqueArray: { name: string; value: string }[],
  handleSelect: (index: number) => (e: string) => void,
  handleCrtiticalAttrChange: (index: number, value: string) => () => void,
  errors: number,
  totalColumns: number,
  source?: string
): MRT_ColumnDef<Mappings>[] => [
  {
    header: 'Mark as critical',
    Header: (
      <div className='mark-as-critical-header-col'>
        <img className='mark-as-critical-header-col__icon' src={MarkedIcon} alt='critical-icon' />
        <span className='mark-as-critical-header-col__text'>
          Mark as
          <br /> Critical
        </span>
      </div>
    ),
    accessorKey: 'criticality',
    size: 70,
    Cell: (props) => (
      <button className='critical-icon' onClick={handleCrtiticalAttrChange(props.row.index, String(props.cell.getValue()))}>
        {props.cell.getValue() === '1' ? (
          <img className='mark-as-critical-col-icon' src={MarkedIcon} alt='marked as critical' />
        ) : (
          <img className='mark-as-critical-col-icon' src={UnMarkedIcon} alt='marked as not critical' />
        )}
      </button>
    )
  },
  {
    header: 'Previous',
    accessorKey: 'sourcePrevious',
    Header: (
      <table className='previous-col'>
        <thead>
          <tr>
            <div className='previous-col__header'>Previous</div>
          </tr>
          <tr className='previous-col__bottom'>
            <th>
              <div className='previous-col__bottom__cell'>Source: {source}</div>
            </th>
            <th>
              <div className='previous-col__bottom__cell'>Columns: {totalColumns}</div>
            </th>
            <th>
              <div className='previous-col__bottom__errors'>
                <img className='previous-col__bottom__errors__icon' src={Info} alt='error-icon' width={12} height={12} />
                <span>Errors: {errors}</span>
              </div>
            </th>
          </tr>
        </thead>
      </table>
    ),
    mantineTableHeadCellProps: {
      className: 'previous'
    },
    Cell: ({ row }) => (
      <div className='previous-col-data-cell'>
        <p className='previous-col-data-cell__value'>{row?.original?.sourcePrevious}</p>
        {row?.original?.isMissingAttribute && row?.original?.sourcePrevious !== '-' ? (
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip>
                {row?.original?.criticality === '1'
                  ? `"${row?.original?.sourcePrevious}" is missing which is a critical attribute`
                  : `"${row?.original?.sourcePrevious}" is missing`}
              </Tooltip>
            }
          >
            <img src={Info} alt='missing-attribute-error' className='previous-col-data-cell__icon' />
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </div>
    )
  },
  {
    header: 'Current',
    accessorKey: 'source',
    Header: (
      <table className='current-col'>
        <thead>
          <tr>
            <div className='current-col__header'>Current</div>
          </tr>
          <tr className='current-col__bottom'>
            <th>
              <div className='current-col__bottom__cell'>Source: {source}</div>
            </th>
            <th>
              <div className='current-col__bottom__cell'>Columns: {totalColumns}</div>
            </th>
            <th>
              <div className='current-col__bottom__errors'>
                <img className='current-col__bottom__errors__icon' src={Info} alt='error-icon' width={12} height={12} />
                <span>Errors: {errors}</span>
              </div>
            </th>
          </tr>
        </thead>
      </table>
    ),
    mantineTableHeadCellProps: {
      className: 'current'
    },

    Cell: (props) => {
      return (
        <ReactSelect
          options={uniqueArray}
          onValueChange={handleSelect(props.row.index)}
          value={{ label: props.cell.getValue(), value: props.cell.getValue() }}
          hideLabel
        />
      );
    }
  },
  {
    header: 'Internal Database',
    accessorKey: 'target',
    mantineTableHeadCellProps: {
      className: 'target-unilever-database'
    },
    minSize: 200
  }
];
