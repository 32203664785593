import React, { FC } from 'react'
import "./loader.scss"

interface LoaderProps { }

const Loader: FC<LoaderProps> = (props) => {

    const { } = props

    return (
        <div className="loader-container">
            <div className="loader-wrapper">
                <div className="loader"></div>
            </div>
        </div>
    )
}

export default Loader