import React, { useState, forwardRef, ChangeEvent } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './NewEDDropdown.scss';
import SearchInputField from '../Search-input-field/search-input-field';
import DropdownIcon from 'assets/icons/dropdown-icon.svg';
import DropdownCross from 'assets/icons/dropdown-cross.svg';
import SkeletonLoader from '../SkeletonLoader';

const CustomToggle = forwardRef<
  HTMLAnchorElement,
  { resetIcon?: boolean; children: React.ReactNode; onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void }
>(({ children, onClick, resetIcon }, ref) => (
  /*eslint-disable */
  <>
    <a
      href=''
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className='toggle-button'
    >
      {children}
      <div className='icon-container'>
        <p>{resetIcon}</p>
        <div className='dd-separator' />
        <img src={DropdownIcon} alt='dropdown icon' className='dd-down' />
      </div>
    </a>
  </>
  /*eslint-enable */
));

const CustomMenu = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; style: React.CSSProperties; className: string; 'aria-labelledby': string }
>(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value?.toLowerCase());

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <SearchInputField onChange={handleSearchChange} placeholder='Search' width='354px' height='48px' />
      <ul className='list-unstyled'>
        {React.Children.toArray(children).filter(
          (child) => !value || (child as React.ReactElement<{ children: string }>).props.children?.toLowerCase().includes(value)
        )}
      </ul>
    </div>
  );
});

interface NewEDDropdown {
  optionItems: any[];
  value?: string;
  className?: string;
  currentItemIndex: number;
  labelText: string;
  updateValue: (name: string) => void;
  defaultValue: string;
  resetIcon?: boolean;
  resetFunc: () => void;
  loading?: boolean;
}

const NewEDDropdown = (props: NewEDDropdown) => {
  const { optionItems, updateValue, labelText, resetFunc, loading } = props;

  const [dropdownValue, setDropdownValue] = useState<string>();

  const internalResetFunction = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    e.preventDefault();
    resetFunc();
  };

  return loading ? (
    <SkeletonLoader />
  ) : (
    <Dropdown className='search-dropdown-container'>
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' className='toggle-container' resetIcon={props.resetIcon}>
        <div className='label-container'>
          <p>{labelText} : </p>
        </div>
        <div className='value-container'>
          <h2>{props.value ? props.value : 'Select'}</h2>
        </div>
        <div className='separator' />
        {props.value ? (
          <div className='dd-cross-container' onKeyDown={internalResetFunction} role='button' tabIndex={0} onClick={internalResetFunction}>
            <img src={DropdownCross} alt='dropdown icon' className='dd-cross' />{' '}
          </div>
        ) : null}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} className='toggle-menu'>
        {optionItems?.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              onClick={() => {
                setDropdownValue(item.value);
                updateValue(item.value);
              }}
              eventKey={item.id}
            >
              {item.value}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NewEDDropdown;
