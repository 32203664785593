export const RMSPeriodMappedRemapping = [
  {
    key: 'tag',
    attributeName: 'TAG',
    external: 'Tag',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'short',
    attributeName: 'Short Description',
    external: 'Short',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'long',
    attributeName: 'Long Description',
    external: 'Long',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'last-period',
    attributeName: 'Last Period',
    external: 'LastPeriodTagFormat',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'unique-tag',
    attributeName: 'Unique Tag',
    external: 'UniqueTag',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'periodicity',
    attributeName: 'Periodicity',
    external: null,
    internal: 'Periodicity',
    apiEndPoint: 'periodicity',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'period-start-date',
    attributeName: 'Period Start Date',
    external: null,
    internal: 'PeriodStartDate',
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'period-end-date',
    attributeName: 'Period End Date',
    external: null,
    internal: 'PeriodEndDate',
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'year',
    attributeName: 'Year',
    external: null,
    internal: 'YearBr',
    apiEndPoint: 'year',
    dependsOn: ['Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    apiDependsOn: ['Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'quarter',
    attributeName: 'Quarter',
    external: null,
    internal: 'QuarterBr',
    apiEndPoint: 'quarter',
    dependsOn: ['YearBr', 'Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    apiDependsOn: ['YearBr', 'Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'month',
    attributeName: 'Month',
    external: null,
    internal: 'MonthBr',
    apiEndPoint: 'month',
    dependsOn: ['QuarterBr', 'YearBr', 'Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    apiDependsOn: ['QuarterBr', 'YearBr', 'Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'week',
    attributeName: 'Week',
    external: null,
    internal: 'WeekBr',
    apiEndPoint: 'week',
    dependsOn: ['MonthBr', 'QuarterBr', 'YearBr', 'Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    apiDependsOn: ['MonthBr', 'QuarterBr', 'YearBr', 'Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'min-period-number',
    attributeName: 'Min Period Number',
    external: null,
    internal: 'MinPeriodNumBr',
    apiEndPoint: 'min-period-number',
    dependsOn: ['PeriodEndDate', 'PeriodStartDate', 'WeekBr', 'MonthBr', 'QuarterBr', 'YearBr', 'Periodicity'],
    apiDependsOn: ['WeekBr', 'MonthBr', 'QuarterBr', 'YearBr', 'Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'max-period-number',
    attributeName: 'Max Period Number',
    external: null,
    internal: 'MaxPeriodNumBr',
    apiEndPoint: 'max-period-number',
    dependsOn: ['MinPeriodNumBr', 'PeriodEndDate', 'PeriodStartDate', 'WeekBr', 'MonthBr', 'QuarterBr', 'YearBr', 'Periodicity'],
    apiDependsOn: ['MinPeriodNumBr', 'WeekBr', 'MonthBr', 'QuarterBr', 'YearBr', 'Periodicity', 'PeriodEndDate', 'PeriodStartDate'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'country-week-start-day',
    attributeName: 'Week Start Day Country',
    external: null,
    internal: 'WeekStartDayCountry',
    apiEndPoint: 'country-week-start-day',
    dependsOn: [
      'MaxPeriodNumBr',
      'MinPeriodNumBr',
      'PeriodEndDate',
      'PeriodStartDate',
      'WeekBr',
      'MonthBr',
      'QuarterBr',
      'YearBr',
      'Periodicity'
    ],
    apiDependsOn: [
      'MaxPeriodNumBr',
      'MinPeriodNumBr',
      'WeekBr',
      'MonthBr',
      'QuarterBr',
      'YearBr',
      'Periodicity',
      'PeriodEndDate',
      'PeriodStartDate'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'periodicity-identifer',
    attributeName: 'Periodicity Identifier',
    external: null,
    internal: 'PeriodicityIdentifer',
    apiEndPoint: 'periodicity-identifer',
    dependsOn: [
      'WeekStartDayCountry',
      'MaxPeriodNumBr',
      'MinPeriodNumBr',
      'PeriodEndDate',
      'PeriodStartDate',
      'WeekBr',
      'MonthBr',
      'QuarterBr',
      'YearBr',
      'Periodicity'
    ],
    apiDependsOn: [
      'WeekStartDayCountry',
      'MaxPeriodNumBr',
      'MinPeriodNumBr',
      'WeekBr',
      'MonthBr',
      'QuarterBr',
      'YearBr',
      'Periodicity',
      'PeriodEndDate',
      'PeriodStartDate'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'convention',
    attributeName: 'Convention',
    external: null,
    internal: 'Convention',
    apiEndPoint: 'convention',
    dependsOn: [
      'PeriodicityIdentifer',
      'WeekStartDayCountry',
      'MaxPeriodNumBr',
      'MinPeriodNumBr',
      'PeriodEndDate',
      'PeriodStartDate',
      'WeekBr',
      'MonthBr',
      'QuarterBr',
      'YearBr',
      'Periodicity'
    ],
    apiDependsOn: [
      'PeriodicityIdentifer',
      'WeekStartDayCountry',
      'MaxPeriodNumBr',
      'MinPeriodNumBr',
      'WeekBr',
      'MonthBr',
      'QuarterBr',
      'YearBr',
      'Periodicity',
      'PeriodEndDate',
      'PeriodStartDate'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'period-number',
    attributeName: 'Period Number',
    external: null,
    internal: 'PeriodNumberBr',
    apiEndPoint: 'period-number',
    dependsOn: [
      'Convention',
      'PeriodicityIdentifer',
      'WeekStartDayCountry',
      'MaxPeriodNumBr',
      'MinPeriodNumBr',
      'PeriodEndDate',
      'PeriodStartDate',
      'WeekBr',
      'MonthBr',
      'QuarterBr',
      'YearBr',
      'Periodicity'
    ],
    apiDependsOn: [
      'Convention',
      'PeriodicityIdentifer',
      'WeekStartDayCountry',
      'MaxPeriodNumBr',
      'MinPeriodNumBr',
      'WeekBr',
      'MonthBr',
      'QuarterBr',
      'YearBr',
      'Periodicity',
      'PeriodEndDate',
      'PeriodStartDate'
    ],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  }
];
