import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import Arrow from 'assets/icons/CardArrowVector.svg';
import { Card, Stack } from 'react-bootstrap';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';
import './harmonizedPieChart.scss';
import PinIcon from './../../../../assets/icons/pin.svg';
const COLORS = ['#FF544F', '#00B190', '#B4B4B4'];

interface HarmonizedPieChartProps {
  providerName: string;
  fail: number;
  success: number;
  process: number;
}

const HarmonizedPieChart: FC<HarmonizedPieChartProps> = (props) => {
  const { providerName, fail, success, process } = props;
  const totalValue = fail + success + process;

  const data = [
    { name: 'Failed', value: fail },
    { name: 'Successful', value: success },
    { name: 'In Process', value: process }
  ];

  const COLORS = ['#FF544F', '#00B190', '#B4B4B4'];

  return (
    <div className='harmonized-pie-chart'>
      <Row>
        <Col>
          <Card className='card-template'>
            <Card.Header className='card-template-header cursor-pointer'>
              <p className='card-template-heading'>
                <img src={PinIcon} alt='pin-icon' className='pin-icon' />
                {providerName}
              </p>
              <img alt='search icon' className='card-template-header-left-icon' src={Arrow} />
            </Card.Header>
            <Card.Body className='card-template-body'>
              <Stack direction='horizontal' className='card-template-stack'>
                <Card.Title className='card-template-title'>
                  <Stack direction='vertical'>
                    <div className='total-container'>
                      <div>
                        <h2>Total Records Received</h2>
                      </div>
                      <div>
                        <p>{totalValue}</p>
                      </div>
                    </div>
                  </Stack>
                </Card.Title>
                <div className='chart-container'>
                  <ResponsiveContainer width='100%' height='100%'>
                    <PieChart>
                      <Pie data={data} cx='50%' cy='50%' labelLine={false} outerRadius={50} innerRadius={40} fill='#8884d8' dataKey='value'>
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                        <Label
                          width={30}
                          position='center'
                          content={
                            <text
                              x='50%'
                              y='50%'
                              fill='#3d405c'
                              className='recharts-text recharts-label'
                              textAnchor='middle'
                              dominantBaseline='central'
                            >
                              <tspan alignmentBaseline='middle' fontSize='20'>
                                {'82%'}
                              </tspan>
                              {/* <tspan fontSize='14'>{'Avg. success rate'}</tspan> */}
                            </text>
                          }
                        ></Label>
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </Stack>
            </Card.Body>
            <Card.Footer className='card-template-footer'>
              <Row>
                <Col xs={4} className='footer-item'>
                  <p>Failed</p>
                  <p className='fail-status'>{fail}</p>
                </Col>
                <Col xs={4} className='footer-item'>
                  <p>Successful</p>
                  <p className='success-status'>{success}</p>
                </Col>
                <Col className='footer-item'>
                  <p>In Process</p>
                  <p className='in-process-status'>{process}</p>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HarmonizedPieChart;
