import HomeIcon from 'assets/icons/home.svg';
import ReportsIcon from 'assets/icons/reports.svg';
import ChecksIcon from 'assets/icons/checks.svg';
import DashboardIcon from 'assets/icons/dashboard.svg';
import CellControlIcon from 'assets/icons/cell-control.svg';
import GlobalRMSCellMetadataIcon from 'assets/icons/global-rms-cell-metadata.svg';
import GlobalRMSSMLMappingIcon from 'assets/icons/global-rms-sml-mapping.svg';
import MetadataIcon from 'assets/icons/metadata.svg';
import useRedirect from 'shared/hooks/useRedirect';

export interface SidebarMenuItem {
  name: string;
  icon: string;
  urlPrefix?: string;
  url?: string;
  submenu?: {
    name: string;
    onClick: () => void;
  }[];
}

const useSidebarConfig = () => {
  const { redirectToDQ, redirectToAR } = useRedirect();

  const defaultMenuItems: SidebarMenuItem[] = [
    {
      name: 'Summary',
      icon: HomeIcon,
      url: 'home'
    },
    // {
    //   name: 'Leadership Home',
    //   icon: HomeIcon,
    //   url: 'leadership'
    // },
    {
      name: 'Reports',
      icon: ReportsIcon,
      url: 'reports',
      submenu: [
        {
          name: 'Data Quality',
          onClick: redirectToDQ
        },
        {
          name: 'Acceptance Report',
          onClick: redirectToAR
        }
      ]
    },
    {
      name: 'Reviews',
      icon: ChecksIcon,
      url: 'checks'
    }
  ];

  const adminMenuItems: SidebarMenuItem[] = [
    {
      name: 'Dashboard',
      icon: DashboardIcon,
      url: 'home'
    },
    {
      name: 'Cell Control',
      icon: CellControlIcon,
      urlPrefix: 'admin',
      url: 'cell-control'
    },
    {
      name: 'Global RMS Cell Metadata',
      icon: GlobalRMSCellMetadataIcon,
      urlPrefix: 'admin',
      url: 'global-rms-metadata-mapping'
    },
    {
      name: 'Global RMS SML Mapping',
      icon: GlobalRMSSMLMappingIcon,
      urlPrefix: 'admin',
      url: 'global-rms-sml-mapping'
    },
    {
      name: 'Metadata',
      icon: MetadataIcon
    }
  ];
  return {
    defaultMenuItems,
    adminMenuItems
  };
};

export default useSidebarConfig;
