import React, { useEffect } from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import Cards from 'shared/components/Card-component/Cards';
import ValidationService from 'services/ValidationService/validation.service';
import './ValidationHome.scss';
import Loader from 'shared/components/Loader';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import { Col, Row } from 'react-bootstrap';
import VolatilityService from 'services/VolatilityService/volatility.service';
import FileVolatility from 'assets/icons/file-volatility-card1.svg';
import SMData from 'services/SmartMapping/SMListService/SmartMapping.service';

const ValidationHome: React.FC = () => {
  const { fetchValidation } = SMData()
  const { data: dashboardData, isLoading:isValidationLoading  } = fetchValidation()

  const { fetchVolatilityDashboard, loading: volatilityLoading, volatilityDashboardDetails } = VolatilityService();

  const navigate = useNavigate();

  useEffect(() => {
    fetchVolatilityDashboard();
  }, []);

  const redirectToValidationPage = () => {
    navigate("/checks/intelligent-mapping/product");
  };

  const redirectToVolatilityPage = () => {
    navigate(AppRoutes.FILE_VOLATALITY_RECORDS);
  };
  

  return (
    <DashboardContainer>
      <div className='reports-page-content'>
        <Row className='cards-container'>
          <Col xs={11}>
            <h1 className='page-title'>Checks</h1>
          </Col>
        </Row>

        <Row className='cards-container'>
          <Col xs={4}>
            <Cards
              heading='File Volatility'
              value={volatilityDashboardDetails?.totalCount ?? 5}
              footer1Heading='Excel'
              footer1Value={volatilityDashboardDetails?.excelCount ?? 0}
              footer2Heading='Csv'
              footer2Value={volatilityDashboardDetails?.csvCount ?? 5}
              footer3Heading='Word'
              footer3Value={volatilityDashboardDetails?.docCount ?? 0}
              image={FileVolatility}
              cardHeaderClickHandler={redirectToVolatilityPage}
              loading={volatilityLoading}
            />
          </Col>
          <Col xs={4}>
            <Cards
              heading='Intelligent Mapping'
              value={dashboardData?.data.total_count ?? 0}
              footer1Heading='Excel'
              footer1Value={dashboardData?.data.excel_count ?? 0}
              footer2Heading='Csv'
              footer2Value={dashboardData?.data.csv_count ?? 0}
              footer3Heading='Word'
              footer3Value={dashboardData?.data.doc_count ?? 0}
              cardHeaderClickHandler={redirectToValidationPage}
              loading={isValidationLoading}
            />
          </Col>
          <Col xs={4}>
            <Cards
              heading='Audit'
              value={62}
              footer1Heading='Recent'
              footer1Value={2}
              footer2Heading='Old'
              footer2Value={60}
            // cardHeaderClickHandler={redirectToWIP}
            />
          </Col>
          <Col xs={4}>
            <Cards
              heading='Source Agnostic Ingestion'
              value={29}
              footer1Heading='Files'
              footer1Value={10}
              footer2Heading='Videos'
              footer2Value={19}
            // cardHeaderClickHandler={redirectToWIP}
            />
          </Col>
          <Col xs={4}>
            <Cards
              heading='Data Purging'
              value={29}
              footer1Heading='Files'
              footer1Value={10}
              footer2Heading='Videos'
              footer2Value={19}
            // cardHeaderClickHandler={redirectToWIP}
            />
          </Col>
        </Row>
      </div>
    </DashboardContainer>
  );
};

export default ValidationHome;
