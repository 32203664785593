import axiosInstance from 'interceptor/axiosInstance';
import { Dispatch, SetStateAction, useState } from 'react';
import { deserialize, ClazzOrModelSchema } from 'serializr';
import { MetaParams, MetaTypes } from 'shared/types/meta.type';

const MetaService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMeta = async (
    url: string,
    setState: Dispatch<SetStateAction<MetaTypes[]>>,
    model: ClazzOrModelSchema<MetaTypes>,
    params?: MetaParams
  ) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(url, { params });
      const data = deserialize(model, response.data) as MetaTypes[];
      setState(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    fetchMeta
  };
};

export default MetaService;
