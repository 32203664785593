import React, { useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import './datePicker.scss'

function DatePicker(props:any) {
    const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);

   
    
    return (

        <DatePickerInput
            icon={'Date : '}
            type="range"
            valueFormat="DD MMM YY"
            value={value}
            onChange={(val)=>{
                setValue(val)
                props.onChange(val)
            }}
            clearable={true}
            className='date-picker'
            size='md'
        />
    );
}

export default DatePicker
