import React, { useEffect } from 'react';
import { Container, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import './Nav-component.scss';
import Network from 'assets/icons/network.svg';
import NotificationBell from 'assets/icons/notification-bell.svg';
import ProfilePlaceholder from 'assets/icons/profilePicPlaceholder.svg';
import SearchInputField from '../Search-input-field/search-input-field';
import Dropdown from 'shared/components/dropdown-component';
import CustomButton from '../Button-component/Button';
import ProfileService from 'services/ProfileService/profile.service';
import DownArrow from 'assets/icons/down-arrow.svg';
import useNavConfig from './useNavConfig';
const NavComponent: React.FC = () => {
  const { getUserPhoto, userPhoto } = ProfileService();

  const { profileOptions } = useNavConfig();

  useEffect(() => {
    getUserPhoto();
  }, []);

  const profileDropdown = (
    <div className='profile-pic-wrapper'>
      <img className='profile-pic' src={userPhoto || ProfilePlaceholder} alt='profile' />
      <img src={DownArrow} alt='down-arrow' className='profile-down-arrow' />
    </div>
  );

  return (
    <Navbar expand='lg'>
      <Container fluid className='navbar-template'>
        <Row className='w-100'>
          <Col className='navbar-search'>
            <div className='search'>
              <SearchInputField
                placeholder='Type here to search file, report...'
                height='40px'
                width='120px'
                paddingLeft='40px'
                paddingRight='50px'
              />
            </div>
          </Col>
          <Col lg={4} xl={5} xxl={6} className='container-center'>
            {/* Comment for future use */}
            {/* <div className='zoom-controls-container'>
            <div>
            <CustomButton height="37px" buttonNav>A-</CustomButton>
            </div>
            <div>
            <CustomButton height="37px" buttonNav>A</CustomButton>
            </div>
            <div>
            <CustomButton height="37px" buttonNav>A+</CustomButton>
            </div>

            </div>
            <div className='nav-dropdown-container'>
            <Dropdown  height='36px' options={languageDropdownOptions} />
            </div>
            <div className='screen-reader-access'>
              <div className='separator'></div>
              <p>Screen Reader Access</p>
              <div className='separator'></div>
            </div> */}
          </Col>
          <Col className='container-right'>
            <div className='icon-container'>
              <img src={Network} alt='networking' />
              <img src={NotificationBell} alt='notifications' />
              <div className="profile-dropdown">
              <div className='profile-pic-wrapper'>
                <img className='profile-pic' src={userPhoto || ProfilePlaceholder} alt='profile' />
                <img src={DownArrow} alt='down-arrow' className='profile-down-arrow' />
              </div>
              </div>

              {/* <NavDropdown title={profileDropdown} className='profile-dropdown'>
                {profileOptions.map(({ name, icon, onClick }) => (
                  <NavDropdown.Item
                    key={name}
                    onClick={onClick}
                    className='profile-dropdown__menu'
                  >
                    <img src={icon} alt={name} width={16} height={16} />
                    {name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown> */}
            </div>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default NavComponent;
