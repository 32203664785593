import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'interceptor/axiosInstance';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
const DropDownMeta = () => {
  const CountryDropDown = (params: any) => {
    const { data, isLoading, isError } = useQuery({
      queryKey: ['country', params],
      queryFn: async () => {
        const response = await axiosInstance.get(ApiRoutes.COUNTRY_META, { params });
        return response;
      }
    });

    return {
      data,
      isLoading,
      isError
    };
  };

  const CategoryDropDown = (params: any) => {
    const { data, isLoading, isError } = useQuery({
      queryKey: ['category', params],
      queryFn: async () => {
        const response = await axiosInstance.get(ApiRoutes.CATEGORY_META, { params });
        return response;
      }
    });

    return {
      data,
      isLoading,
      isError
    };
  };

  const DataProviderDropDown = (params: any) => {
    const { data, isLoading, isError } = useQuery({
      queryKey: ['dataProvider', params],
      queryFn: async () => {
        const response = await axiosInstance.get(ApiRoutes.PROVIDER_META, { params });
        return response;
      }
    });

    return {
      data,
      isLoading,
      isError
    };
  };

  return {
    CountryDropDown,
    CategoryDropDown,
    DataProviderDropDown
  };
};

export default DropDownMeta;
