import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query'
import axiosInstance from "interceptor/axiosInstance"
import { ApiRoutes } from 'routes/routeConstants/apiRoutes'
import { deserialize } from 'serializr';
import { SmlPcat } from 'models/SmlPcat/smlPcat.model';
import {
    type MRT_ColumnFiltersState,
    type MRT_SortingState,
} from 'mantine-react-table';


interface Params {
    columnFilters: MRT_ColumnFiltersState;
    sorting: MRT_SortingState;
}

interface ParamsObject {
    offset?: Number,
    limit?: Number,
    filters?: string,
    sorting?: string

}

const SmlPcatService = () => {

    const fetchSmalPcatRecords = ({ columnFilters, sorting }: Params) => {

        return useInfiniteQuery({
            queryKey: ['smlpcat', columnFilters, sorting],
            queryFn: async ({ pageParam = 0 }) => {
                const paramsObj: ParamsObject = {
                    offset: pageParam * 30,
                    limit: 30,
                    filters: JSON.stringify(columnFilters ?? []),
                    sorting: JSON.stringify(sorting ?? [])
                }
                const response = await axiosInstance.get(ApiRoutes.SML_PCAT_POC, { params: paramsObj })
                return response.data
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        })
    }


    const createSmlPcatRecord = () => {

        const { isLoading: createIsLoading, mutateAsync: createMutate } = useMutation({
            mutationFn: async (records: SmlPcat[]) => {
                const response = await axiosInstance.post(ApiRoutes.SML_PCAT_POC_INSERT, { records });
                return response.data
            }
        })
        return {
            createIsLoading,
            createMutate
        }
    };

    const updateSmalPcatRecords = () => {

        const { isLoading: updateIsLoading, mutateAsync: updateMutate } = useMutation({
            mutationFn: async (records: SmlPcat[]) => {
                const response = await axiosInstance.put(ApiRoutes.SML_PCAT_POC, { records })
                return response.data
            }
        })
        return {
            updateIsLoading,
            updateMutate
        }
    };

    const deleteSmlPcatRecords = () => {
        const { isLoading: deleteIsLoading, mutateAsync: deleteMutate } = useMutation({
            mutationFn: async (ids: number[]) => {
                const response = await axiosInstance.delete(ApiRoutes.SML_PCAT_POC, {
                    data: {
                        ids
                    }
                });
                return response.data
            },
        })

        return {
            deleteIsLoading,
            deleteMutate
        }
    };


    return {
        fetchSmalPcatRecords,
        updateSmalPcatRecords,
        createSmlPcatRecord,
        deleteSmlPcatRecords,
    }
}


export default SmlPcatService