import { useMutation, useQuery } from '@tanstack/react-query';
import axiosInstance from 'interceptor/axiosInstance';

const ProductRemappingService = () => {
  const ProductMappingDropDown = ({ provider = null, apiUrl = null, itemDependsOn = null, status = null  }: any) => {
    const { data, isLoading, isFetching } = useQuery({
      enabled: (!!provider && !!apiUrl),
      queryKey: ['productRemappingDropDown', provider, apiUrl, itemDependsOn.join(', ')],
      queryFn: async () => {
        const modifiedUrl = provider && provider !== 'nielsen' ? `product/${provider}` : status === 'unprocessed' ? 'product/unprocessed': status === 'uaol-processed' ? 'product/uaol-processed' : 'product';
        const response = await axiosInstance.get(`remapping/${modifiedUrl}/${apiUrl}`);
        return response.data;
      }
    }); 
    return {
      data,
      isLoading,
      isFetching
    };
  };
  const UpdatedProductColumn = () => {
    const { mutate, data, isLoading, isError } = useMutation({
      mutationKey:['updateproduct-remappingColumn'],
      mutationFn: async ({ provider = null, value = null, id = null , status = null }: any) => {
        const modifiedUrl = provider && provider !== 'nielsen' ? `product/${provider}` : status === 'unprocessed' ? 'product/unprocessed': status === 'uaol-processed' ? 'product/uaol-processed' : 'product';
        const response = await axiosInstance.put(`remapping/${modifiedUrl}/${id}`, value);
        return response.data;
      }
    });

    return {
      mutate,
      data,
      isLoading,
      isError
    };
  };

  return {
    ProductMappingDropDown,
    UpdatedProductColumn
  };
};

export default ProductRemappingService;
