import React, { FC, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Arrow from 'assets/icons/CardArrowVector.svg';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Legend, Line, Dot } from 'recharts';
import DashboardServiceService from 'services/DashboardService/dashboard.service';
import './volumeOfDataProcessed.scss';
import NewEDDropdown from 'shared/components/NewEDDropdown';
import Loader from 'shared/components/Loader';
import MetaService from 'services/MetaService/meta.service';
import { MetaData } from 'models/MetaData/metaData.model';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import clsx from 'clsx';

interface VolumeOfDataProcessedProps {
  startDate?: string;
  endDate?: string;
  country?: string;
}

export interface ValidationFilters {
  filter_by_provider: string;
}

const VolumeOfDataProcessed: FC<VolumeOfDataProcessedProps> = (props) => {
  const { startDate, endDate, country } = props;

  const { fetchMeta } = MetaService();

  const [providerMeta, setProviderMeta] = useState<MetaData[]>([]);

  const [filters, setFilters] = useState<ValidationFilters>({
    filter_by_provider: ''
  });

  const updateFilters = (filterName: keyof ValidationFilters, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value
    });

    fetchVolatilityMetrics({
      filter_by_provider: value
    });
  };

  const handleProviderChange = (value: string) => {
    fetchVolatilityMetrics({
      filter_by_provider: value
    });
  };
  const handleProviderReset = () => {
    fetchVolatilityMetrics({
      filter_by_provider: ''
    });
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchVolatilityMetrics({
        start_date: startDate,
        end_date: endDate
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (country) {
      fetchVolatilityMetrics({
        filter_by_country: country
      });
    }
  }, [country]);

  const { fetchVolatilityMetrics, volatilityMetrics, volatilityUniqueColumns, loading: chartLoading } = DashboardServiceService();

  useEffect(() => {
    fetchMeta(ApiRoutes.HOME_PROVIDER_META, setProviderMeta, MetaData);
    fetchVolatilityMetrics();
  }, []);

  // if (!volatilityMetrics) return <Loader />;

  return (
    <Container fluid className='volume-of-data-processed'>
      <Row>
        <div className='card-title-container d-flex justify-content-between'>
          <div className='heading-container'>
            <h3>Volume of Data Processed</h3>
            <p>Hover on points to view detail.</p>
          </div>
          <img src={Arrow} alt='arrow-icon' />
        </div>
      </Row>
      <Row>
        <Col sm={8} className='area-chart-container'>
          {chartLoading ? (
            <Loader />
          ) : (
            <ResponsiveContainer width='100%' aspect={2}>
              <LineChart
                width={800}
                height={380}
                data={volatilityMetrics}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                {volatilityMetrics?.length && (
                  <XAxis
                    dataKey='name'
                    label={{
                      value: `( ${volatilityMetrics[0].name} - ${volatilityMetrics[volatilityMetrics.length - 1].name} )`,
                      dy: 20
                    }}
                  />
                )}
                <YAxis label={{ value: 'No.of files', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                {volatilityUniqueColumns.map((item, index) => {
                  let strokeColor = '';
                  switch (index) {
                    case 0:
                      strokeColor = '#008651';
                      break;
                    case 1:
                      strokeColor = '#FF0A7A';
                      break;
                    case 2:
                      strokeColor = '#9C44C0';
                      break;
                  }
                  return (
                    <Line
                      type='monotone'
                      dataKey={item}
                      stroke={strokeColor}
                      activeDot={{ r: 8 }}
                      width={5}
                      strokeWidth={2}
                      dot={<Dot rx={4} stroke={strokeColor} fill={strokeColor} />}
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          )}
        </Col>

        <Col sm={4} className='right-container'>
          <p className='data-provider-heading'>Data Providers</p>
          <div className='filter-container'>
            <NewEDDropdown
              // optionItems={providerMeta ?? []}
              // currentItemIndex={2}
              // labelText='Data Provider'
              // resetFunc={handleProviderReset}
              // defaultValue='All(22)'
              // updateValue={handleProviderChange}
              optionItems={providerMeta ?? []}
              // loading={!changedFilter && metaLoading}
              value={filters.filter_by_provider}
              currentItemIndex={2}
              resetIcon={true ? filters.filter_by_provider !== '' : false}
              resetFunc={() => updateFilters('filter_by_provider', '')}
              labelText='Data Provider'
              defaultValue='All(22)'
              updateValue={(value) => updateFilters('filter_by_provider', value)}
            />
          </div>
          <div className='list-container'>
            <ul className='data-providers'>
              {volatilityUniqueColumns.map((source, i) => (
                <div key={source} className='data-provider'>
                  <span className={clsx('data-provider-dot', `dot-${i}`)} />
                  <li className='data-provider-name'>{source}</li>
                </div>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default VolumeOfDataProcessed;
