import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "interceptor/axiosInstance";
import { ResponseTypes } from 'enums/responseTypes';

const FactMappedServices = () => {

  const FactMappedRecords = ({ filename, filters, sorting, status = null, provider = null }: any) => {

    const { data, isLoading, error, isPreviousData, isFetching, fetchNextPage, refetch } = useInfiniteQuery({
      queryKey: ['fact', filename, status, filters , sorting, provider],
      queryFn: async ({ pageParam = 0 }) => {
        const params = {
          offset: pageParam * 30,
          limit: 30,
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };
        const modifiedURL = provider ? `fact/${provider.toLowerCase()}` : 'fact';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/mapped`, { params });
        return response.data['result'];
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

    return {
      data,
      isLoading,
      error,
      refetch,
      isPreviousData,
      fetchNextPage,
      isFetching
    }
  }

  const FactMappedRecordsCount = ({ filename, filters, sorting, status = null, provider = null }: any) => {
    const { data, isError, isLoading, refetch } = useQuery({
      queryKey: ['factMappedCount', filename, filters, sorting, provider],
      queryFn: async () => {
        const params = {
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };
        const modifiedURL = provider ? `fact/${provider.toLowerCase()}` : 'fact';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/mapped/count`, { params });
        return response.data['total_count'];
      }
    });
    return {
      data,
      isLoading,
      isError,
      refetch
    }
  }

  const FactUnprocessedRecordsCount = ({ filename, filters, sorting, provider = null }: any) => {
    const { data, isError, isLoading, refetch } = useQuery({
      queryKey: ['factUnprocessedCount', filename, filters, sorting, provider],
      queryFn: async () => {
        const params = {
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename,
        };
        const modifiedURL = provider ? `fact/${provider.toLowerCase()}` : 'fact';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/unprocessed/count`, { params });
        return response.data['total_count'];
      }
    });
    return {
      data,
      isLoading,
      isError,
      refetch
    }
  }

  const downloadMappedFactRecords = ({ filename, provider = null }: any) => {
    const { isLoading: isDownloading, mutateAsync: triggerDownload } = useMutation({
      mutationFn: async () => {
        const modifiedURL = provider ? `fact/${provider.toLowerCase()}` : 'fact';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/mapped/download`, {
          params: { Filename: filename },
          responseType: ResponseTypes.BLOB
        });
        const contentDisposition =await response?.headers['content-disposition'];
        const matchName = contentDisposition.replace('attachment; filename=', '');
        const filenames = matchName.split('.')[0] + '.xlsx';

        const urlLink = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = urlLink;
        link.setAttribute('download', filenames);

        document.body.appendChild(link);
        link.click();
        return response
      }
    });
    return {
      isDownloading,
      triggerDownload
    };

  }

  const downloadUnprocessedFactRecords = ({ filename, provider = null }: any) => {
    const { isLoading: isDownloading, mutateAsync: triggerDownload } = useMutation({
      mutationFn: async () => {
        const modifiedURL = provider ? `fact/${provider.toLowerCase()}` : 'fact';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/unprocessed/download`, {
          params: { Filename: filename },
          responseType: ResponseTypes.BLOB
        });
        const contentDisposition =await response?.headers['content-disposition'];
        const matchName = contentDisposition.replace('attachment; filename=', '');
        const filenames = matchName.split('.')[0] + '.xlsx';

        const urlLink = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = urlLink;
        link.setAttribute('download', filenames);

        document.body.appendChild(link);
        link.click();
        return response
      }
    });
    return {
      isDownloading,
      triggerDownload
    };

  }

  const FactUnprocessedRecords = ({ filename, filters, sorting, provider = null }: any) => {
    const { data, isLoading, error, isPreviousData, isFetching, fetchNextPage, refetch } = useInfiniteQuery({
      queryKey: ['fact-unprocessed', filename, filters, sorting, provider],
      queryFn: async ({ pageParam = 0 }) => {
        const params = {
          offset: pageParam * 30,
          limit: 30,
          filters: JSON.stringify(filters ?? []),
          sorting: JSON.stringify(sorting ?? []),
          Filename: filename
        };
        const modifiedURL = provider ? `fact/${provider.toLowerCase()}` : 'fact';
        const response = await axiosInstance.get(`smart-mapping/${modifiedURL}/unprocessed`, { params });
        return response.data['result'];
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

    return {
      data,
      isLoading,
      error,
      refetch,
      isPreviousData,
      isFetching,
      fetchNextPage
    }
  }

  return {
    FactMappedRecords,
    FactUnprocessedRecords,
    downloadMappedFactRecords,
    downloadUnprocessedFactRecords,
    FactMappedRecordsCount,
    FactUnprocessedRecordsCount
  }
}

export default FactMappedServices;
