import React from 'react'
import Button from 'react-bootstrap/Button';
import {Form} from 'react-bootstrap';
import './EDTextInputs.scss';

interface Props {
  placeholder:string;
  controlId?:string;
  type:string;
  onChange?:any;
  onBlur?:any
  value?:string
  borderless?:boolean
  defaultValue?:string | number
}
const EDTextInputs = ({placeholder, controlId, type, onChange, value, borderless, onBlur, defaultValue}:Props) => {
  return (
    <Form className='ed-input'>
      <Form.Group className="mb-3" controlId={controlId}>
       {borderless ? (<Form.Control defaultValue={defaultValue} type={type} placeholder={placeholder} onBlur={onBlur} className='input-field borderless' onChange={onChange} value={value}/>) : (<Form.Control type={type} placeholder={placeholder} className='input-field' onChange={onChange} value={value}/>)}
      </Form.Group>
    </Form>
  )
}

export default EDTextInputs