import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query'
import axiosInstance from "interceptor/axiosInstance"
import { ApiRoutes } from 'routes/routeConstants/apiRoutes'
import { deserialize } from 'serializr';
import { CellControlStatus } from 'models/CellControl/cellControl.model';
import { type MRT_ColumnFiltersState,  type MRT_SortingState } from 'mantine-react-table';


interface ParamsObject {
    offset?: Number,
    limit?: Number,
    filters?: string,
    sorting?: string,
    activated?: Boolean,
    deactivated?: Boolean
}

interface Params {
    columnFilters: MRT_ColumnFiltersState;
    sorting: MRT_SortingState;
    cellStatus: {
        activated: Boolean,
        deactivated: Boolean
    }
}
interface UpdateArg {
    status: number;
    ids: number[];
}


const CellControlServices = () => {



    const cellControlRecords = ({  columnFilters, sorting, cellStatus }: Params) => {

        const { data, isLoading, refetch, fetchNextPage, isFetching } = useInfiniteQuery({
            queryKey: ['cellcontrol', columnFilters, sorting, cellStatus.activated, cellStatus.deactivated],
            queryFn: async ({ pageParam = 0 }) => {

                const paramsObj: ParamsObject = {
                    offset: pageParam * 30,
                    limit: 30,
                    filters: JSON.stringify(columnFilters ?? []),
                    sorting: JSON.stringify(sorting ?? []),
                    activated: cellStatus.activated,
                    deactivated: cellStatus.deactivated
                }
                const response = await axiosInstance.get(ApiRoutes.CELL_CONTROL, { params: paramsObj })
                // const data = deserialize(CellControl, response.data['result']) as CellControl[];
                return response.data
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        })
        return {
            data,
            isLoading,
            refetch,
            fetchNextPage,
            isFetching
        }
    }

    const fetchcellControlStatus = ({ columnFilters, sorting }: any) => {
        const paramsObj: ParamsObject = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting ?? [])
        }
        return useQuery({
            queryKey: ['cellControlStatus'],
            queryFn: async () => {
                const response = await axiosInstance.get(ApiRoutes.CELL_CONTROL_STATUS, { params: paramsObj })
                const data = deserialize(CellControlStatus, response.data);
                return data
            }
        })
    }



    const updateCellControlMutation = useMutation(
        (datas: UpdateArg) => {
            return axiosInstance.put(ApiRoutes.CELL_CONTROL, datas);
        },

    );

    const updateCellControlRecords = async (status: number, ids: number[]) => {
        const res = await updateCellControlMutation.mutateAsync({ status, ids });

        return res.data
    };




    return {
        cellControlRecords,
        updateCellControlRecords,
        fetchcellControlStatus,
    }

}


export default CellControlServices