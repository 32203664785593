import { create } from 'zustand';

export const FILTER_META: any = {
  country: 'country',
  category: 'category',
  provider: 'dataProvider',
  search:'searchValue'
}

interface FilterType {
  country: string | undefined,
  category: string | undefined,
  dataProvider: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
  searchValue:string | undefined
}

interface Filter extends FilterType {
  setFilterValue: (filterName: keyof FilterType, value: string) => void,
}

const useFilterStore = create<Filter>((set) => ({
  country: undefined,
  category: undefined,
  dataProvider: undefined,
  startDate:undefined,
  endDate: undefined,
  searchValue:'',
  setFilterValue: (filterName, value) =>
    set((state) => ({
      ...state,
      [filterName]: value,
    })),

}));

export default useFilterStore;