import React from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import Send from 'assets/icons/send-icon.svg';
import './RemarksModal.scss';
import CustomButton from '../Button-component/Button';

export default function RemarksModal(props:any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
    >
      <div className='remarks-modal'> 
      <Modal.Header closeButton className='header-container'> 
        <Modal.Title id="contained-modal-title-vcenter">
          <div className='modal-heading'>
          <img src={Send} alt='send' />
            <p>Send Remarks</p>
            </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              placeholder='Type Here...'
            >
              <Form.Control as="textarea" rows={12} />
            </Form.Group>
          </Form>
      </Modal.Body>
      <Modal.Footer className='modal-footer'>
      <CustomButton secondary onClick={props.onHide}>Cancel</CustomButton>
      <CustomButton primary onClick={props.onHide}>Submit</CustomButton>
      </Modal.Footer>
      </div>
    </Modal>
    
  );
}

