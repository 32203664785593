import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Arrow from 'assets/icons/CardArrowVector.svg';
import Download from 'assets/icons/download-icon.svg';
import './confidenceMapping.scss';
import NewEDDropdown from 'shared/components/NewEDDropdown';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DashboardServiceService from 'services/DashboardService/dashboard.service';
import Loader from 'shared/components/Loader';
import ValidationService from 'services/ValidationService/validation.service';
import MetaService from 'services/MetaService/meta.service';
import { MetaData } from 'models/MetaData/metaData.model';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { confidenceLevels } from 'constants/formats';
import clsx from 'clsx';

interface ConfidenceMappingProps {
  startDate?: string;
  endDate?: string;
  country?: string;
}

export interface ValidationFilters {
  filter_by_provider: string;
}

const ConfidenceMapping = (props: ConfidenceMappingProps) => {
  const { fetchConfidenceMapping, confidenceMapping, totalRequests, loading } = DashboardServiceService();
  const { startDate, endDate, country } = props;
  const barChartHeight = useRef<any>();

  const { fetchMeta } = MetaService();

  const [providerMeta, setProviderMeta] = useState<MetaData[]>([]);
  const [filters, setFilters] = useState<ValidationFilters>({
    filter_by_provider: ''
  });

  const updateFilters = (filterName: keyof ValidationFilters, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value
    });

    fetchConfidenceMapping({
      filter_by_provider: value
    });
  };

  useEffect(() => {
    fetchConfidenceMapping();
    fetchMeta(ApiRoutes.HOME_PROVIDER_META, setProviderMeta, MetaData);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchConfidenceMapping({
        start_date: startDate,
        end_date: endDate
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (country) {
      fetchConfidenceMapping({
        filter_by_country: country
      });
    }
  }, [country]);

  const handleProviderChange = (value: string) => {
    fetchConfidenceMapping({
      filter_by_provider: value
    });
  };
  const handleProviderReset = () => {
    fetchConfidenceMapping({
      filter_by_provider: ''
    });
  };

  if (!confidenceMapping) return <Loader />;

  const heightHandler = () => {
    const height = barChartHeight.current.offsetHeight;
    const updateHeight = document.querySelector<HTMLElement>('.task-updates .tasks-header')?.offsetHeight!;
    const newHeight = height - updateHeight - 54;

    const elm = document.querySelector<HTMLElement>('.task-updates .task-list-container ')!;
    elm.style.maxHeight = newHeight + 'px';
    elm.style.minHeight = newHeight + 'px';
  };

  return (
    <Container fluid className='confidence-mapping graphs-card' ref={barChartHeight}>
      <Row className='tasks-header'>
        <div className='heading-container d-flex justify-content-between'>
          <h3>Confidence Level of Data Mapping</h3>
          <img src={Arrow} alt='arrow-icon' />
        </div>
        <div className='d-flex justify-content-between'>
          <div className='filter-container'>
            <NewEDDropdown
              // optionItems={providerMeta}
              // currentItemIndex={2}
              // labelText='Data Provider'
              // defaultValue='All(22)'
              // resetFunc={handleProviderReset}
              // updateValue={handleProviderChange}

              optionItems={providerMeta ?? []}
              // loading={!changedFilter && metaLoading}
              value={filters.filter_by_provider}
              currentItemIndex={2}
              resetIcon={true ? filters.filter_by_provider !== '' : false}
              resetFunc={() => updateFilters('filter_by_provider', '')}
              labelText='Data Provider'
              defaultValue='All(22)'
              updateValue={(value) => updateFilters('filter_by_provider', value)}
            />
          </div>

          <div className='download-container'>
            <span className='pr-2'>Download</span>
            <img src={Download} alt='download' />
          </div>
        </div>
      </Row>
      <Row className='mt-5'>
        <Col sm={12}>
          {loading ? (
            <Loader />
          ) : (
            <ResponsiveContainer width='100%' aspect={2} height={'auto'}>
              <BarChart
                width={800}
                height={320}
                data={confidenceMapping}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid horizontal={false} />
                <XAxis dataKey='provider' label={{ value: 'Data providers', dy: 20 }} />
                <YAxis label={{ value: 'No.of files', angle: -90, position: 'insideLeft', offset: -12 }} />
                <Tooltip />
                <Bar dataKey='high' fill='#00B190' onAnimationStart={heightHandler} barSize={25} />
                <Bar dataKey='medium' fill='#FF9E00' barSize={25} />
                <Bar dataKey='low' fill='#BDBDBD' barSize={25} />
              </BarChart>
            </ResponsiveContainer>
          )}
          <div className='justify-content-between d-flex aligh-item-baseline mb-2 mt-5'>
            <p className='data-provider-title'>Confidence level (in %)</p>
            <p className='data-provider-total-count'>{totalRequests}</p>
          </div>
          <div className='justify-content-between d-flex'>
            <ul className='data-providers'>
              {confidenceLevels.map((confidence, i) => (
                <div key={confidence} className='data-provider'>
                  <span className={clsx('data-provider-dot', `dot-${i}`)} />
                  <li className='data-provider-name'>{confidence}</li>
                </div>
              ))}
            </ul>
            <p className='data-provider-total-req'>Total requests</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfidenceMapping;
