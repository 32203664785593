import React from 'react';
import DatePicker from 'react-datepicker';
import './EDDatePicker.scss';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { defaultDateTimeFormat } from 'constants/formats';
import { MomentUnits } from 'enums/momentUnits';
import useFilterStore from 'store/filter.store';

interface EDDatePickerProps {
  onDateSelected?: (startDate: string, endDate: string) => void;
}

const EDDatePicker = (props: EDDatePickerProps) => {
  const { onDateSelected } = props;
  const { startDate, endDate } = useFilterStore()
  const [start_Date, setStartDate] = React.useState<Date | null>(
    startDate ? new Date(startDate) : null
  );
  const [end_Date, setEndDate] = React.useState<Date | null>(
    endDate ? new Date(endDate) : null
  );
  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      onDateSelected &&
        onDateSelected(
          moment(start).startOf(MomentUnits.DAY).format(defaultDateTimeFormat),
          moment(end).endOf(MomentUnits.DAY).format(defaultDateTimeFormat)
        );
    } else if (start === null && end === null) {
      onDateSelected && onDateSelected('', '');
    }
  };
  return (
    <DatePicker
      onChange={onChange}
      startDate={start_Date}
      endDate={end_Date}
      selectsRange
      placeholderText='Date:'
      className='ed-datepicker'
      isClearable
      clearButtonClassName='datepicker-close-icon'
    />
  );
};

export default EDDatePicker;
