import React, { useEffect } from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import Header from './Header';
import { dimensions } from 'shared/utils/dimensions';
import SegmentedTab from 'shared/components/SegmentedTab';
import useQueryParams from 'shared/hooks/useQueryParams';
import Table from './Table';
import VolatilityService from 'services/VolatilityService/volatility.service';
import { useParams } from 'react-router';

const paramKeys = ['dimension'];

const Mapping = () => {
  const { loading, fetchIndividualFileVolatility, individualFileVolatilityData } = VolatilityService();

  const { id } = useParams<{ id: string }>();

  const { getParams, setParams } = useQueryParams();

  const { dimension } = getParams(paramKeys);

  useEffect(() => {
    id && fetchIndividualFileVolatility(id);
  }, [id]);

  useEffect(() => {
    if (!dimensions.includes(dimension)) setParams({ dimension: dimensions[0] });
  }, [dimension]);

  const onTabChange = (value: string) => setParams({ dimension: value });

  return (
    <DashboardContainer>
      <Header individualFileVolatilityData={individualFileVolatilityData} loading={loading} />
      <SegmentedTab data={dimensions} onChange={onTabChange} value={dimension ?? ''} />
      <Table id={id} dimension={dimension} individualFileVolatilityData={individualFileVolatilityData} />
    </DashboardContainer>
  );
};

export default Mapping;
