import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './summary.scss';
import { Button, Loader, Text } from '@mantine/core';
import {
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_Virtualizer
} from 'mantine-react-table';
import VolatilityService from 'services/VolatilityService/volatility.service';
import { getFVSummaryColumns } from './columns';
import { IndividualFileVolatility } from 'models/IndividualFileVolatility/IndividualFileVolatility.model';
import useRedirect from 'shared/hooks/useRedirect';
import { IconCheck, IconDownload } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import CustomButton from 'shared/components/Button-component/Button';
import MantineTable from 'views/FileVolatility/MantineTable';
import clsx from 'clsx';

interface FVStatus {
  filter_by_success: boolean;
  filter_by_fail: boolean;
  filter_by_in_progress: boolean;
}

function Summary() {
  const { redirectToFVMapping } = useRedirect();

  const { fetchFVSummaryData, buttonLoading, downloadFileVolatilityRecords } = VolatilityService();

  const [status, setStatus] = useState<FVStatus>({
    filter_by_success: false,
    filter_by_fail: false,
    filter_by_in_progress: false
  });

  const [tableElement, setTableElement] = useState<any>(null);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [tempData, setTempData] = useState<IndividualFileVolatility[]>([]);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const { data, isError, isLoading, refetch, fetchNextPage, isFetching, isPreviousData } = fetchFVSummaryData({
    sorting,
    columnFilters,
    status
  });

  const summaryData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result) ?? [];
  }, [data]);

  useEffect(() => {
    setTempData(summaryData ? summaryData : []);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [status]);

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {}
    }
  }, [sorting, columnFilters]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [tempData]);

  const totalDBRowCount = data?.pages?.[0]?.count ?? 0;

  const totalFetched = summaryData.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        setTableElement(containerRefElement);

        if (scrollHeight - scrollTop - clientHeight < 400 && !isFetching && totalFetched < totalDBRowCount) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  const handleDownload = () => {
    const selectedIds = Object.keys(rowSelection).filter((key) => rowSelection[key] === true);
    downloadFileVolatilityRecords({ ids: selectedIds });
    notifications.show({
      title: 'Success',
      message: 'Sample file will be downloaded',
      color: 'green',
      icon: <IconCheck />,
      autoClose: 2000
    });
    setRowSelection({});
  };

  const updateStatus = (filterName: keyof FVStatus, value: boolean) => () => {
    setStatus((prev) => ({ ...prev, [filterName]: value }));
  };

  const customRecordsButton = ({ table }: { table: MRT_TableInstance<IndividualFileVolatility> }) => {
    return !isLoading ? (
      <div className='fv-table-actions'>
        <Button className='download-btn' onClick={handleDownload} loading={buttonLoading} rightIcon={<IconDownload />}>
          Sample
        </Button>
        <Button.Group className='status-btns-group'>
          <Button
            variant='outline'
            className={clsx(status.filter_by_in_progress && 'active')}
            onClick={updateStatus('filter_by_in_progress', !status.filter_by_in_progress)}
          >
            In Progress
          </Button>
          <Button
            variant='outline'
            className={clsx(status.filter_by_success && 'active')}
            onClick={updateStatus('filter_by_success', !status.filter_by_success)}
          >
            Success
          </Button>
          <Button
            variant='outline'
            className={clsx(status.filter_by_fail && 'active')}
            onClick={updateStatus('filter_by_fail', !status.filter_by_fail)}
          >
            Failure
          </Button>
        </Button.Group>
      </div>
    ) : (
      <Loader size={15} />
    );
  };

  const handleRowClick = (props: {
    isDetailPanel?: boolean;
    row: MRT_Row<IndividualFileVolatility>;
    staticRowIndex: number;
    table: MRT_TableInstance<IndividualFileVolatility>;
  }) => ({
    onClick: () => props.row.original.LogId && redirectToFVMapping(props.row.original.LogId),
    sx: { cursor: 'pointer' }
  });

  const renderBottomToolbarCustomActions = () => (
    <div className='bottom-Action'>
      <p>Total Records : {totalDBRowCount}</p>
      <Text className='bottom-text'>
        Showing {totalFetched} of {totalDBRowCount} total rows.
      </Text>
    </div>
  );

  const getRowId = (originalRow: IndividualFileVolatility) => originalRow.LogId;

  const mantineToolbarAlertBannerProps = isError
    ? {
        color: 'red',
        children: 'Error loading data'
      }
    : undefined;

  return (
    <MantineTable
      data={tempData}
      columns={getFVSummaryColumns}
      customActions={true}
      columnPinning={false}
      enablePinning={true}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableStickyHeader={true}
      enablePagination={false}
      enableColumnActions={false}
      enableGlobalFilter={false}
      manualFiltering={true}
      manualSorting={true}
      onColumnFiltersChange={setColumnFilters}
      onSortingChange={setSorting}
      columnFilters={columnFilters}
      isLoading={isLoading}
      sorting={sorting}
      isFetching={isFetching}
      fetchMoreOnBottomReached={(val: any) => fetchMoreOnBottomReached(val)}
      rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
      tableContainerRef={tableContainerRef}
      isPreviousData={isPreviousData}
      rowSelection={rowSelection}
      enableRowSelection
      onRowSelectionChange={setRowSelection}
      customRecordsButton={customRecordsButton}
      getRowId={getRowId}
      mantineToolbarAlertBannerProps={mantineToolbarAlertBannerProps}
      renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
      mantineTableBodyRowProps={handleRowClick}
    />
  );
}

export default Summary;
