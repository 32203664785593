import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import VolatilityService from 'services/VolatilityService/volatility.service';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { getfileVolatilityMappingColumns } from './columns';
import { Mappings } from 'models/Mappings/mappings.model';
import CustomButton from 'shared/components/Button-component/Button';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconDownload, IconEdit, IconMessage, IconUpload, IconX } from '@tabler/icons-react';
import './table.scss';
import { Button, Skeleton } from '@mantine/core';
import FileVolatility from 'services/FileVolatility/fileVolatility.service';
import { IndividualFileVolatility } from 'models/IndividualFileVolatility/IndividualFileVolatility.model';
import RemarksModal from 'shared/components/remarks-modal/RemarksModal';
import UpdateTargetModal from 'shared/components/update-target-modal/UpdateTargetModal';
import deepClone from 'shared/utils/deepClone';

interface FVDownloadObject {
  DataProvider?: string;
  Country?: string;
  Category?: string;
  Filename?: string;
  Entity?: string;
}
interface TableProps {
  id?: string;
  dimension: string;
  individualFileVolatilityData?: IndividualFileVolatility;
}

type ModalType = 'update' | 'remarks';

const Table: FC<TableProps> = (props) => {
  const { id, dimension, individualFileVolatilityData } = props;

  const { fetchColumnMappings, fileMappingData: data, loading, updateMappingValues, buttonLoading } = VolatilityService();

  const { loading: downloadLoading, triggerCAPipeline } = FileVolatility();

  const [result, setResult] = useState<Mappings[]>([]);

  const [selectedSource, setSelectedSource] = useState<(string | undefined)[]>([]);

  const [selectedCriticals, setSelectedCriticals] = useState<(string | undefined)[]>([]);

  const [isMissing, setIsMissing] = useState<number>(0);

  const [enableSubmitButton, setEnableSubmitButton] = useState<boolean>(false);

  const [openModalType, setOpenModalType] = useState<ModalType>();

  useEffect(() => {
    id && fetchColumnMappings(id, { entity: dimension });
  }, [dimension]);

  useEffect(() => {
    formatCriticalAttributesTableData();
  }, [data]);

  useEffect(() => {
    handleResultChange();
  }, [result]);

  const formatCriticalAttributesTableData = () => {
    const Source = data && data?.SourceColumn ? data.SourceColumn?.split('|') : [];
    const Target = data && data?.TargetColumn ? data.TargetColumn.split('|') : [];
    const repeatedValue = '-';
    const SourcePrevious = data && data?.PreviousSource ? data.PreviousSource?.split('|') : Array(Target.length).fill(repeatedValue);
    const criticality = data && data?.CriticalFlag ? data.CriticalFlag?.split('|') : [];
    const missingAttributes = data && data?.Missing_Critical_Attribute_Value ? data.Missing_Critical_Attribute_Value?.split('|') : [];
    if (missingAttributes.length === 1 && missingAttributes[0] === `All critical attributes are present for ${data?.FileName}`)
      setIsMissing(0);
    else setIsMissing(missingAttributes.length);

    const result = Source.map((item: string, index: number) => ({
      source: item,
      target: Target[index],
      sourcePrevious: SourcePrevious[index],
      id: index + 1,
      criticality: criticality[index] === '1' ? '1' : '0',
      isMissingAttribute: missingAttributes.includes(item) ? true : false
    }));

    setResult(result);
    setEnableSubmitButton(false);
  };

  const sourceColumnList = data && data?.SourceColumnList ? data.SourceColumnList?.split('|') : [];

  const uniqueArray = sourceColumnList.map((item) => {
    return {
      name: item,
      value: item
    };
  });

  const handleResultChange = () => {
    const source = result.map((data) => data.source);
    const criticals = result.map((data) => data.criticality);
    setSelectedSource(source);
    setSelectedCriticals(criticals);
  };

  const handleSelect = (index: number) => (value: string) => {
    const temp = [...result];
    temp[index]['source'] = value;
    setResult(temp);
    setEnableSubmitButton(true);
  };

  const handleCrtiticalAttrChange = (index: number, value: string) => () => {
    const oldData = [...result];
    oldData[index]['criticality'] = value === '1' ? '0' : '1';
    setResult(oldData);
    setEnableSubmitButton(true);
  };

  const updateColumnValues = async () => {
    const sourceColumn: string = selectedSource.join('|');
    const criticalAttributes: string = selectedCriticals.join('|');

    if (data?.Id === undefined) return;
    id &&
      updateMappingValues(sourceColumn, criticalAttributes, data.Id ?? '', id)
        .then(() => {
          notifications.show({
            title: 'Success',
            message: 'Mapping successfully updated',
            color: 'green',
            icon: <IconCheck />,
            autoClose: 2000
          });
          setEnableSubmitButton(false);
        })
        .catch((data) =>
          notifications.show({
            title: 'Failure',
            message: 'Record Creation Unsuccessfull' + data.error.message,
            color: 'green',
            icon: <IconX />,
            autoClose: 2000
          })
        );
  };

  const handleDownload = async () => {
    const modifiedFileName = data?.FileName?.split('.');

    const downloadObj: FVDownloadObject = {
      DataProvider: individualFileVolatilityData?.SOURCE,
      Country: individualFileVolatilityData?.COUNTRY,
      Category: individualFileVolatilityData?.CATEGORY,
      Filename: modifiedFileName ? modifiedFileName[0] : '',
      Entity: dimension
    };

    await triggerCAPipeline(downloadObj);
  };

  const handleModalOpen = (type: ModalType) => () => setOpenModalType(type);

  const closeModal = () => setOpenModalType(undefined);

  const handleUpdateSuccess = () => {
    id && fetchColumnMappings(id, { entity: dimension });
    notifications.show({
      title: 'Success',
      message: 'Target Values Updated',
      color: 'green',
      icon: <IconCheck />,
      autoClose: 2000
    });
    closeModal();
  };

  const renderBottomToolbar = (
    <div className='fv-mapping-table__footer'>
      <Button className='primary-btn' disabled={!enableSubmitButton || buttonLoading} onClick={updateColumnValues}>
        {buttonLoading ? 'Submitting...' : 'Submit'}
      </Button>
      <Button variant='outline' disabled={!enableSubmitButton || buttonLoading} onClick={formatCriticalAttributesTableData}>
        Cancel
      </Button>
    </div>
  );

  const table = useMantineReactTable({
    columns: getfileVolatilityMappingColumns(
      uniqueArray,
      handleSelect,
      handleCrtiticalAttrChange,
      isMissing,
      result?.length,
      individualFileVolatilityData?.SOURCE
    ),
    data: result,
    enableGlobalFilter: false,
    enableFilters: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTableFooter: false,
    enablePagination: false,
    enableMultiSort: false,
    enableColumnActions: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableStickyFooter: true,
    enableStickyHeader: true,
    state: {
      isLoading: loading
    },
    mantineTableProps: {
      striped: true
    },
    mantineTableContainerProps: {
      className: 'fv-mapping-table__container'
    },
    mantinePaperProps: {
      className: 'fv-mapping-table__paper'
    },
    mantineTableHeadRowProps: {
      className: 'fv-mapping-table__header__row'
    },
    mantineTableBodyProps: {
      className: 'fv-mapping-table__body'
    },
    renderBottomToolbar
  });

  return (
    <div className='fv-mapping-table'>
      <div className='fv-mapping-table__header'>
        {loading ? (
          <Skeleton height={25} radius='md' width='40%' />
        ) : (
          <p className='file-name-status'>
            <span className='file-name-status__title'>Filename: </span>
            <span className='file-name-status__name'>{data?.FileName}</span>
            <span className='file-name-status__title'>Status:</span>
            <span className={clsx('file-name-status__status', individualFileVolatilityData?.PIPELINESTATUS?.toLocaleLowerCase())}>
              {individualFileVolatilityData?.PIPELINESTATUS}
            </span>
          </p>
        )}
        <div className='fv-mapping-table__actions'>
          <Button.Group>
            <Button variant='outline' disabled>
              Excel
            </Button>
            <Button variant='outline' rightIcon={<IconDownload />} />
            <Button variant='outline' rightIcon={<IconUpload />} />
          </Button.Group>
          <Button variant='outline' rightIcon={<IconMessage />} onClick={handleModalOpen('remarks')}>
            Remarks
          </Button>
          <Button variant='outline' rightIcon={<IconEdit />} onClick={handleModalOpen('update')}>
            Update
          </Button>
          {dimension === 'product' && (
            <Button variant='outline' rightIcon={<IconDownload />} loading={downloadLoading} onClick={handleDownload}>
              Download
            </Button>
          )}
        </div>
      </div>
      <MantineReactTable table={table} />
      <RemarksModal show={openModalType === 'remarks'} onHide={closeModal} className='remarks-modal' />
      {openModalType === 'update' && (
        <UpdateTargetModal
          show={openModalType === 'update'}
          onSuccess={handleUpdateSuccess}
          onHide={closeModal}
          className='remarks-modal'
          optionItems={deepClone(result)}
          mappingId={data?.Id?.toString() ?? ''}
        />
      )}
    </div>
  );
};
export default Table;
