import { Breadcrumbs } from '@mantine/core';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import './breadCrumbs.scss';
import leftIcon from 'assets/icons/left-icon.svg';

const MantineBreadcrumbs = () => {
  const location = useLocation();

  const locationValue = location.pathname.split('/').filter(Boolean);
  locationValue.pop();
  const linkArray: Array<any> = [];

  const breadcrumbs = locationValue.map((ele, i) => {
    linkArray.push(ele);
    const joinedArray = linkArray.join('/');
    let route = joinedArray;

    if (i === locationValue.length - 2) route += '/' + locationValue.at(-1);
    else route = joinedArray;

    return (
      <Link to={'/' + route} key={i}>
        {ele}
      </Link>
    );
  });

  return (
    <>
      <div className='mantine-breadcrumb-container'>
        <img src={leftIcon} alt='left-icon' />
        <Breadcrumbs separator='/' mt='xs'>
          {breadcrumbs}
        </Breadcrumbs>
      </div>
    </>
  );
};

export default MantineBreadcrumbs;
