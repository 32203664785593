export const criticalAttributeTableColumns = [
  { header: 'Id', accessorKey: 'Id', maxSize: 70, enableResizing: true, enableColumnDragging: true, enableEditing: false },
  { header: 'Country', accessorKey: 'Country', maxSize: 70, enableResizing: true, enableColumnDragging: true },

  {
    header: 'Global Database Name',
    accessorKey: 'GlobalDatabaseName',
    maxSize: 70,
    enableResizing: true,
    enableColumnDragging: true
  },

  { header: 'Local Database Name', accessorKey: 'LocalDatabaseName', maxSize: 300, enableResizing: true, enableColumnDragging: true },

  { header: 'MarketNameCode', accessorKey: 'MarketNameCode', maxSize: 250, enableResizing: true, enableColumnDragging: true },

  { header: 'COMPANY', accessorKey: 'COMPANY', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'GLOBAL BRAND', accessorKey: 'GLOBALBRAND', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'BRAND', accessorKey: 'BRAND', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'SUBBRAND', accessorKey: 'SUBBRAND', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'VARIANT', accessorKey: 'VARIANT', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'TOTAL', accessorKey: 'TOTAL', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'CATEGORY GROUP', accessorKey: 'CATEGORY_GROUP', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'CATEGORY', accessorKey: 'CATEGORY', maxSize: 220, enableResizing: true, enableColumnDragging: true },

  { header: 'MARKET', accessorKey: 'MARKET', maxSize: 220, enableResizing: true, enableColumnDragging: true },

  { header: 'SEGMENT', accessorKey: 'SEGMENT', maxSize: 220, enableResizing: true, enableColumnDragging: true },

  { header: 'SUB SEGMENT', accessorKey: 'SUB_SEGMENT', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'TYPE', accessorKey: 'TYPE', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'FORMAT', accessorKey: 'FORMAT', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'FORM', accessorKey: 'FORM', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'TARGET USER', accessorKey: 'TARGET_USER', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'CORE BENEFIT', accessorKey: 'CORE_BENEFIT', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'TARGET AREA', accessorKey: 'TARGET_AREA', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'PACK TYPE', accessorKey: 'PACK_TYPE', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'PLATFORM', accessorKey: 'PLATFORM', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'MACHINE VS HAND WASH', accessorKey: 'MACHINE_VS_HAND_WASH', maxSize: 250, enableResizing: true, enableColumnDragging: true },

  { header: 'SUBFORMAT', accessorKey: 'SUBFORMAT', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'ACTUAL PACK SIZE', accessorKey: 'ACTUAL_PACK_SIZE', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'BASE PACK SIZE', accessorKey: 'BASE_PACK_SIZE', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'NUMBER IN PACK', accessorKey: 'NUMBER_IN_PACK', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'FLAVOUR', accessorKey: 'FLAVOUR', maxSize: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'ITEM', accessorKey: 'ITEM', maxSize: 200 }
];
