export const FactMappedRemapping = [
  {
    key: 'internal-fact-description',
    attributeName: 'Long Description',
    external: 'Externaldesc',
    internal: 'Harmonizedname',
    apiEndPoint: 'internal-fact-description',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'short-description',
    attributeName: 'Short Description',
    external: 'Short',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'currency',
    attributeName: 'Currency',
    external: 'Currency',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'precision',
    attributeName: 'Precision',
    external: 'Precision',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'denominator',
    attributeName: 'Denominator',
    external: 'Denominator',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'unique-tag',
    attributeName: 'Unique TAG',
    external: 'Uniqueidentifier',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'internal-fact-type',
    attributeName: 'Fact Type',
    external: null,
    internal: 'Facttype',
    apiEndPoint: 'internal-fact-type',
    dependsOn: ['Harmonizedname'],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  }
];
