import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Cards from 'shared/components/Card-component';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import useRedirect from 'shared/hooks/useRedirect';

const GlobalMetadataMapping = () => {
  const { redirectToFactMeta, redirectToCriticalAttribute, redirectToMarketMetada,redirectToPeriodMeta } = useRedirect();

  const metadataCells = [
    { name: 'FACT', onClick: redirectToFactMeta },
    { name: 'Critical Attribute', onClick: redirectToCriticalAttribute },
    { name: 'Market', onClick: redirectToMarketMetada },
    { name: 'Period', onClick: redirectToPeriodMeta },
  ];
  return (
    <div>
      <DashboardContainer>
        <div className='reports-page-content'>
          <Row>
            <Col xs={11} className='page-title-text'>
              <h1 className='page-title'>Global RMS Metadata Mapping</h1>
            </Col>
          </Row>
          <Row className='cards-container'>
            {metadataCells.map(({ name, onClick }) => (
              <Col xs={4} key={name}>
                <Cards heading={name} cardHeaderClickHandler={onClick} />
              </Col>
            ))}
          </Row>
        </div>
      </DashboardContainer>
    </div>
  );
};

export default GlobalMetadataMapping;
