import React from 'react';
import { Cell } from 'react-table';
import dayjs from 'dayjs';

export const columns = [
  {
    header: 'Dataset',
    accessorKey: 'Dataset',
    size: 100,
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    header: 'Data Provider',
    accessorKey: 'dataProvider',
    size: 100,
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    header: 'Data Provider Type',
    accessorKey: 'DirectIndirect',
    minWidth: 160,
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    header: 'Country',
    accessorKey: 'country',
    size: 100,
    enableColumnFilter: false,
    enableSorting: false
  },
  // {
  //   header: 'External Category',
  //   accessorKey: 'category',
  //   size: 100,
  //   enableColumnFilter: false,
  //   enableSorting: false
  // },
  // {
  //   header: 'Cell Database',
  //   accessorKey: 'data',
  //   enableColumnFilter: false,
  //   enableSorting: false,
  //   Cell: (cell: any) => {
  //     return (
  //       <div>
  //         {cell.row.original?.fileName} {cell.row.original?.category}
  //       </div>
  //     );
  //   }
  // },
  // {
  //   header: 'File Name',
  //   accessorKey: 'fileName',
  //   enableColumnFilter: false,
  //   enableSorting: false
  // },
  // {
  //   header: 'Loaded on',
  //   accessorKey: 'createdOn',
  //   enableColumnFilter: false,
  //   enableSorting: true,
  //   Cell: ({ value }: any) => dayjs(value).format('DD/MMM/YY hh:mm a')
  // },
  // {
  //   header: 'Records',
  //   enableColumnFilter: false,

  //   columns: [
  //     {
  //       header: 'Mapped',
  //       accessorKey: 'TotalMappedRecords',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //       size: 120
  //     },
  //     {
  //       header: 'Unprocessed',
  //       accessorKey: 'TotalUnprocessedRecords',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //       size: 120,
  //       // Cell: (cell: Cell) => <div>{cell.value ?? 0}</div>
  //     },
  //     {
  //       header: 'Mapped by SME',
  //       accessorKey: 'TotalRecordsMappedBySME',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //       size: 120
  //     },
  //     {
  //       header: 'Total',
  //       accessorKey: 'TotalRecords',
  //       size: 120,
  //       enableColumnFilter: false,
  //       enableSorting: true
  //     },
  //     {
  //       header: 'Success Status(%)',
  //       size: 100,
  //       accessorKey: 'successStatus',
  //       enableColumnFilter: false,
  //       enableSorting: false,
  //       Cell: (cell: any) => {
  //         const original = cell.row.original;
  //         const mappedCount = original.mappedRecordCount ?? 0;
  //         const total = original.total ?? 0;
  //         const successStatus = ((mappedCount / total) * 100).toFixed(2);
  //         return <div>{successStatus}</div>;
  //       }
  //     }
  //   ]
  // },
  // {
  //   header: 'Confidence Level of Mapped Records',
  //   size: 200,

  //   columns: [
  //     {
  //       header: 'High',
  //       accessorKey: 'High',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //       size: 120,
  //       // Cell: (cell: Cell) => <div>{cell.value ?? 0}</div>
  //     },
  //     {
  //       header: 'Medium',
  //       accessorKey: 'Medium',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //       size: 120,
  //       // Cell: (cell: Cell) => <div>{cell.value ?? 0}</div>
  //     },
  //     {
  //       header: 'Low',
  //       accessorKey: 'Low',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //       size: 120,
  //       // Cell: (cell: Cell) => <div>{cell.value ?? 0}</div>
  //     }
  //   ]
  // },
];
