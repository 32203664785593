export enum FilterTypes {
  CATEGORY = 'category',
  MARKET = 'market',
  SEGMENT = 'segment'
}

export enum FactFilterTypes{
  CELL = 'cell',
  COUNTRYNAME = 'country',
  NIELSENMARKETNAME = 'market'
}

export enum CellControlFilterTypes {
  PROVIDER = 'provider',
  COUNTRY = 'country',
  CATEGORY = 'category'
}

export enum CriticalAttributeFiltersTypes{
  GLOBALDB = 'globalDB',
  LOCALDB = 'localDB',
  MARKET = 'marketName'
}

export enum CellControlTypes {
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated'
}
