import React, { useEffect, useState ,useMemo,useRef,useCallback} from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import { Button, Flex, Tooltip, ActionIcon, Loader,Text } from '@mantine/core';
import { MRT_TableOptions, MRT_ColumnFiltersState, MRT_SortingState, MRT_Virtualizer } from 'mantine-react-table';
import { factMetadataColumn } from './column';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { IconCheck, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import MantineTable from 'shared/components/MantineTable';
import { FactMeta } from 'models/FactMeta/factMeta.model';
import FactMetadataService from 'services/AdminService/FactMetadata/FactMetadata.service';

function FactMetaData() {
  const tableColumns: any = factMetadataColumn;
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [tableElement, setTableElement] = useState<any>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  const [tempData, setTempData] = useState<any>([]);
  const [modifiedRecords, setModifiedRecords] = useState({ created: [], edited: [], deleted: [] });

//Api calls
  const { fetchFactMetaRecords, createFactRecord, deleteFactRecords, updateFactRecord } =
    FactMetadataService();
  const { data, isError, isLoading, refetch,fetchNextPage, isFetching, isPreviousData } = fetchFactMetaRecords({ sorting, columnFilters });

  const { createIsLoading, createMutate } = createFactRecord();

  const { updateIsLoading, updateMutate } = updateFactRecord();

  const { deleteIsLoading, deleteMutate } = deleteFactRecords();

  const factMetaData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result) ?? []
  },
    [data],
  );

  useEffect(() => {
    setTempData(factMetaData ? factMetaData : []);
  }, [data]);

  const totalDBRowCount = data?.pages?.[0]?.count ?? 0;
  const totalFetched = factMetaData.length;

  const handleClear = () => {
    setModifiedRecords({
      created: [],
      edited: [],
      deleted: []
    });
    setTempData(data ? data : []);
  };

  const handleSaveChanges = () => {
    modifiedRecords.created.length
      ? createMutate(modifiedRecords.created, {
          onSuccess: async (data) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Success',
                  message: 'Records Creation successfull',
                  color: 'green',
                  icon: <IconCheck />,
                  autoClose: 2000
                })}
              </>
            );
          },
          onError: async (data: any) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Failure',
                  message: 'Record Creation Unsuccessfull' + data.error.message,
                  color: 'green',
                  icon: <IconX />,
                  autoClose: 2000
                })}
              </>
            );
          }
        })
      : null;

    modifiedRecords.edited.length
      ? updateMutate(modifiedRecords.edited, {
          onSuccess: async (data) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Success',
                  message: 'Records Updation successfull',
                  color: 'green',
                  icon: <IconCheck />,
                  autoClose: 2000
                })}
              </>
            );
          },
          onError: async (data: any) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Failure',
                  message: 'Updation Unsuccessfull ' + data.error.message,
                  color: 'green',
                  icon: <IconX />,
                  autoClose: 2000
                })}
              </>
            );
          }
        })
      : null;

    modifiedRecords.deleted.length
      ? deleteMutate(modifiedRecords.deleted, {
          onSuccess: async (data) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Success',
                  message: 'Deletion successfull',
                  color: 'green',
                  icon: <IconCheck />,
                  autoClose: 2000
                })}
              </>
            );
          },
          onError: async (data: any) => {
            refetch();
            return (
              <>
                {notifications.show({
                  title: 'Failure',
                  message: 'Deletion Unsuccessfull ' + data.error.message,
                  color: 'green',
                  icon: <IconX />,
                  autoClose: 2000
                })}
              </>
            );
          }
        })
      : null;

    setModifiedRecords({
      created: [],
      edited: [],
      deleted: []
    });
  };

  const handleSave: MRT_TableOptions<FactMeta>['onEditingRowSave'] = async ({ values, table, row }) => {
    const editedRecords = [...modifiedRecords.edited, values];
    const editedIndex = row.index;
    setModifiedRecords((prev: any) => {
      const data = prev;
      data.edited = editedRecords;
      return data;
    });

    setTempData((prevTempData: any) => {
      const updatedTempData = prevTempData.map((record: any, index: any) => (index === editedIndex ? values : record));
      return updatedTempData;
    });
    table.setEditingRow(null);
  };

  const handleCreate: MRT_TableOptions<FactMeta>['onCreatingRowSave'] = ({ values, exitCreatingMode }) => {
    const { Id, ...rest } = values;
    const createdRecords = [...modifiedRecords.created, rest];
    setModifiedRecords((prev: any) => {
      const data = prev;
      data.created = createdRecords;
      return data;
    });
    setTempData([values, ...tempData]);
    exitCreatingMode();
  };

  


  const fetchMoreOnBottomReached = useCallback((containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      setTableElement(containerRefElement)

      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !isFetching &&
        totalFetched < totalDBRowCount
      ) {

        fetchNextPage();
      }
    }
  },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, columnFilters]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [tempData]);


  return (
    <DashboardContainer>
      <h3>Fact</h3>
      <MantineTable
        data={tempData}
        columns={tableColumns}
        customActions={true}
        columnPinning={{ left: ['mrt-row-actions'] }}
        enablePinning={true}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableStickyHeader={true}
        enablePagination={false}
        enableColumnActions={false}
        enableGlobalFilter={false}
        manualFiltering={true}
        manualSorting={true}
        isFetching={isFetching}
        fetchMoreOnBottomReached={(val: any) => fetchMoreOnBottomReached(val)}
        isPreviousData={isPreviousData}
        onColumnFiltersChange={setColumnFilters}
        onSortingChange={setSorting}
        columnFilters={columnFilters}
        isLoading={isLoading }
        sorting={sorting}
        mantineToolbarAlertBannerProps={
          isError 
            ? {
                color: 'red',
                children: 'Error loading data'
              }
            : undefined
        }
        customActionButton={({ row, table }: any) => (
          <Flex gap='md'>
            <Tooltip label='Edit' position='right'>
              <ActionIcon onClick={() => table.setEditingRow(row)}>
                <IconEdit />
              </ActionIcon>
            </Tooltip>
            <Tooltip label='Delete' position='left'>
              <ActionIcon
                color='red'
                onClick={() => {
                  const deleteRecords = [...modifiedRecords.deleted, row.original.Id];
                  setModifiedRecords((prev: any) => {
                    const data = prev;
                    data.deleted = deleteRecords;
                    return data;
                  });
                  tempData.splice(row.index, 1);
                  setTempData([...tempData]);
                }}
              >
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </Flex>
        )}
        customRecordsButton={({ table }: any) => (
          <div style={{ display: 'flex', gap: '8px' }}>
            {!createIsLoading && !updateIsLoading && !deleteIsLoading ? (
              <>
                <Button
                  onClick={() => {
                    table.setCreatingRow(true);
                    tableElement.scrollTop = 0;
                  }}
                >
                  New Metadata
                </Button>
                <Button
                  color='green'
                  disabled={
                    modifiedRecords.created.length === 0 && modifiedRecords.deleted.length === 0 && modifiedRecords.edited.length === 0
                  }
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
                <Button
                  color='red'
                  disabled={
                    modifiedRecords.created.length === 0 && modifiedRecords.deleted.length === 0 && modifiedRecords.edited.length === 0
                  }
                  onClick={handleClear}
                >
                  Clear Changes
                </Button>
              </>
            ) : (
              <Loader size={38} />
            )}
          </div>
        )}
        onEditingRowSave={handleSave}
        onCreatingRowSave={handleCreate}
        renderBottomToolbarCustomActions={() => {
          return (
            <div className='bottom-Action'>
              <p>Total Records : {totalDBRowCount}</p>
              <Text className='bottom-text'>
                Showing {totalFetched} of {totalDBRowCount} total rows.
              </Text>
            </div>
          );
        }}
        enableRowActions={true}
        enableEditing={true}
      />
    </DashboardContainer>
  );
}

//   const [filters, setFilters] = useState<FactFilters>({ limit: 10, offset: 0 });

//   const [showConfirm, setShowConfirm] = useState<boolean>(false);

//   const [showToast, setShowToast] = useState<boolean>(false);

//   const [submitResponse, setSubmitResponse] = useState<string>('');

//   const [deletedRecords, setDeletedRecords] = useState<FactMeta[]>([]);

//   const { ADD, EDIT, DELETE } = TableAction;

//   useEffect(() => {
//     fetchFactMetaRecords(filters);
//     fetchFactRecordsPaginationData(filters)
//   }, [filters]);

//   const setTableAction = (actionType: TableAction) => (event: MouseEvent<HTMLButtonElement>) => {
//     if (action) event.preventDefault();
//     else {
//       setAction(actionType);
//       if (actionType === ADD) {
//         const newRecord = new FactMeta();
//         setFactMetaRecords((prev) => [newRecord, ...prev]);
//         setTempMetaData((prev) => [newRecord, ...prev]);
//       }
//     }
//   };

//   const handlePagination = (pageNumber: number, recordsPerPage?: number) => {

//     if (deletedRecords.length) openConfirmationAlert();
//     else {
//       const currentPageSize = factPagination?.pageSize ?? 0;
//       const limit = recordsPerPage ?? currentPageSize;
//       const offset = (pageNumber - 1) * limit;
//       setFilters((prev) => ({
//         ...prev,
//         limit,
//         offset
//       }));
//     }
//   };

//   const enableSubmit = () => {
//     switch (action) {
//       case ADD: {
//         const values = Object.values(factMetaRecords[0]);
//         return values.every((value) => !value);
//       }
//       case EDIT:
//         return JSON.stringify(factMetaRecords) === JSON.stringify(factTempData);

//       case DELETE:
//         return !deletedRecords.length;
//     }
//   };

//   const handleCancel = () => {
//     switch (action) {
//       case ADD: {
//         onCancelNewRecord()();
//         break;
//       }
//       case DELETE: {
//         if (deletedRecords.length) {
//           deletedRecords.forEach((record) => {
//             setFactMetaRecords((prevData) => {
//               const newData = [...prevData];
//               newData.splice(record.originalIndex ?? 0, 0, record);
//               return newData;
//             });
//             setTempMetaData((prevData) => {
//               const newData = [...prevData];
//               newData.splice(record.originalIndex ?? 0, 0, record);
//               return newData;
//             });
//           });
//           setDeletedRecords([]);
//         }
//         break;
//       }
//       case EDIT:
//       default: {
//         const temp = deserialize(FactMeta, factTempData) as FactMeta[];
//         setFactMetaRecords(temp);
//       }
//     }
//     setAction(undefined);
//   };

//   //Cancel for new record addition
//   const onCancelNewRecord = (index?: number) => () => {
//     setFactMetaRecords((prevData) => {
//       const newData = [...prevData];
//       newData.splice(index ?? 0, 1);
//       return newData;
//     });
//     setTempMetaData((prevData) => {
//       const newData = [...prevData];
//       newData.splice(index ?? 0, 1);
//       return newData;
//     });
//     setAction(undefined);
//   };

//   const handleSaveAsDraft = () => setAction(undefined);

//   const handleSubmit = async () => {
//     switch (action) {
//       case ADD: {
//         const newRecord = factMetaRecords[0];
//         delete newRecord.isEdited;
//         delete newRecord.originalIndex;
//         delete newRecord.toastVisible;
//         const response = await createFactRecord(newRecord);
//         if (response) {
//           setSubmitResponse(response);
//           setShowToast(true);
//           setAction(undefined);
//         }
//         break;
//       }
//       case EDIT: {
//         let updatedRecords: FactMeta[] = [];
//         factMetaRecords.forEach((record) => {
//           if (record.isEdited) {
//             const editedRecord = record;
//             delete editedRecord.isEdited;
//             delete editedRecord.originalIndex;
//             delete editedRecord.toastVisible;
//             updatedRecords.push(editedRecord);
//           }
//         });
//         const response = await updateFactRecord(updatedRecords, filters);
//         if (response) {
//           setSubmitResponse(response.message);
//           setShowToast(true);
//           setAction(undefined);
//         }
//         break;
//       }
//       case DELETE: {
//         if (deletedRecords.length) {
//           const ids = deletedRecords.map((record) => record.Id);
//           const response = await deleteFactRecords(ids as number[]);
//           if (response) {
//             setSubmitResponse(response);
//             setShowToast(true);
//             setAction(undefined);
//           }
//         }
//         break;
//       }
//     }
//     setShowConfirm(false);
//   }

//   //Delete a record
//   const handleRecordDelete = (index: number) => () => {
//     const deletedRecord = factMetaRecords[index];
//     setFactMetaRecords((prevData) => prevData.filter((item) => item.Id !== deletedRecord.Id));
//     setTempMetaData((prevData) => prevData.filter((item) => item.Id !== deletedRecord.Id));
//     setDeletedRecords((prevDeletedItems) => [...prevDeletedItems, deletedRecord]);
//   };

//   //Undo for delete
//   const handleUndo = (index: number) => () => {
//     const undoRecord = deletedRecords[index];

//     setDeletedRecords((prevDeletedItems) => prevDeletedItems.filter((item) => item.Id !== undoRecord.Id));

//     setFactMetaRecords((prevData) => {
//       const newData = [...prevData];
//       newData.splice(undoRecord.originalIndex ?? 0, 0, undoRecord);
//       return newData;
//     });
//     setTempMetaData((prevData) => {
//       const newData = [...prevData];
//       newData.splice(undoRecord.originalIndex ?? 0, 0, undoRecord);
//       return newData;
//     });
//   };

//   const handleDeletedRecordsToastChange = (index: number) => () =>
//     setDeletedRecords((prev) => {
//       const records = [...prev];
//       records[index].toastVisible = false;
//       return records;
//     });

//   const getTableColumns = () => {
//     switch (action) {
//       case ADD:
//         return factMetadataColumn(onCancelNewRecord);
//       case DELETE:
//         return factMetadataColumn(handleRecordDelete);
//       case EDIT:
//       default:
//         return [...factMetadataColumn(handleRecordDelete)].slice(0, -1);
//     }
//   };

//   const toggleToast = () => setShowToast(!showToast);

//   const openConfirmationAlert = () => setShowConfirm(true);

//   const handleConfirmClose = () => setShowConfirm(false);

//   return (
//     <Fragment>
//       <DashboardContainer>
//         <Row>
//           <Col xs={11} className='page-title-text'>
//             <h1 className='page-title'>Fact</h1>
//           </Col>
//         </Row>
//         <ToastComponent className='sml-pcat-toast' showState={showToast} toggleShow={toggleToast} message={submitResponse} />
//         <ToastContainer position='top-end' className='toast-component'>
//           {deletedRecords.map((record, index) => (
//             <Toast
//               key={record.Id}
//               className='toast-component-toast'
//               show={!!record.toastVisible}
//               onClose={handleDeletedRecordsToastChange(index)}
//               delay={5000}
//               autohide
//             >
//               <Toast.Header className='toast-component-header' closeVariant='white'>
//                 <Row>
//                   <Col className='toast-status' xl={8}>
//                     <strong>A row was deleted successfully</strong>
//                   </Col>
//                   <Col>
//                     <button className='toast-btn' onClick={handleUndo(index)}>
//                       UNDO
//                     </button>
//                   </Col>
//                 </Row>
//               </Toast.Header>
//             </Toast>
//           ))}
//         </ToastContainer>
//         <Filters filters={filters} setFilters={setFilters} isDeletedRecords={!!deletedRecords.length} openAlert={openConfirmationAlert} />
//         <FactTableActions action={action} setTableAction={setTableAction} />
//         <div className='table-container table-container-scroll sml-pcat-table'>
//           <Table
//             columns={getTableColumns()}
//             data={factMetaRecords}
//             actionType={action}
//             setData={setFactMetaRecords}
//             initialData={factTempData}
//             primaryKey='Id'
//           />
//         </div>
//         <EDPagination
//           loading={paginationLoading}
//           page={factPagination?.page ?? 0}
//           pageSize={factPagination?.pageSize ?? 0}
//           totalCount={factPagination?.totalCount ?? 0}
//           onPageItemClick={handlePagination}
//         />
//         <Row className='table-bottom-wrapper'>
//           <Col xl={2} xxl={2}>
//             <CustomButton SecondaryPressed disabled={enableSubmit() || action !== EDIT} skeletonLoading={loading} onClick={handleSaveAsDraft}>
//               Save as Draft
//             </CustomButton>
//           </Col>
//           <Col xl={3} xxl={3} className='table-bottom-wrapper__action-btns'>
//             <CustomButton disabled={!action && enableSubmit()} secondary skeletonLoading={loading} onClick={openConfirmationAlert}>
//               Cancel
//             </CustomButton>
//             <CustomButton skeletonLoading={loading} onClick={handleSubmit} disabled={submitting || enableSubmit() || !action}>
//               {submitting ? 'Submitting' : 'Submit'}
//             </CustomButton>
//           </Col>
//         </Row>
//         <ConfirmationModal
//           show={showConfirm}
//           onHide={handleConfirmClose}
//           onCancel={handleCancel}
//           onConfirm={handleSubmit}
//           desc='Your work is not saved. Do you want to save it ?'
//           title='Attention'
//           loading={submitting}
//         />
//       </DashboardContainer>
//     </Fragment>
//   );
// }

export default FactMetaData;
