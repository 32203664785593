import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NavComponent from '../Nav-component/Nav-component';
import SidebarComponent from '../Sidebar-component';
import './DashboardContainer.scss';

interface Props {
  children?: any,
  className?: string
}


const DashboardContainer: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    
      <div className='dashboard-container '>
        <SidebarComponent />
        <div className='page-container'>
          <NavComponent />
          <div className='page-content'>
            {children}
          </div>
        </div>
      </div>
    
  );
};

export default DashboardContainer;
