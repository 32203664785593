import React from 'react';

export const RMSMarketMappedColumn = [
  {
    header: 'Extrenal',
    accessorKey: 'External',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'External Product Description',
        accessorKey: 'Long',
        size: 260
      },
      {
        header: 'External Short Description',
        accessorKey: 'Short',
        size: 260
      },
      // {
      //   header: 'External Tag',
      //   accessorKey: 'Tag',
      //   size: 160
      // },
      // {
      //   header: 'External Hierarchy Number',
      //   accessorKey: 'HierNum',
      //   size: 260
      // },
      // {
      //   header: 'External Hierarchy Name',
      //   accessorKey: 'HierName',
      //   size: 260
      // },
      // {
      //   header: 'External Hierarchy Level',
      //   accessorKey: 'HierLevelNum',
      //   size: 260
      // },
      // {
      //   header: 'External Hierarchy Level Name',
      //   accessorKey: 'HierLevelName',
      //   size: 260
      // },
      // {
      //   header: 'External Parent TAG',
      //   accessorKey: 'ParentTag',
      //   size: 220
      // },
      // {
      //   header: 'Unique TAG',
      //   accessorKey: 'UniqueTag',
      //   size: 150
      // }
    ]
  },
  {
    header: 'Internal',
    accessorKey: 'internal',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Cell',
        accessorKey: 'Cell',
        size: 100
      },
      {
        header: 'Country',
        accessorKey: 'Country',
        size: 100
      },
      // {
      //   header: 'Category',
      //   accessorKey: 'Category',
      //   size: 100
      // },
      // {
      //   header: 'Channel',
      //   accessorKey: 'Channel',
      //   size: 100
      // },
      // {
      //   header: 'Total Market',
      //   accessorKey: 'TotalMarket',
      //   size: 170
      // },
      // {
      //   header: 'Channel Confidence',
      //   accessorKey: 'ChannelConfidence',
      //   size: 180
      // },
      // {
      //   header: 'Total Confidence',
      //   accessorKey: 'TotalConfidence',
      //   size: 180
      // }
    ]
  }
];
