import axiosInstance from 'interceptor/axiosInstance';
import { Pagination } from 'models/Pagination/pagination.model';
import { useState } from 'react';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { deserialize } from 'serializr';
import { useMutation, useQuery,useInfiniteQuery } from '@tanstack/react-query';
import { MRT_ColumnFiltersState, MRT_SortingState } from 'mantine-react-table';
import { MarketMetadata } from 'models/MarketMetadata/marketMetadata.model';

interface Params {
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
}

interface ParamsObject {
  offset?: Number;
  limit?: Number;
  filters?: string;
  sorting?: string;
}

const MarketMetadataService = () => {

  const fetchMarketMetadataRecords = ({ columnFilters, sorting }: Params) => {
    return useInfiniteQuery({
      queryKey: ['market-metadata', columnFilters, sorting],
      queryFn: async ({ pageParam = 0 }) => {
          const paramsObj: ParamsObject = {
              offset: pageParam * 30,
              limit: 30,
              filters: JSON.stringify(columnFilters ?? []),
              sorting: JSON.stringify(sorting ?? [])
          }
          const response = await axiosInstance.get(ApiRoutes.MARKET_META, { params: paramsObj })
          return response.data
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
  })
  };
 


  const createMarketMetadataRecords = () => {
    const { isLoading: createIsLoading, mutateAsync: createMutate } = useMutation({
      mutationFn: async (records: MarketMetadata[]) => {
        const response = await axiosInstance.post(ApiRoutes.MARKET_META, { records });
        return response.data;
      }
    });
    return {
      createIsLoading,
      createMutate
    };
  };

  const updateMarketMetadataRecords = () => {
    const { isLoading: updateIsLoading, mutateAsync: updateMutate } = useMutation({
      mutationFn: async (records: MarketMetadata[]) => {
        const response = await axiosInstance.put(ApiRoutes.MARKET_META, { records });
        return response.data;
      }
    });
    return {
      updateIsLoading,
      updateMutate
    };
  };

  const deleteMarketMetadataRecords = () => {
    const { isLoading: deleteIsLoading, mutateAsync: deleteMutate } = useMutation({
      mutationFn: async (ids: number[]) => {
        const response = await axiosInstance.delete(ApiRoutes.MARKET_META, {
          data: {
            ids
          }
        });
        return response.data;
      }
    });

    return {
      deleteIsLoading,
      deleteMutate
    };
  };

  return {
    fetchMarketMetadataRecords,
    createMarketMetadataRecords,
    updateMarketMetadataRecords,
    deleteMarketMetadataRecords
  };
};
export default MarketMetadataService;
