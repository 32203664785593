export const RMSProductMappedRemmaping = [
  {
    key: 'division-name',
    attributeName: 'Division Name',
    internal: 'Divisionname',
    external: null,
    apiEndPoint: 'division-name',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'category-name',
    attributeName: 'Category Name',
    internal: 'Categoryname',
    external: null,
    apiEndPoint: 'category-name',
    dependsOn: ['Divisionname'],
    apiDependsOn: ['Divisionname'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'company',
    attributeName: 'Company Name',
    external: 'Company',
    internal: null,
    apiEndPoint: null,
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  // {
  //   key: 'hier-name',
  //   attributeName: 'Hierarchy Name',
  //   external: 'Hiername',
  //   internal: null,
  //   apiEndPoint: null,
  //   dependsOn: [],
  //   apiDependsOn: [],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'hier-level-name',
  //   attributeName: 'Hierarchy Level Name',
  //   external: 'Hierlevelname',
  //   internal: null,
  //   apiEndPoint: null,
  //   dependsOn: [],
  //   apiDependsOn: [],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'market-name',
  //   attributeName: 'Market Name',
  //   internal: 'Marketname',
  //   external: null,
  //   apiEndPoint: 'market-name',
  //   dependsOn: ['Categoryname', 'Divisionname'],
  //   apiDependsOn: ['Categoryname', 'Divisionname'],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  {
    key: 'corporate-brand-name',
    attributeName: 'Corporate Brand Name',
    internal: 'Corporatebrandname',
    external: 'Brand',
    apiEndPoint: 'corporate-brand-name',
    dependsOn: ['Marketname', 'Categoryname', 'Divisionname'],
    apiDependsOn: ['Marketname', 'Categoryname', 'Divisionname'],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  // {
  //   key: 'sector-name',
  //   attributeName: 'Sector Name',
  //   internal: 'Sectorname',
  //   external: null,
  //   apiEndPoint: 'sector-name',
  //   dependsOn: ['Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
  //   apiDependsOn: ['Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'segment-name',
  //   attributeName: 'Segment Name',
  //   internal: 'Segmentname',
  //   external: null,
  //   apiEndPoint: 'segment-name',
  //   dependsOn: ['Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
  //   apiDependsOn: ['Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'product-form-name',
  //   attributeName: 'Product Form Name',
  //   internal: 'Productformname',
  //   external: null,
  //   apiEndPoint: 'product-form-name',
  //   dependsOn: ['Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
  //   apiDependsOn: ['Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'form-name',
  //   attributeName: 'Form Name',
  //   internal: 'Formname',
  //   external: null,
  //   apiEndPoint: 'form-name',
  //   dependsOn: ['Productformname', 'Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
  //   apiDependsOn: ['Productformname', 'Segmentname', 'Sectorname', 'Corporatebrandname', 'Marketname', 'Categoryname', 'Divisionname'],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'sub-form-name',
  //   attributeName: 'Subform Name',
  //   internal: 'Subformname',
  //   external: null,
  //   apiEndPoint: 'sub-form-name',
  //   dependsOn: [
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'sub-product-form-variant-name',
  //   attributeName: 'Sub Product Form Variant Name',
  //   internal: 'Spfvname',
  //   external: null,
  //   apiEndPoint: 'sub-product-form-variant-name',
  //   dependsOn: [
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'product-pack-form-name',
  //   attributeName: 'Product Pack Form Name',
  //   internal: 'Productpackformname',
  //   external: null,
  //   apiEndPoint: 'product-pack-form-name',
  //   dependsOn: [
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'product-pack-size-name',
  //   attributeName: 'Product Packsize Name',
  //   internal: 'Productpacksizename',
  //   external: null,
  //   apiEndPoint: 'product-pack-size-name',
  //   dependsOn: [
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'product-variant-name',
  //   attributeName: 'Product Variant Name',
  //   internal: 'Productvariantname',
  //   external: null,
  //   apiEndPoint: 'product-variant-name',
  //   dependsOn: [
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'product-code-name',
  //   attributeName: 'Product Code Name',
  //   internal: 'Productcodename',
  //   external: null,
  //   apiEndPoint: 'product-code-name',
  //   dependsOn: [
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'product-name',
  //   attributeName: 'Product Description',
  //   external: 'Externaldesc',
  //   internal: 'Productname',
  //   apiEndPoint: 'product-name',
  //   dependsOn: [
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-format',
  //   attributeName: 'Format',
  //   internal: 'Maxattriformat',
  //   external: null,
  //   apiEndPoint: 'max-attri-format',
  //   dependsOn: [
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-if-rinse-off',
  //   attributeName: 'IF_RINSE_OFF',
  //   internal: 'Maxattriifrinseoff',
  //   external: null,
  //   apiEndPoint: 'max-attri-if-rinse-off',
  //   dependsOn: [
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-pack-type',
  //   attributeName: 'PACK_TYPE',
  //   internal: 'Maxattripacktype',
  //   external: null,
  //   apiEndPoint: 'max-attri-pack-type',
  //   dependsOn: [
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-benefit-claim',
  //   attributeName: 'BENEFIT_CLAIM',
  //   internal: 'Maxattribenefitclaim',
  //   external: null,
  //   apiEndPoint: 'max-attri-benefit-claim',
  //   dependsOn: [
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-target-use',
  //   attributeName: 'TARGET_USE',
  //   internal: 'Maxattritargetuse',
  //   external: null,
  //   apiEndPoint: 'max-attri-target-use',
  //   dependsOn: [
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-laundry-variants',
  //   attributeName: 'LAUNDRY_VARIANTS',
  //   internal: 'Maxattrilaundryvariants',
  //   external: null,
  //   apiEndPoint: 'max-attri-laundry-variants',
  //   dependsOn: [
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-if-concentrate',
  //   attributeName: 'IF_CONCENTRATE',
  //   internal: 'Maxattriifconcentrate',
  //   external: null,
  //   apiEndPoint: 'max-attri-if-concentrate',
  //   dependsOn: [
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-gender',
  //   attributeName: 'GENDER',
  //   internal: 'Maxattrigender',
  //   external: null,
  //   apiEndPoint: 'max-attri-gender',
  //   dependsOn: [
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-if-high-suds',
  //   attributeName: 'IF_HIGH_SUDS',
  //   internal: 'Maxattriifhighsuds',
  //   external: null,
  //   apiEndPoint: 'max-attri-if-high-suds',
  //   dependsOn: [
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-if-antiperspirant',
  //   attributeName: 'IF_ANTIPERSPIRANT',
  //   internal: 'Maxattriifantiperspirant',
  //   external: null,
  //   apiEndPoint: 'max-attri-if-antiperspirant',
  //   dependsOn: [
  //     'Maxattriifhighsuds',
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattriifhighsuds',
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-formation',
  //   attributeName: 'FORMATION',
  //   internal: 'Maxattriformation',
  //   external: null,
  //   apiEndPoint: 'max-attri-formation',
  //   dependsOn: [
  //     'Maxattriifantiperspirant',
  //     'Maxattriifhighsuds',
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattriifantiperspirant',
  //     'Maxattriifhighsuds',
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-lifestage',
  //   attributeName: 'LIFESTAGE',
  //   internal: 'Maxattrilifestage',
  //   external: null,
  //   apiEndPoint: 'max-attri-lifestage',
  //   dependsOn: [
  //     'Maxattriformation',
  //     'Maxattriifantiperspirant',
  //     'Maxattriifhighsuds',
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattriformation',
  //     'Maxattriifantiperspirant',
  //     'Maxattriifhighsuds',
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // },
  // {
  //   key: 'max-attri-fat-content',
  //   attributeName: 'FAT_CONTENT',
  //   internal: 'Maxattrifatcontent',
  //   external: null,
  //   apiEndPoint: 'max-attri-fat-content',
  //   dependsOn: [
  //     'Maxattrilifestage',
  //     'Maxattriformation',
  //     'Maxattriifantiperspirant',
  //     'Maxattriifhighsuds',
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   apiDependsOn: [
  //     'Maxattrilifestage',
  //     'Maxattriformation',
  //     'Maxattriifantiperspirant',
  //     'Maxattriifhighsuds',
  //     'Maxattrigender',
  //     'Maxattriifconcentrate',
  //     'Maxattrilaundryvariants',
  //     'Maxattritargetuse',
  //     'Maxattribenefitclaim',
  //     'Maxattripacktype',
  //     'Maxattriifrinseoff',
  //     'Maxattriformat',
  //     'Productname',
  //     'Productcodename',
  //     'Productvariantname',
  //     'Productpacksizename',
  //     'Productpackformname',
  //     'Spfvname',
  //     'Subformname',
  //     'Formname',
  //     'Productformname',
  //     'Segmentname',
  //     'Sectorname',
  //     'Corporatebrandname',
  //     'Marketname',
  //     'Categoryname',
  //     'Divisionname'
  //   ],
  //   value: null,
  //   isDisabled: false,
  //   isLoading: false,
  //   option: [],
  //   showDropdown: true
  // }
];
