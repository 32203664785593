
import { serializable, alias, object, list, primitive } from 'serializr';

export class HarmonisationStatus {

	@serializable(alias('Fail', primitive()))
	fail: number = 0;

	@serializable(alias('In Progress', primitive()))
	inProgress: number = 0;

	@serializable(alias('Success', primitive()))
	success: number = 0;

	@serializable(alias('provider_name', primitive()))
	providerName: string = '';

}