export const MarketMappedRemapping = [
  {
    key: 'country',
    attributeName: 'Country',
    external: 'Country',
    internal: null,
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'country',
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'category',
    attributeName: 'Category',
    external: 'Category',
    internal: null,
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'category',
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'market-description',
    attributeName: ' Market Description',
    external: 'Long',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'market-short-description',
    attributeName: ' Market Short Description',
    external: 'Short',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'hier-name',
    attributeName: 'Hierarchy Name',
    external: 'HierName',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'hier-level-name',
    attributeName: 'Hierarchy Level Name',
    external: 'HierLevelName',
    internal: null,
    apiEndPoint: '',
    dependsOn: [],
    apiDependsOn: [],
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'channel',
    attributeName: 'Channel',
    external: null,
    internal: 'Channel',
    dependsOn: [],
    apiDependsOn: [],
    apiEndPoint: 'channel',
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  },
  {
    key: 'total-market',
    attributeName: 'Total Market',
    external: null,
    internal: 'TotalMarket',
    dependsOn: ['Channel'],
    apiDependsOn: [],
    apiEndPoint: 'total-market',
    value: null,
    isDisabled: false,
    isLoading: false,
    option: [],
    showDropdown: true
  }
];
