import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import SMFilter from './SMFilters/SMFilters';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import SegmentedTab from 'shared/components/SegmentedTab';
import { TabData } from './constants/index';
import SMTable from './SMTable/SMTable';
import MantineBreadcrumbs from 'shared/components/MantineBreadcrumbs';
import './summary.scss';
import dayjs from 'dayjs';

const SmartmappingSummary = () => {
  const navigate = useNavigate();
  const { dimension } = useParams();

  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (!dimension) navigate(`/checks/intelligent-mapping/product`);
  }, [dimension]);

  const handleNavigation = (value: string) => {
    if (value) {
      navigate(`/checks/intelligent-mapping/${value}`);
    }
  };

  const handleFilterChange = (value: any, date: any) => {
    let result = { ...value };
    if (date.length && date[0] && date[1]) {
      result['startDate'] = dayjs(date[0]).format('YYYY-MM-DD');
      result['endDate'] = dayjs(date[1]).format('YYYY-MM-DD');
    }

    setFilter(result);
  };

  return (
    <DashboardContainer>
      <div className='validation-summary-header'>
        <p className='page-title'>Intelligent Mapping</p>
        <MantineBreadcrumbs />
        <div className='segmented-tab'>
          <SegmentedTab data={TabData} value={dimension} onChange={handleNavigation} />
        </div>
        <SMFilter handleFilterChange={handleFilterChange} />
        <SMTable filter={filter} />
      </div>
    </DashboardContainer>
  );
};

export default SmartmappingSummary;
