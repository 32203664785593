import React, { ChangeEvent } from 'react';
import { FormCheck, Spinner } from 'react-bootstrap';
import { CellControlTypes } from 'enums/filterTypes';
import clsx from 'clsx';

export const getCellControlTableColumns = (
  handleSwitchChange: (index: number, id?: number) => (event: ChangeEvent<HTMLInputElement>) => void,
  toggleMappedCheckStatus: (id?: number) => void,
) => {
  return [
    {
      id: 'checkboxes',
      accessorKey: 'isSelected',
      maxSize: 80,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }: any) => {
        return (
          <input
            type='checkbox'
            disabled={!row.original.IsActive}
            className='checkbox'
            checked={renderedCellValue}
            onChange={() => toggleMappedCheckStatus(row?.original?.Id)}
          />
        );
      },

    },
    { header: 'Data Provider', accessorKey: 'DataProvider', minWidth: 80, enableSorting: false, },

    { header: 'Country', accessorKey: 'Country', minWidth: 100, },

    { header: 'Category', accessorKey: 'Category', minWidth: 100, },

    { header: 'Format', accessorKey: 'Format', minSize: 100 },

    { header: 'File Name', accessorKey: 'Filename', size: 400 },

    {
      header: 'Status',
      accessorKey: 'Status',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: any) => (
        <div className='cell-control-switch'>
          {cell.row.original.loading ? (
            <Spinner animation='border' size='sm' />
          ) : (
            <p className={clsx('cell-status', cell.row.original.IsActive ? 'cell-activated' : 'cell-deactivated')}>
              {cell.row.original.IsActive ? CellControlTypes.ACTIVATED : CellControlTypes.DEACTIVATED}
            </p>
          )}
        </div>
      )
    },

    {
      header: 'Action',
      accessorKey: 'IsActive',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: any) => (
        <div className='cell-control-switch'>
          {cell.row.original.loading ? (
            <Spinner animation='border' size='sm' />
          ) : (
            <FormCheck
              type='switch'
              checked={!!cell.row.original.IsActive}
              onChange={handleSwitchChange(cell.row.index, cell.row.original.Id)}
            />
          )}
        </div>
      )
    }
  ];
};
