import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import { getCellControlTableColumns } from './columns';
import './cellControl.scss';
import MantineTable from 'shared/components/MantineTable';
import CustomButton from 'shared/components/Button-component/Button';
import { Text } from '@mantine/core';
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_Virtualizer,
} from 'mantine-react-table';
import { Box } from '@mantine/core';
import CellControlServices from 'services/AdminService/CellControlService/cellcontrol.service';
import { IconCheck, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

export interface CellControlFilters {
  provider?: string;
  country?: string;
  category?: string;
  limit?: number;
  offset?: number;
  activated?: boolean;
  deactivated?: boolean;
}
const CellControl = () => {

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [cellStatus, setCellStatus] = useState({
    activated: true,
    deactivated: true
  });

  const [cellControlData, setCellControlData] = useState<any[]>([])
  const [toggleAllStatus, setToggleAllStatus] = useState<boolean>(false);

  const {
    cellControlRecords,
    updateCellControlRecords,
    fetchcellControlStatus,
  } = CellControlServices()

  const { data, isLoading: cellDataIsLoading, refetch, isFetching, fetchNextPage } = cellControlRecords({ columnFilters, sorting, cellStatus })
  const { data: cellControlStatus, isLoading, refetch: statusReftech } = fetchcellControlStatus({ columnFilters, sorting })


  const cellData = useMemo(() => {
    return data?.pages.flatMap((page) => page.result) ?? []
  },
    [data],
  );


  useEffect(() => {
    setCellControlData(cellData)
  }, [data])

  const handleCellActivationChange = (index: number, id?: number) => async (event: ChangeEvent<HTMLInputElement>) => {
    const updatedData = [...cellControlData];
    updatedData[index]['loading'] = true;
    updatedData[index]['IsActive'] = event.target.checked ? 1 : 0;
    setCellControlData(updatedData);
    const response = id && (await updateCellControlRecords(event.target.checked ? 1 : 0, [id]));

    if (response) {
      refetch()
      statusReftech()

      return (
        <>
          {response ?
            notifications.show({
              title: 'Success',
              message: "Cell Updated Successfully",
              color: 'green',
              icon: <IconCheck />,
              autoClose: 2000
            }) : notifications.show({
              title: 'Failed',
              message: "Cell Updated Failed",
              color: 'red',
              icon: <IconX />,
              autoClose: 2000
            })
          }
        </>

      );
    }
    updatedData[index]['IsActive'] = event.target.checked ? 0 : 1;
    setCellControlData(updatedData);
  };

  const isDeactivateEnable = cellControlData?.some((item) => item.isSelected === true);

  const toggleMappedCheckStatus = (id?: number) => {
    const updatedData = cellControlData?.map((item) => {
      if (item.Id === id) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
    setToggleAllStatus(false);
    setCellControlData(updatedData);
  };

  const resetAllCheckbox = () => {
    const updatedData = cellControlData?.map((item) => {
      item.isSelected = false;
      item.loading = false;
      return item;
    });
    setToggleAllStatus(false);
    setCellControlData(updatedData);
  };



  const handleDeactivate = async () => {
    const updatedRecords = [...cellControlData];
    updatedRecords.forEach((record) => {
      if (record.isSelected) {
        record.IsActive = 0;
        record.loading = true;
      }
    });

    setCellControlData(updatedRecords);
    const selectedRecords = cellControlData.filter((record) => {
      if (record.isSelected) return record;
    });

    const ids = selectedRecords.map((record: any) => Number(record.Id));
    if (ids.length) {
      const response = await updateCellControlRecords(0, ids);
      if (response) {
        refetch()
        statusReftech()

        setToggleAllStatus(false);
        return (
          <>
            {
              notifications.show({
                title: 'Success',
                message: "Cells Deactivated Succesfully",
                color: 'green',
                icon: <IconCheck />,
                autoClose: 2000
              })
            }
          </>
        );
      }
      resetAllCheckbox();
    }

  };


  const totalDBRowCount = data?.pages?.[0]?.count ?? 0;
  const totalFetched = cellData.length;

  const handleActivate = () => {
    setCellStatus((prev: any) => ({ ...prev, activated: !prev.activated }))
    refetch()

  }

  const handleDeactivate2 = () => {
    setCellStatus((prev: any) => ({ ...prev, deactivated: !prev.deactivated }))
    refetch()
  }

  const fetchMoreOnBottomReached = useCallback((containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !isFetching &&
        totalFetched < totalDBRowCount
      ) {

        fetchNextPage();
      }
    }
  },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, columnFilters]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);



  return (
    <DashboardContainer>
      <p className='page-title'>Cell Control</p>

      <MantineTable
        data={cellData}
        columns={getCellControlTableColumns(
          handleCellActivationChange,
          toggleMappedCheckStatus
        )}
        columnPinning={
          { left: ['mrt-row-actions'] }
        }
        enablePinning={true}
        enableRowVirtualization={true}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableStickyHeader={true}
        enablePagination={false}
        enableColumnActions={false}
        enableGlobalFilter={false}
        manualPagination={true}
        onColumnFiltersChange={setColumnFilters}
        onSortingChange={setSorting}
        manualFiltering={true}
        manualSorting={true}
        customActions={true}
        columnFilters={columnFilters}
        sorting={sorting}
        isLoading={cellDataIsLoading}
        fetchMoreOnBottomReached={(val: any) => fetchMoreOnBottomReached(val)}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        tableContainerRef={tableContainerRef}
        isFetching={isFetching}
        customRecordsButton={({ table }: any) => (
          <Box>
            <div className='cell-control-table-actions'>
              <div className='cell-controls'>
                <p className='cell-controls__total'>Total cells: {cellControlStatus?.totalCells ?? ''}</p>
                <CustomButton status statusActive={cellStatus.activated} onClick={handleActivate}>{`Activated${cellControlStatus ? `(${cellControlStatus.activatedCells})` : ''
                  }`}</CustomButton>
                <CustomButton status statusActive={cellStatus.deactivated} onClick={handleDeactivate2}>{`Deactivated${cellControlStatus ? `(${cellControlStatus.deactivatedCells})` : ''
                  }`}</CustomButton>
              </div>
            </div>
          </Box>
        )}
        renderBottomToolbarCustomActions={
          () => (
            <Box className='bottom-Action'>
              <CustomButton disabled={!isDeactivateEnable} onClick={handleDeactivate}>
                Deactivate
              </CustomButton>
              <Text className='bottom-text'>
                Showing {totalFetched} of {totalDBRowCount} total rows.
              </Text>
            </Box>
          )
        }
      />
    </DashboardContainer>
  );
};

export default CellControl;
