import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React, { useEffect } from 'react';
import AppRoutes from './routes';
import { ProfileData } from 'shared/components/ProfileData';
// import { callMsGraph } from "./graph";
import './styles/_main.scss';
import SSOscreen from 'views/SSOscreen';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { useState } from 'react';
const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <>
      {/* <AuthenticatedTemplate>

      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SSOscreen />
      </UnauthenticatedTemplate> */}
      {isAuthenticated ?
         <BrowserRouter>
          <MantineProvider>
            <Notifications position='top-center' zIndex={99999} />
            <AppRoutes />
          </MantineProvider>
        </BrowserRouter>
         : <SSOscreen authentication={(value:boolean)=>setIsAuthenticated(value)}/>}
      
    </>
  );
};

export default App;
