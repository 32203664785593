import React from 'react';

export const RMSFactMappedColumn = [
  {
    header: 'External',
    accessorKey: 'description',
    enableSorting: false,
    enableColumnFilter: false,
    columns: [
      {
        header: 'External Long Description',
        accessorkey: 'long',
        size: 300,
        enableSorting: false,
      },
      {
        header: 'External Short Description',
        accessorKey: 'short',
        size: 300,
        enableSorting: false,
      },
      // {
      //   header: 'Currency',
      //   accessorKey: 'externalCurrency',
      //   maxWidth: 160,
      //   enableSorting: false,
      // },
      // {
      //   header: 'Unique TAG',
      //   accessorKey: 'externalUniqueTAG',
      //   size: 150,
      //   enableSorting: false,
      // }
    ]
  },
  {
    header: 'Internal',
    accessorKey: 'internal',
    enableColumnFilter: false,
    enableSorting: false,
    mantineTableHeadCellProps: {
      align: 'left'
    },
    columns: [
      {
        header: 'Internal Fact Description',
        accessorKey: 'Harmonizedname',
        size: 280,
        enableSorting: false,
        id: 'internalFactDescription'
      },
      {
        header: 'Internal Fact Type',
        accessorKey: 'Facttype',
        enableSorting: false,
        size: 260,
        id: 'Internal_Fact_Type'
      }
    ]
  }
];
