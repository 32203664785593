import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import searchIcon from 'assets/icons/search-icon.svg';
import './search-input-field.scss';

interface Props {
  placeholder: string;
  height: string;
  width: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  paddingLeft?: string;
  paddingRight?: string;
  value?: string;
}

const SearchInputField: React.FC<Props> = ({ placeholder, height, width, onChange, paddingLeft, paddingRight, value }: Props) => {
  const style = {
    width: width ?? '200px',
    height: height ?? '40px',
    display: 'flex',
    justifyContent: 'center',
    paddingInlineStart: paddingLeft ?? '40px',
    paddingInlineEnd: paddingRight ?? '12px'
  };

  return (
    <div>
      <InputGroup className='search-input-field'>
        <Form.Control
          type='search'
          style={style}
          placeholder={placeholder}
          aria-label='Username'
          aria-describedby='basic-addon1'
          className='search-input-field-text'
          onChange={onChange}
          defaultValue={value}
        />
        <img className='card-template-header-left-icon' alt='search icon' src={searchIcon} />
      </InputGroup>
    </div>
  );
};

export default SearchInputField;
