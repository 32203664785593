import axios from 'axios';
import { ApiRoutes } from '../routes/routeConstants/apiRoutes';
import { msalInstance, userDetailsRequest } from 'authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-common';

export const getHeaders = (): any => {
  const headers = {
    'Content-Type': 'application/json'
  };
  return headers;
};

export const addToken = async () => {
  try {
    const accountObj = msalInstance.getAllAccounts()[0];
    const tokenResponse = await msalInstance.acquireTokenSilent({ account: accountObj, scopes: userDetailsRequest.scopes });
    return tokenResponse.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance.acquireTokenPopup(userDetailsRequest);
    }
  }
};

const msalClientInstance = axios.create({
  baseURL: ApiRoutes.MS_GRAPH_BASE_URL,
  timeout: 120000
});

msalClientInstance.interceptors.request.use(
  async function (config) {
    config.headers = {
      ...config.headers,
      ...getHeaders(),
      Authorization: `Bearer ${await addToken()}`
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

msalClientInstance.interceptors.response.use(
  (response): any => {
    return {
      data: response.data,
      message: response.statusText,
      status: response.status,
      headers: response.headers
    };
  },
  (error) => Promise.reject(error)
);

export default msalClientInstance;
