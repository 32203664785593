import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import axiosInstance from 'interceptor/axiosInstance';
import { deserialize } from 'serializr';
import { SmartMappingList } from 'models/SmartMappingList/smartMappingList.model';

const SMData = () => {

  const fetchValidation = () => {
    const { data, isLoading } = useQuery({
      queryKey: ['dashboard'],
      queryFn: async () => {
        const response = await axiosInstance.get(ApiRoutes.VALIDATION_DASHBOARD)
        return response.data;
      }
    })
    return {
      data,
      isLoading
    };
  }
  const SMList = (dimension: any, filter: any, sorting: any, globalFilter: any = []) => {
    const { data, isError, isLoading, fetchNextPage, isPreviousData, isFetching, refetch } = useInfiniteQuery({
      queryKey: ['smartmappinglist', filter, sorting, dimension, globalFilter],
      queryFn: async ({ pageParam = 0 }) => {
        const SMParams = {
          offset: pageParam * 30,
          limit: 30,
          filter_by_dimension: dimension,
          filters: JSON.stringify(filter),
          sorting: JSON.stringify(sorting ?? []),
          fileName: globalFilter ?? []
        };

        const response = await axiosInstance.get(ApiRoutes.SMART_MAPPING_LIST, { params: SMParams });
        const data = deserialize(SmartMappingList, response.data['result']) as SmartMappingList[];

        return data;
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    });
    return {
      data,
      isError,
      isLoading,
      fetchNextPage,
      isPreviousData,
      isFetching,
      refetch
    };
  };

  const SMListCount = (dimension: any, columnFilters: any, globalFilter: any = []) => {
    const { data, isError, isLoading, refetch } = useQuery({
      queryKey: ['smartmappinglistcount', columnFilters, dimension, globalFilter],
      queryFn: async () => {
        const SMParams = {
          filter_by_dimension: dimension,
          filters: JSON.stringify(columnFilters),
          fileName: globalFilter ?? []
        };

        const response = await axiosInstance.get(ApiRoutes.SMART_MAPPING_LIST_COUNT, { params: SMParams });

        return response.data;
      }
    });
    return {
      data,
      isError,
      isLoading,
      refetch
    };
  };

  return {
    SMList,
    SMListCount,
    fetchValidation
  };
};
export default SMData;
