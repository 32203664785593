import React, {useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from 'authConfig';
import CustomButton from '../Button-component/Button';

export function ProfileContent() {
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const name = accounts[0] && accounts[0].name;

  function RequestAccessToken() {
      const request = {
          ...loginRequest,
          account: accounts[0]
      }; 

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    //   instance.acquireTokenSilent(request).then((response) => {
    //       setAccessToken(response.accessToken);
    //   }).catch((e) => {
    //       instance.acquireTokenPopup(request).then((response) => {
    //           setAccessToken(response.accessToken);
    //       });
    //   });
  }

  return (
      <>
          <h5 className="card-title">Welcome {name}</h5>
          {accessToken ? 
              <p>Access Token Acquired!</p>
              :
              <CustomButton primary onClick={RequestAccessToken}>Request Access Token</CustomButton>
          }
      </>
  );
};