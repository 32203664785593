import { useNavigate, createSearchParams, useLocation, generatePath } from 'react-router-dom';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import { removeFalsyKeys } from 'shared/utils/removeFalsyKeys';

const useRedirect = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const navigateBack = () => navigate(-1);

  const redirect = (pathname: string, queryParams?: {}, hash?: string, replace?: boolean) => {
    navigate(
      {
        pathname,
        search: queryParams && createSearchParams(removeFalsyKeys(queryParams)).toString(),
        hash
      },
      { replace }
    );
  };

  const redirectToHome = () => redirect(AppRoutes.OPERATION_GRAPH);

  const redirectToAdminHome = () => redirect(AppRoutes.GLOBAL_RMS_SML_MAPPING);

  const redirectToSmlPcat = () => redirect(AppRoutes.SML_PCAT);

  const redirectToDQ = () => redirect(AppRoutes.DQ_CHECK);

  const redirectToAR = () => redirect(AppRoutes.ACCEPTANCE_REPORT);

  const redirectToCriticalAttribute = () => redirect(AppRoutes.CRITICAL_ATTRIBUTE);

  const redirectToFactMeta = () => redirect(AppRoutes.FACT_METADATA);

  const redirectToPeriodMeta = () => redirect(AppRoutes.PERIOD_METADATA);

  const redirectToMarketMetada = () => redirect(AppRoutes.MARKET_METADATA);

  const redirectToFVMapping = (id: number) => redirect(generatePath(AppRoutes.FILE_VOLATILITY_MAPPED, { id }));

  return {
    location,
    navigate,
    navigateBack,
    redirect,
    redirectToHome,
    redirectToAdminHome,
    redirectToSmlPcat,
    redirectToDQ,
    redirectToAR,
    redirectToCriticalAttribute,
    redirectToFactMeta,
    redirectToMarketMetada,
    redirectToPeriodMeta,
    redirectToFVMapping
  };
};

export default useRedirect;
