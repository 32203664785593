import { useMutation, useQuery } from '@tanstack/react-query';
import axiosInstance from 'interceptor/axiosInstance';

const FactRemappingService = () => {
  const FactRemappingDropDown = ({ provider = null, apiUrl = null, itemDependsOn = null }: any) => {  
    const { data, isLoading, isFetching } = useQuery({
      enabled: (!!provider && !!apiUrl),
      queryKey: ['factRemappingDropDown', apiUrl, provider, itemDependsOn],
      queryFn: async () => {
        const modifiedUrl = provider && provider !== 'nielsen' ? `fact/${provider}` : 'fact';
        const response = await axiosInstance.get(`remapping/${modifiedUrl}/${apiUrl}`);

        return response.data;
      }
    });

    return {
      data,
      isLoading,
      isFetching
    };
  };

  const UpdatedFactColumn = () => {
    const { data, mutate, isLoading, isError } = useMutation({
      mutationFn: async ({ provider = null, value = null, id }: any) => {
        const modifiedUrl = provider && provider !== 'nielsen' ? `fact/${provider}` : 'fact';
        const response = await axiosInstance.put(`remapping/${modifiedUrl}/${id}`, value);
        return response.data;
      }
    });

    return {
      mutate,
      data,
      isLoading,
      isError
    };
  };

  return {
    FactRemappingDropDown,
    UpdatedFactColumn
  };
};

export default FactRemappingService;
