import React from 'react';

export const ProductUnProcessedColumns = [
  {
    Header: 'External',
    accessorKey: 'External',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'External Product Description',
        accessorKey: 'Externaldesc',
        size: 260
      },
      {
        header: 'External Short Description',
        accessorKey: 'Short',
        size: 280
      },
      // {
      //   header: 'External TAG',
      //   accessorKey: 'Tag',
      //   size: 140
      // },
      // {
      //   header: 'External Hierarchy Number',
      //   accessorKey: 'Hiernum',
      //   size: 240
      // },
      // {
      //   header: 'External Hierarchy Name',
      //   accessorKey: 'Hiername',
      //   size: 240
      // },
      // {
      //   header: 'External Hierarchy Level',
      //   accessorKey: 'Hierlevelnum',
      //   size: 240
      // },
      // {
      //   header: 'External Hierarchy Level Name',
      //   accessorKey: 'Hierlevelname',
      //   size: 280
      // },
      // {
      //   header: 'External Parent TAG',
      //   accessorKey: 'Parenttag',
      //   size: 240
      // },
      // {
      //   header: 'External Company',
      //   accessorKey: 'Company',
      //   size: 240
      // },
      // {
      //   header: 'External Brand',
      //   accessorKey: 'Brand',
      //   size: 240
      // },
      // {
      //   header: 'SEGMENT',
      //   accessorKey: 'Segmentname',
      //   minWidth: 300,
      //   id: 'externalSegment'
      // }
    ]
  },
  {
    Header: 'Internal',
    accessorKey: 'Internal',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Internal Product Description',
        accessorKey: 'Internaldesc',
        size: 310
      },
      {
        header: 'Category Name',
        accessorKey: 'Categoryname',
        minWidth: 240
      },
      // {
      //   header: 'Market Name',
      //   accessorKey: 'Marketname',
      //   minWidth: 150
      // },
      // {
      //   header: 'Corporate brand name',
      //   accessorKey: 'Corporatebrandname',
      //   size: 220
      // },
      // {
      //   header: 'Product Form Name',
      //   accessorKey: 'Productformmediumname',
      //   size: 200
      // },
      // {
      //   header: 'Sub Product Form Varient Name',
      //   accessorKey: 'Spfvname',
      //   size: 350
      // },
      // {
      //   header: 'Division Name',
      //   accessorKey: 'Divisionname',
      //   minWidth: 200
      // },
      // {
      //   header: 'Sector Name',
      //   accessorKey: 'Sectorname',
      //   minWidth: 280
      // },
      // {
      //   header: 'Segment Name',
      //   accessorKey: 'Segmentname',
      //   minWidth: 300,
      // },
      // {
      //   header: 'Form Name',
      //   accessorKey: 'Formname',
      //   minWidth: 200
      // },
      // {
      //   header: 'Sub Form Name',
      //   accessorKey: 'Subformname',
      //   minWidth: 200
      // },
      // {
      //   header: 'Product Pack Form Name',
      //   accessorKey: 'Productpackformname',
      //   size: 280
      // },
      // {
      //   header: 'Product Pack Size Name',
      //   accessorKey: 'Productpacksizename',
      //   size: 240
      // },
      // {
      //   header: 'Product Variant Name',
      //   accessorKey: 'Productvariantname',
      //   size: 240
      // },
      // {
      //   header: 'Product Code Name',
      //   accessorKey: 'Productcodename',
      //   size: 200
      // }
    ]
  }
  
];
