import React from 'react';

export const POSFactMappedColumn = [
  {
    header: 'External',
    accessorKey: 'External',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'External Long Description',
        accessorkey: 'Externaldesc',
        size: 300,
        enableSorting: false,
        id: 'long'
      },
      {
        header: 'External Short Description',
        accessorKey: 'Short',
        size: 300,
        enableSorting: false,
        id: 'short'
      },
      // {
      //   header: 'Currency',
      //   accessorKey: 'Currency',
      //   maxWidth: 180,
      //   enableSorting: false,
      //   id: 'externalCurrency'
      // },
      // {
      //   header: 'Country',
      //   accessorKey: 'Country',
      //   maxWidth: 180,
      //   enableSorting: false,
      //   id: 'country'
      // },
      // {
      //   header: 'Direct Indirect',
      //   accessorKey: 'Direct_Indirect',
      //   size: 180,
      //   enableSorting: false,
      //   id: 'direct_Indirect'
      // }
    ]
  },
  {
    header: 'Internal',
    accessorKey: 'internal',
    enableColumnFilter: false,
    enableSorting: false,
    mantineTableHeadCellProps: {
      align: 'left'
    },
    columns: [
      {
        header: 'Internal Fact Description',
        accessorKey: 'Harmonizedname',
        size: 300,
        enableSorting: false,
        id: 'internalFactDescription'
      },
      {
        header: 'Internal Fact Type',
        accessorKey: 'Facttype',
        enableSorting: false,
        size: 200,
        id: 'Internal_Fact_Type'
      },
      // {
      //   header: 'ISO CountryCode',
      //   accessorKey: 'ISOCountryCode',
      //   size: 300,
      //   enableSorting: false,
      //   id: 'ISOCountryCode'
      // },
      // {
      //   header: 'Unique Tag',
      //   accessorKey: 'Uniqueidentifier',
      //   size: 300,
      //   enableSorting: false,
      //   id: 'uniqueidentifier'
      // }
    ]
  }
];
