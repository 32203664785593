import React, { useEffect, useState } from 'react';
import ReactSelect from 'shared/components/ReactSelect';
import DropDownMeta from 'services/MetaService/dropdownMeta.service';
import './smfilters.scss';
import DatePicker from 'shared/components/DatePicker';
import { Grid } from '@mantine/core';

const Filter = ({ handleFilterChange }: any) => {
  const [params, setParams] = useState({});
  const [date, setDate] = useState([]);

  const { CountryDropDown, CategoryDropDown, DataProviderDropDown } = DropDownMeta();

  const { data: countryData, isLoading: countryIsLoading, isError: countryIsError } = CountryDropDown(params);
  const { data: categoryData, isLoading: categoryIsLoading, isError: categoryIsError } = CategoryDropDown(params);
  const { data: providerData, isLoading: providerIsLoading, isError: providerIsError } = DataProviderDropDown(params);

  const dateFilters = (value: any) => {
    setDate(value);
  };

  const handleMetaChange = (type: string, value: string) => {
    if (value) setParams({ ...params, [type]: value });
    else
      setParams((curr) => {
        const obj: any = { ...curr };
        delete obj[type];
        return obj;
      });
  };

  useEffect(() => {
    if (Object.keys(params) || date.length) handleFilterChange(params, date);
  }, [params, date]);

  return (
    <div className='filter-container'>
      <Grid>
        <Grid.Col md={6} lg={3}>
          <ReactSelect
            label='Data Provider'
            isLoading={providerIsLoading}
            options={providerData ? providerData.data : []}
            onValueChange={(value: string) => handleMetaChange('provider', value)}
          />
        </Grid.Col>
        <Grid.Col md={6} lg={3}>
          <ReactSelect
            label='Country'
            isLoading={countryIsLoading}
            options={countryData ? countryData.data : []}
            onValueChange={(value: string) => handleMetaChange('country', value)}
          />
        </Grid.Col>
        <Grid.Col md={6} lg={3}>
          <ReactSelect
            label='Category'
            isLoading={categoryIsLoading}
            options={categoryData ? categoryData.data : []}
            onValueChange={(value: string) => handleMetaChange('category', value)}
          />
        </Grid.Col>
        <Grid.Col md={6} lg={3}>
          <DatePicker onChange={dateFilters} />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default Filter;
