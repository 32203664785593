import React from 'react';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import axiosInstance from 'interceptor/axiosInstance';
import { useState } from 'react';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { FVDownloadObject } from 'views/FileVolatility/VolatilityMapping/ColumnMappingView';

const FileVolatility = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const triggerCAPipeline = async (params: FVDownloadObject) => {
    try {
      setLoading(true);
      await axiosInstance.post(ApiRoutes.TRIGGER_CA_PIPELINE, { params });
      notifications.show({
        title: 'Download Success',
        message: 'Report will be shared soon via email',
        color: 'green',
        icon: <IconCheck />,
        autoClose: 2000,
        withBorder: true
      });
    } catch (error: any) {
      const { response } = error;
      notifications.show({
        title: 'Download Failed',
        message: response.data['error'] && response.data.error['message'],
        color: 'red',
        icon: <IconX />,
        autoClose: 2000,
        withBorder: true
      });
    } finally {
      setLoading(false);
    }
  };

  return { loading, triggerCAPipeline };
};

export default FileVolatility;
