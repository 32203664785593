import React from 'react';

export const POSPeriodMappedColumn = [
  {
    header: 'Extrenal',
    accessorKey: 'External',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Date',
        accessorKey: 'Date',
        size: 200,
        id: 'date'
      },
      {
        header: 'Week Commencing',
        accessorKey: 'WeekCommencing',
        size: 200,
        id: 'week_commencing'
      }
    ]
  },
  {
    header: 'Internal',
    accessorKey: 'internal',
    size: 200,
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Periodicity',
        accessorKey: 'Periodicity',
        size: 100
      },
      {
        header: 'Tag',
        accessorKey: 'Tag',
        size: 100
      },
      // {
      //   header: 'Year',
      //   accessorKey: 'YearBr',
      //   size: 100
      // },
      // {
      //   header: 'Quarter',
      //   accessorKey: 'QuarterBr',
      //   size: 100
      // },
      // {
      //   header: 'Month',
      //   accessorKey: 'MonthBr',
      //   size: 100
      // },
      // {
      //   header: 'Week',
      //   accessorKey: 'WeekBr',
      //   size: 100
      // },
      // {
      //   header: 'Start Date',
      //   accessorKey: 'PeriodStartDate',
      //   size: 100
      // },
      // {
      //   header: 'End Date',
      //   accessorKey: 'PeriodEndDate',
      //   size: 100
      // },
      // {
      //   header: 'Min Period Number',
      //   accessorKey: 'MinPeriod',
      //   size: 100
      // },
      // {
      //   header: 'Max Period Number',
      //   accessorKey: 'MaxPeriod',
      //   size: 100
      // },
      // {
      //   header: 'Month Number',
      //   accessorKey: 'MonthNumber',
      //   size: 100
      // },
      // {
      //   header: 'Period Number',
      //   accessorKey: 'PeriodNumberBr',
      //   size: 100
      // }
    ]
  }
];
