import { displayDateTimeFormat } from 'constants/formats';
import { MRT_ColumnDef } from 'mantine-react-table';
import { IndividualFileVolatility } from 'models/IndividualFileVolatility1/IndividualFileVolatility.model';
import moment from 'moment';
import React from 'react';
import ArrowIcon from 'assets/icons/CardArrowVector.svg';
import useRedirect from 'shared/hooks/useRedirect';

export const getFVSummaryColumns: MRT_ColumnDef<IndividualFileVolatility>[] = [
  { header: 'Data Provider', accessorKey: 'SOURCE', size: 40 },
  { header: 'Country', accessorKey: 'COUNTRY', size: 40 },
  { header: 'Category', accessorKey: 'CATEGORY', size: 40 },
  {
    header: 'Filename',
    accessorKey: 'FILENAME',
    mantineTableBodyCellProps: {
      className: 'fv-record-filename-cell'
    }
  },
  {
    header: 'Loaded on',
    accessorKey: 'LOADSTARTTIME',
    Cell: (props) => <span>{moment.utc(props.cell.getValue() ?? '').format(displayDateTimeFormat)}</span>
  },
  {
    header: 'Status',
    accessorKey: 'PIPELINESTATUS',
    size: 30,
    enableColumnFilter: false,
    mantineTableBodyCellProps: {
      className: 'status-cell'
    },
    Cell: (props) => (
      <span className={`status ${String(props.cell.getValue())?.toLowerCase().split(' ').join('_')}`}>{props.cell.getValue() ?? ''}</span>
    )
  },
  {
    header: '',
    id: 'arrow',
    Cell: (props) => <img src={ArrowIcon} alt='arrow' />,
    size: 10,
    enableColumnFilter: false
  }
];
