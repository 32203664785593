import { serializable, alias, object, list, primitive } from 'serializr';

export class CellControl {
  @serializable(alias('Id', primitive()))
  Id?: number;

  @serializable(alias('DataProvider', primitive()))
  DataProvider?: string;

  @serializable(alias('Format', primitive()))
  Format?: string;

  @serializable(alias('Country', primitive()))
  Country?: string;

  @serializable(alias('Category', primitive()))
  Category?: number;

  @serializable(alias('Filename', primitive()))
  Filename?: string;

  @serializable(alias('Expected', primitive()))
  Expected?: string;

  @serializable(alias('Period', primitive()))
  Period?: string;

  @serializable(alias('Language', primitive()))
  Language?: string;

  @serializable(alias('Source', primitive()))
  Source?: string;

  @serializable(alias('Interim', primitive()))
  Interim?: string;

  @serializable(alias('Target', primitive()))
  Target?: string;

  @serializable(alias('IsActive', primitive()))
  IsActive?: number;

  @serializable(alias('loading', primitive()))
  loading?: boolean = false;

  @serializable(primitive())
  isSelected?: boolean = false;
}

export class CellControlStatus {
  @serializable(alias('total_cells', primitive()))
  totalCells?: number;
  @serializable(alias('activated_cells', primitive()))
  activatedCells?: number;
  @serializable(alias('deactivated_cells', primitive()))
  deactivatedCells?: number;
}
