import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import './LineChartCard.scss';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';
import Arrow from 'assets/icons/CardArrowVector.svg';
import Download from 'assets/icons/download-icon.svg';
import ValidationService from 'services/ValidationService/validation.service';
import NewEDDropdown from '../NewEDDropdown';
import DashboardServiceService from 'services/DashboardService/dashboard.service';
import Loader from '../Loader';
import { $schema } from 'eslint-config-standard-with-typescript';
import { MetaData } from 'models/MetaData/metaData.model';
import MetaService from 'services/MetaService/meta.service';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import clsx from 'clsx';

interface LineChartCardProps {
  startDate?: string;
  endDate?: string;
  country?: string;
}

export interface ValidationFilters {
  filter_by_provider: string;
}

const LineChartCard = (props: LineChartCardProps) => {
  const [filters, setFilters] = useState<ValidationFilters>({
    filter_by_provider: ''
  });

  const updateFilters = (filterName: keyof ValidationFilters, value: string) => {
    setFilters({
      ...filters,
      [filterName]: value
    });

    fetchVolatilityMetrics({
      filter_by_provider: value
    });
  };

  const lengthRef = useRef<any>();
  const { startDate, endDate, country } = props;

  // const handleProviderChange = (value: string) => {

  //   fetchVolatilityMetrics({
  //     filter_by_provider: value
  //   });
  // };
  // const handleProviderReset = () => {
  //   fetchVolatilityMetrics({
  //     filter_by_provider: ''
  //   });
  // };
  useEffect(() => {
    if (startDate && endDate) {
      fetchVolatilityMetrics({
        start_date: startDate,
        end_date: endDate
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (country) {
      fetchVolatilityMetrics({
        filter_by_country: country
      });
    }
  }, [country]);

  const {
    fetchVolatilityMetrics,
    volatilityMetrics,
    volatilityUniqueColumns,
    loading: chartLoading,
    totalRequests
  } = DashboardServiceService();

  useEffect(() => {
    fetchVolatilityMetrics();
    fetchMeta(ApiRoutes.HOME_PROVIDER_META, setProviderMeta, MetaData);
  }, []);

  const { fetchMeta, loading } = MetaService();

  const [providerMeta, setProviderMeta] = useState<MetaData[]>([]);

  // if (!volatilityMetrics) return <Loader />;

  const handleChartLoad = () => {
    const height = lengthRef.current.offsetHeight;
    const taskHeaderHeight = document.querySelector<HTMLElement>('.task-due-header')?.offsetHeight!;
    const newHeight = height - taskHeaderHeight - 11;
    const elm = document.querySelector<HTMLElement>('.tasks-due-container .list-container')!;
    elm.style.maxHeight = newHeight + 'px';
    elm.style.minHeight = newHeight + 'px';
  };

  return (
    <Container fluid className='line-chart-card' ref={lengthRef}>
      <Row>
        <div className='card-title-container'>
          <h3 className='card-title-container-header'>File Volatility - File Metrics</h3>
          <img src={Arrow} alt='arrow-icon' />
        </div>
        <div className='card-dropdown-container'>
          <div className='filter-container'>
            <NewEDDropdown
              // optionItems={providerMeta ?? []}
              // currentItemIndex={2}
              // resetFunc={handleProviderReset}
              // labelText='Data Providerqq'
              // defaultValue= ''//'All(22)'
              // updateValue={handleProviderChange}
              optionItems={providerMeta ?? []}
              // loading={!changedFilter && metaLoading}
              value={filters.filter_by_provider}
              currentItemIndex={2}
              resetIcon={true ? filters.filter_by_provider !== '' : false}
              resetFunc={() => updateFilters('filter_by_provider', '')}
              labelText='Data Provider'
              defaultValue='All(22)'
              updateValue={(value) => updateFilters('filter_by_provider', value)}
            />
          </div>
          <div className='download-container'>
            <h2 className='download-container-text'>Download</h2>
            <img src={Download} alt='download' />
          </div>
        </div>
      </Row>
      {chartLoading ? (
        <Loader />
      ) : (
        <ResponsiveContainer width='100%' aspect={2}>
          <LineChart
            width={800}
            height={380}
            data={volatilityMetrics}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            {volatilityMetrics?.length && (
              <XAxis
                dataKey='name'
                label={{
                  value: `( ${volatilityMetrics[0].name} - ${volatilityMetrics[volatilityMetrics.length - 1].name} )`,
                  dy: 20
                }}
              />
            )}
            <YAxis label={{ value: 'No.of files', angle: -90, position: 'insideLeft' }} />
            <Tooltip />

            {volatilityUniqueColumns.map((item, index) => {
              let strokeColor = '';
              switch (index) {
                case 0:
                  strokeColor = '#008651';
                  break;
                case 1:
                  strokeColor = '#FF0A7A';
                  break;
                case 2:
                  strokeColor = '#9C44C0';
                  break;
              }
              return (
                <Line
                  type='monotone'
                  dataKey={item}
                  stroke={strokeColor}
                  activeDot={{ r: 8 }}
                  width={5}
                  strokeWidth={2}
                  onAnimationStart={handleChartLoad}
                  dot={<Dot rx={4} stroke={strokeColor} fill={strokeColor} />}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
      <div className='justify-content-between d-flex aligh-item-baseline mb-2 mt-5'>
        <p className='data-provider-title'>Data providers</p>
        <p className='data-provider-total-count'>{totalRequests}</p>
      </div>
      <div className='justify-content-between d-flex'>
        <ul className='data-providers'>
          {volatilityUniqueColumns.map((source, i) => (
            <div key={source} className='data-provider'>
              <span className={clsx('data-provider-dot', `dot-${i}`)} />
              <li className='data-provider-name'>{source}</li>
            </div>
          ))}
        </ul>
        <p className='data-provider-total-req'>Total requests</p>
      </div>
    </Container>
  );
};

export default LineChartCard;
