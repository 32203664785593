import React, { useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import './segmentedTab.scss';

const SegmentedTab = (props: any) => {
  return (
    <SegmentedControl
      className='segmented-tab'
      data={props.data}
      transitionDuration={300}
      transitionTimingFunction='linear'
      radius={0}
      size='sm'
      value={props.value ? props.value : props.data[0].value}
      onChange={(val) => {
        props.onChange(val);
      }}
    />
  );
};

export default SegmentedTab;
