import React from 'react';
import AccessDeniedImage from 'assets/images/access-denied.svg';
import './AccessDenied.scss'

const AccessDenied = () => {
  return (
    <div
      className='access-denied-container'
    >
      <img src={AccessDeniedImage} alt='access-denied' style={{ maxHeight: '500px' }} />
      <p className='access-denied-title'>
        The page you are trying to access have restricted access.
      </p>
    </div>
  );
};

export default AccessDenied;
