import React from 'react';
import ProductMappedServices from 'services/SmartMapping/Details/Product.Service';
import { ProductMappedColumns } from './Nielsen/Product/mapped/mappedColumn';
import { POSProductMappedColumns } from './POS/Product/mapped/mappedColumn';
import { RMSProductMappedColumns } from './otherRMS/Product/mapped/mappedColumn';
import { ProductUnProcessedColumns } from './Nielsen/Product/unprocessed/unprocessedColumn';
import { POSProductUnprocessedColumn } from './POS/Product/unprocessed/unprocessedColumn';
import { RMSProductUnprocessedColumn } from './otherRMS/Product/unprocessed/unprocessedColumn';
import FactMappedServices from 'services/SmartMapping/Details/Fact.Service';
import { FactMappedColumn } from './Nielsen/Fact/mapped/mappedColumn';
import { POSFactMappedColumn } from './POS/Fact/mapped/mappedColumn';
import { RMSFactMappedColumn } from './otherRMS/Fact/mapped/mappedColumn';
import { FactUnprocessedColumns } from './Nielsen/Fact/unprocessed/unprocessedColumns';
import PeriodMappedServices from 'services/SmartMapping/Details/Period.Service';
import { PeriodMappedColumn } from './Nielsen/Period/mapped/mappedColumns';
import { POSPeriodMappedColumn } from './POS/Period/mapped/mappedColumns';
import { RMSPeriodMappedColumn } from './otherRMS/Period/mapped/mappedColumns';
import MarketMappedServices from 'services/SmartMapping/Details/Market.Service';
import { MarketMappedColumn } from './Nielsen/Market/mapped/mappedColumns';
import { POSMarketMappedColumn } from './POS/Market/mapped/mappedColumns';
import { RMSMarketMappedColumn } from './otherRMS/Market/mapped/mappedColumns';
import { MarketUnprocessedColumns } from './Nielsen/Market/unprocessed/unprocessedColumns';
import { ProductMappedRemapping } from './Nielsen/Product/remapping/mapped';
import ProductRemappingService from 'services/SmartMapping/RemappingService/ProductRemapping.service';
import FactRemappingService from 'services/SmartMapping/RemappingService/FactRemapping.service';
import PeriodRemappingService from 'services/SmartMapping/RemappingService/PeriodRemapping.service';
import MarketRemappingService from 'services/SmartMapping/RemappingService/MarketRemapping.service';
import { POSProductMapppedRemapping } from './POS/Product/remapping/mapped';
import { RMSProductMappedRemmaping } from './otherRMS/Product/remapping/mapped';
import { FactMappedRemapping } from './Nielsen/Fact/remapping/mapped';
import { RMSFactMappedRemapping } from './otherRMS/Fact/remapping/mapped';
import { POSFactMappedRemapping } from './POS/Fact/remapping/mapped';
import { PeriodMappedRemapping } from './Nielsen/Period/remapping/mapped';
import { POSPeriodMappedRemapping } from './POS/Period/remapping/mapped';
import { RMSPeriodMappedRemapping } from './otherRMS/Period/remapping/mapped';
import { MarketMappedRemapping } from './Nielsen/Market/remapping/mapped';
import { POSMarketMappedRemapping } from './POS/Market/remapping/mapped';
import { RMSMarketMappedRemapping } from './otherRMS/Market/remapping/mapped';
import { ProductUnprocessedRemapping } from './Nielsen/Product/remapping/unprocessed';

const {
  ProductMappedRecords,
  ProductMappedRecordsCount,
  ProductUnprocessedRecordsCount,
  downloadMappedProductRecords,
  ProductUnprocessedRecords,
  downloadUnprocessedProductRecords,
  ProductUAOLMappedRecords,
  ProductUAOLUnprocessedRecords,
  ProductUAOLMappedRecordsCount,
  ProductUAOLUnprocessedRecordsCount,
  downloadUAOLMappedProductRecords,
  downloadUAOLUnprocessedProductRecords
} = ProductMappedServices();
const {
  FactMappedRecords,
  FactMappedRecordsCount,
  FactUnprocessedRecordsCount,
  downloadMappedFactRecords,
  FactUnprocessedRecords,
  downloadUnprocessedFactRecords
} = FactMappedServices();
const { PeriodMappedRecords, PeriodMappedRecordsCount, downloadMappedPeriodRecords } = PeriodMappedServices();
const {
  MarketMappedRecords,
  MarketMappedRecordsCount,
  MarketUnprocessedRecordsCount,
  MarketUnprocessedRecords,
  downloadMappedMarketRecords,
  downloadUnprocessedMarketRecords
} = MarketMappedServices();

const { ProductMappingDropDown, UpdatedProductColumn } = ProductRemappingService();
const { FactRemappingDropDown, UpdatedFactColumn } = FactRemappingService();
const { PeriodMappingDropDown, UpdatedPeriodColumn } = PeriodRemappingService();
const { MarketMappingDropDown, UpdatedMarketColumn } = MarketRemappingService();

export const DIMENSION_META: any = {
  product: {
    high: {
      fetchData: ProductMappedRecords,
      fetchCount: ProductMappedRecordsCount,
      downloadMethod: downloadMappedProductRecords,
      remappingOption: ProductMappingDropDown,
      remappingUpdate: UpdatedProductColumn,
      nielsen: {
        remappingMeta: ProductMappedRemapping,
        column: ProductMappedColumns
      },
      pos: {
        remappingMeta: POSProductMapppedRemapping,
        column: POSProductMappedColumns
      },
      rms: {
        remappingMeta: RMSProductMappedRemmaping,
        column: RMSProductMappedColumns
      }
    },
    medium: {
      fetchData: ProductMappedRecords,
      fetchCount: ProductMappedRecordsCount,
      downloadMethod: downloadMappedProductRecords,
      remappingOption: ProductMappingDropDown,
      remappingUpdate: UpdatedProductColumn,
      nielsen: {
        remappingMeta: ProductMappedRemapping,
        column: ProductMappedColumns
      },
      pos: {
        remappingMeta: POSProductMapppedRemapping,
        column: POSProductMappedColumns
      },
      rms: {
        remappingMeta: RMSProductMappedRemmaping,
        column: RMSProductMappedColumns
      }
    },
    low: {
      fetchData: ProductMappedRecords,
      fetchCount: ProductMappedRecordsCount,
      downloadMethod: downloadMappedProductRecords,
      remappingOption: ProductMappingDropDown,
      remappingUpdate: UpdatedProductColumn,
      nielsen: {
        remappingMeta: ProductMappedRemapping,
        column: ProductMappedColumns
      },
      pos: {
        remappingMeta: POSProductMapppedRemapping,
        column: POSProductMappedColumns
      },
      rms: {
        remappingMeta: RMSProductMappedRemmaping,
        column: RMSProductMappedColumns
      }
    },
    unprocessed: {
      fetchData: ProductUnprocessedRecords,
      fetchCount: ProductUnprocessedRecordsCount,
      downloadMethod: downloadUnprocessedProductRecords,
      remappingOption: ProductMappingDropDown,
      remappingUpdate: UpdatedProductColumn,
      nielsen: {
        remappingMeta: ProductUnprocessedRemapping,
        column: ProductUnProcessedColumns
      },
      pos: {
        column: POSProductUnprocessedColumn
      },
      rms: {
        column: RMSProductUnprocessedColumn
      }
    },
    'uaol-processed': {
      fetchData: ProductUAOLMappedRecords,
      fetchCount: ProductUAOLMappedRecordsCount,
      downloadMethod: downloadUAOLMappedProductRecords,
      remappingOption: ProductMappingDropDown,
      remappingUpdate: UpdatedProductColumn,
      nielsen: {
        column: ProductMappedColumns
      }
    },
    'uaol-unprocessed': {
      fetchData: ProductUAOLUnprocessedRecords,
      fetchCount: ProductUAOLUnprocessedRecordsCount,
      downloadMethod: downloadUAOLUnprocessedProductRecords,
      nielsen: {
        column: ProductUnProcessedColumns
      }
    }
  },
  fact: {
    mapped: {
      fetchData: FactMappedRecords,
      fetchCount: FactMappedRecordsCount,
      downloadMethod: downloadMappedFactRecords,
      remappingOption: FactRemappingDropDown,
      remappingUpdate: UpdatedFactColumn,
      nielsen: {
        remappingMeta: FactMappedRemapping,
        column: FactMappedColumn
      },
      pos: {
        remappingMeta: POSFactMappedRemapping,
        column: POSFactMappedColumn
      },
      rms: {
        remappingMeta: RMSFactMappedRemapping,
        column: RMSFactMappedColumn
      }
    },
    unprocessed: {
      fetchData: FactUnprocessedRecords,
      fetchCount: FactUnprocessedRecordsCount,
      downloadMethod: downloadUnprocessedFactRecords,
      nielsen: {
        column: FactUnprocessedColumns
      }
    }
  },
  period: {
    mapped: {
      fetchData: PeriodMappedRecords,
      fetchCount: PeriodMappedRecordsCount,
      downloadMethod: downloadMappedPeriodRecords,
      remappingOption: PeriodMappingDropDown,
      remappingUpdate: UpdatedPeriodColumn,
      nielsen: {
        remappingMeta: PeriodMappedRemapping,
        column: PeriodMappedColumn
      },
      pos: {
        remappingMeta: POSPeriodMappedRemapping,
        column: POSPeriodMappedColumn
      },
      rms: {
        remappingMeta: RMSPeriodMappedRemapping,
        column: RMSPeriodMappedColumn
      }
    }
  },
  market: {
    mapped: {
      fetchData: MarketMappedRecords,
      fetchCount: MarketMappedRecordsCount,
      downloadMethod: downloadMappedMarketRecords,
      remappingOption: MarketMappingDropDown,
      remappingUpdate: UpdatedMarketColumn,
      nielsen: {
        remappingMeta: MarketMappedRemapping,
        column: MarketMappedColumn
      },
      pos: {
        remappingMeta: POSMarketMappedRemapping,
        column: POSMarketMappedColumn
      },
      rms: {
        remappingMeta: RMSMarketMappedRemapping,
        column: RMSMarketMappedColumn
      }
    },
    unprocessed: {
      fetchData: MarketUnprocessedRecords,
      fetchCount: MarketUnprocessedRecordsCount,
      downloadMethod: downloadUnprocessedMarketRecords,
      nielsen: {
        column: MarketUnprocessedColumns
      }
    }
  }
};
