import { displayDateTimeFormat } from "constants/formats";
import moment from "moment";
import React from "react";


export const RMSProductUnprocessedColumn = [
    {
        Header: 'FileName',
        accessorKey: 'Filename',
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        Header: 'Tag',
        accessorKey: 'Tag'
      },
      {
        Header: 'Externaldesc',
        accessorKey: 'Externaldesc',
        minWidth: 200
      },
      {
        Header: 'Created On',
        accessorKey: 'Createdon',
        enableColumnFilter: false,
        Cell: ({ cell }: any) => (
          <div role='link' tabIndex={0}>
            {moment.utc(cell.value).format(displayDateTimeFormat)}
          </div>
        )
      },
      // {
      //   Header: 'Remark',
      //   accessorKey: 'Remark',
      //   minWidth: 300
      // },
]