import React from 'react';

export const RMSPeriodMappedColumn = [
  {
    header: 'Extrenal',
    accessorKey: 'External',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      // {
      //   header: 'External TAG',
      //   accessorKey: 'Tag',
      //   size: 140
      // },
      {
        header: 'External Long Description',
        accessorKey: 'Long',
        size: 240
      },
      // {
      //   header: 'Last Period',
      //   accessorKey: 'LastPeriodTagFormat',
      //   size: 100
      // }
    ]
  },
  {
    header: 'Internal',
    accessorKey: 'internal',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Periodicity',
        accessorKey: 'Periodicity',
        size: 100
      },
      {
        header: 'Year',
        accessorKey: 'YearBr',
        size: 100
      },
      // {
      //   header: 'Quarter',
      //   accessorKey: 'QuarterBr',
      //   size: 100
      // },
      // {
      //   header: 'Month',
      //   accessorKey: 'MonthBr',
      //   size: 100
      // },
      // {
      //   header: 'Week',
      //   accessorKey: 'WeekBr',
      //   size: 100
      // },
      // {
      //   header: 'Start Date',
      //   accessorKey: 'PeriodStartDate',
      //   size: 100
      // },
      // {
      //   header: 'End Date',
      //   accessorKey: 'PeriodEndDate',
      //   size: 100
      // },
      // {
      //   header: 'Min Period Number',
      //   accessorKey: 'MinPeriodNumBr',
      //   size: 150
      // },
      // {
      //   header: 'Max Period Number',
      //   accessorKey: 'MaxPeriodNumBr',
      //   size: 150
      // },
      // {
      //   header: 'Country Week Start Day',
      //   accessorKey: 'WeekStartDayCountry',
      //   size: 240
      // },
      // ,
      // {
      //   header: 'Periodicity Identifier',
      //   accessorKey: 'PeriodicityIdentifier',
      //   size: 200
      // },
      // {
      //   header: 'Convention',
      //   accessorKey: 'Convention',
      //   size: 100
      // },
      // {
      //   header: 'Period Number',
      //   accessorKey: 'PeriodNumberBr',
      //   size: 100
      // }
    ]
  }
];
