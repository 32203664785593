// Table.js

import React, { ChangeEvent, Dispatch, Fragment, MouseEvent, SetStateAction } from 'react';
import { Column, useExpanded, useTable } from 'react-table';
import './Table-component.scss';
import SkeletonLoader from '../SkeletonLoader';
import clsx from 'clsx';
import { TableAction } from 'enums/tableAction';
import DeleteIcon from 'assets/icons/delete-red.svg';
import { Cell } from 'recharts';

interface Props {
  columns: any;
  data: any;
  tableFor?: string;
  renderRowSubComponent?: any;
  actionType?: TableAction;
  setData?: Dispatch<SetStateAction<any>>;
  loading?: boolean;
  initialData?: any;
  primaryKey?: string;
  showAddSuccess?: boolean;
  isDisabledKey?: string;
  isDisabledClassName?: string;
}

const Table: React.FC<Props> = (props) => {
  const {
    columns,
    data,
    tableFor,
    renderRowSubComponent,
    actionType,
    setData,
    loading,
    initialData,
    primaryKey,
    showAddSuccess,
    isDisabledKey,
    isDisabledClassName
  } = props;

  const tableData = React.useMemo(() => (loading ? Array(10).fill({}) : data), [loading, data]);

  const tableColumns = React.useMemo(
    () =>
      loading
        ? columns.map((column: Column) => ({
            ...column,
            Cell: <SkeletonLoader className='table-cell-loading' />
          }))
        : columns,
    [loading, columns]
  );

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow,
    visibleColumns // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable(
    {
      columns: tableColumns,
      data: tableData
    },
    useExpanded
  ); /*
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */

  // Function to handle cell editing
  const handleCellEdit = (rowIndex: number, columnId: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const updatedData = [...data];
    updatedData[rowIndex][columnId] = event.target.value;

    const { ['isEdited']: _, ...rest1 } = updatedData[rowIndex];
    const { ['isEdited']: __, ...rest2 } = initialData[rowIndex];

    updatedData[rowIndex]['isEdited'] = JSON.stringify(rest1) !== JSON.stringify(rest2);
    setData && setData(updatedData);
  };

  const isCellEdited = (rowIndex: number, columnId: string, cellValue: string) =>
    initialData?.length && initialData[rowIndex] && initialData[rowIndex][columnId] !== cellValue;

  return (
    <table
      style={{ width: '100%' }}
      className={clsx(
        tableFor === 'highMedium' ? 'table-component-highMedium' : 'table-component',
        (actionType === TableAction.DELETE || actionType === TableAction.ADD) && 'table-with-actions'
      )}
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className='table-header' id='table-header'>
            {headerGroup.headers.map((column) => {
              const data = column.render('Header');
              return (
                <th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width }
                  })}
                  className={typeof data === 'string' ? 'data-exist' : 'empty'}
                >
                  {data}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, index: number) => {
          prepareRow(row);
          return actionType === TableAction.ADD && index === 0 ? (
            <Fragment key={index}>
              <tr {...row.getRowProps()} className={clsx(actionType === TableAction.ADD && 'add-row', 'table-row')}>
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                          wordBreak: 'break-all'
                        }
                      })}
                      className={clsx('add-row__cell', 'table-row__column')}
                    >
                      {primaryKey !== cell.column.id && index !== row.cells.length - 1 ? (
                        <input className='cell-input' value={cell.value ?? ''} onChange={handleCellEdit(row.index, cell.column.id)} />
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
            </Fragment>
          ) : (
            <Fragment key={index}>
              <tr {...row.getRowProps()} className={clsx('table-row', showAddSuccess && index === 0 && 'show-success-border')}>
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                          wordBreak: 'break-all'
                        }
                      })}
                      className={clsx(
                        isCellEdited(row.index, cell.column.id, cell.value) &&
                          (actionType === TableAction.EDIT ? !loading && 'edit-highlight' : !loading && 'update-highlight'),
                        'table-row__column',
                        isDisabledKey &&
                          isDisabledClassName &&
                          cell.column.id !== isDisabledKey &&
                          !row.original[isDisabledKey] &&
                          isDisabledClassName
                      )}
                    >
                      {actionType === TableAction.EDIT && primaryKey !== cell.column.id ? (
                        <input className='cell-input' value={cell.value ?? ''} onChange={handleCellEdit(row.index, cell.column.id)} />
                      ) : actionType === TableAction.ADD ? (
                        index !== row.cells.length - 1 ? (
                          cell.render('Cell')
                        ) : (
                          <></>
                        )
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {/*
                      Inside it, call our renderRowSubComponent function. In reality,
                      you could pass whatever you want as props to
                      a component like this, including the entire
                      table instance. But for this example, we'll just
                      pass the row
                    */}
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
export default Table;
