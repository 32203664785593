import React, { useState } from 'react';
import UniliverLogo from 'assets/icons/unilever-logo-blue.svg';
import CustomButton from 'shared/components/Button-component/Button';
import infoIcon from 'assets/icons/info-icon.svg';
import role1 from 'assets/images/user-role/role-1.png';
import role2 from 'assets/images/user-role/role-2.png';
import role3 from 'assets/images/user-role/role-3.png';
import role4 from 'assets/images/user-role/role-4.png';
import role5 from 'assets/images/user-role/role-5.png';
import role6 from 'assets/images/user-role/role-6.png';
import role7 from 'assets/images/user-role/role-7.png';
import role8 from 'assets/images/user-role/role-8.png';
import role9 from 'assets/images/user-role/role-9.png';
import './roleSelectionFlow.scss'
import jwt_decode from "jwt-decode";
import { useCookies } from 'react-cookie';
import { UserRole } from 'enums/userRole';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProfileService from 'services/ProfileService/profile.service';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'routes/routeConstants/appRoutes';

const RoleSelectionFlow = () => {

    const [cookies] = useCookies();

    const [role, setRole] = useState<string>()
    const [avatar, setAvatar] = useState<string>()
    const { createProfile } = ProfileService()

    const roleDetails = [
        {
            id: "role1",
            alt: "role 1",
            src: role1
        },
        {
            id: "role2",
            alt: "role 2",
            src: role2
        },
        {
            id: "role3",
            alt: "role 3",
            src: role3
        },
        {
            id: "role4",
            alt: "role 4",
            src: role4
        },
        {
            id: "role5",
            alt: "role 5",
            src: role5
        },
        {
            id: "role6",
            alt: "role 6",
            src: role6
        },
        {
            id: "role7",
            alt: "role 7",
            src: role7
        },
        {
            id: "role8",
            alt: "role 8",
            src: role8
        },
        {
            id: "role9",
            alt: "role 9",
            src: role9
        }
    ]

    const navigate = useNavigate()

    const authToken = cookies.authToken
    const decoded: any = jwt_decode(authToken);
    const name = decoded.name

    const isSubmitButtonEnabled: boolean = Boolean(role && avatar)

    const customTooltip = <Tooltip className='text-left'>
        <div className='text-left'>
            <div>
                Admin- 1 line info
            </div>
            <div>
                SMEs-1 line info
            </div>
            <div>
                Leadership-1 line info
            </div>
            <div>
                Operations- 1 line info
            </div>
        </div>
    </Tooltip>

    return (
        <div className='role-selection-flow'>
            <div className='role-selection-container'>
                <div>
                    <img src={UniliverLogo} alt="unilever-logo" />
                    <span className='logo-text ml-4'>
                        E.D.I.T.H.
                    </span>
                </div>
                <div className='text-center'>
                    <div className='welcome-message'>
                        Welcome {name} !!
                    </div>
                    <div className='welcome-info mt-5'>
                        Please make selections to proceed ahead.
                    </div>
                </div>
                <div className='text-center'>
                    <div className='role-header-title'>Select your role
                        <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            overlay={customTooltip}
                        >
                            <img src={infoIcon} alt="info-icon" className='ml-4 cursor-pointer' />
                        </OverlayTrigger>
                    </div>
                    <div className='d-flex justify-content-center role-content-options'>
                        <CustomButton
                            secondary={role !== UserRole.Admin}
                            disabled
                            SecondaryPressed={role === UserRole.Admin}
                            onClick={() => setRole(UserRole.Admin)}
                        >
                            Admin
                        </CustomButton>
                        <CustomButton
                            secondary={role !== UserRole.SME}
                            SecondaryPressed={role === UserRole.SME}
                            disabled
                            onClick={() => setRole(UserRole.SME)}
                        >
                            SME
                        </CustomButton>
                        <CustomButton
                            secondary={role !== UserRole.Leadership}
                            SecondaryPressed={role === UserRole.Leadership}
                            onClick={() => setRole(UserRole.Leadership)}
                        >
                            Leadership
                        </CustomButton>
                        <CustomButton
                            secondary={role !== UserRole.Operation}
                            SecondaryPressed={role === UserRole.Operation}
                            onClick={() => setRole(UserRole.Operation)}
                        >
                            Operation
                        </CustomButton>
                    </div>
                </div>
                <div className='text-center divider-section'></div>
                <div className='avatar-header'>
                    Choose your Avatar
                </div>
                <div>
                    <div className='role-image-container'>
                        {roleDetails.map((item, index) =>
                            <div key={index} className='role-checkbox-container'>
                                <input
                                    type="radio"
                                    name="role"
                                    id={item.id}
                                    value={item.id}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAvatar(e.target.value)}
                                />
                                <label htmlFor={item.id}>
                                    <img src={item.src} alt={item.alt} />
                                </label>
                            </div>
                        )}
                    </div>
                </div>
                <div className='button-container'>
                    <CustomButton
                        secondary
                        onClick={() => {
                            navigate(AppRoutes.ROOT)
                         }}
                    >
                        Cancel
                    </CustomButton>
                    <CustomButton
                        disabled={!isSubmitButtonEnabled}
                        primary={isSubmitButtonEnabled}
                        onClick={async () => {
                            await createProfile({
                                role,
                                avatar
                            })
                            window.location.href='/home'
                        }}
                    >
                        Next
                    </CustomButton>
                </div>
            </div>
        </div>
    )
}

export default RoleSelectionFlow