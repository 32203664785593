import { PublicClientApplication } from '@azure/msal-browser';
import { userProfileRead } from 'constants/scope';

export const msalConfig = {
  tenant: process.env.REACT_APP_TENANT_ID as string,
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: process.env.REACT_APP_AUTHORITY as string, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_REDIRECT_URL as string
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE as string]
};

export const userDetailsRequest = {
  scopes: [userProfileRead]
};
