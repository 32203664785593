import React from 'react';
import './Databar.scss';
import { Loader } from '@mantine/core';
interface Props {
  DataProvider: string | JSX.Element
  TotalRecords: number | JSX.Element
  successStatus: number | JSX.Element
  loadedOn: string | JSX.Element
}
const Databar: React.FC<Props> = ({ DataProvider, TotalRecords, successStatus, loadedOn }: Props) => {
  return (
    <div className='container'>
      <div className='item-container'>
        <div className='item'>
          <h1>Data Provider: </h1>
        </div>
        <div className='item'>
          <p>{DataProvider}</p>
        </div>
      </div>
      <div className='item-container'>
        <div className='item'>
          <h1>Total Records: </h1>
        </div>
        <div className=' item'>
          <p>{TotalRecords}</p>
        </div>
      </div>
      <div className='item-container'>
        <div className='item'>
        <h1>Success Status: </h1>
        </div>
        <div className=' item'>
        <p className='success'>{successStatus}%</p>
        </div>
      </div>
      <div className='item-container'>
        <div className='item'>
        <h1>Loaded On: </h1>
        </div>
        <div className='item'>
        <p>{loadedOn}</p>
        </div>
      </div>
    </div>
  );
};

export default Databar;
