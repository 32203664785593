import React from 'react';
import Select, { components, ControlProps, StylesConfig } from 'react-select';

const ControlWithLabel = ({ children, ...props }: any) => {
  return (
    <components.Control {...props}>
      <span style={{ color: '#808080', fontWeight: '600', fontSize: '14px' }}>{props.selectProps?.label} : </span>
      {children}
    </components.Control>
  );
};

const ControlWithoutLabel = ({ children, ...props }: any) => <components.Control {...props}>{children}</components.Control>;

const ReactSelect = (props: any) => {
  const styles: StylesConfig<any, false> = {
    control: (css: any) => ({
      ...css,
      paddingLeft: '1rem',
      borderRadius: 0,
      width: '100%',
      minHeight: '42px',
      border: '1px solid #008651'
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 99 }),
    menu: (provided) => ({ ...provided, wordBreak: 'break-all', fontSize: '14px'}),
    option: (styles) => {
      return {
        ...styles,
        ':hover': {
          backgroundColor: '#57866a',
          color: 'white'
        }
      };
    } ,
  };

  const options = props?.options?.map((ele: any) => {
    return { value: ele.name, label: ele.name };
  });

  return (
    <>
      <Select
        {...props}
        components={{ Control: props?.hideLabel ? ControlWithoutLabel : ControlWithLabel }}
        isSearchable={props?.search ?? true}
        options={options}
        styles={styles}
        isClearable={props?.clear ?? true}
        backspaceRemovesValue={false}
        placeholder='Select'
        isLoading={props?.isLoading}
        onChange={(e: any) => props.onValueChange(e ? e.value : null)}
        menuPortalTarget={document.body}
        defaultValue={props?.defaultValue}
        value={props?.value}
      />
    </>
  );
};

export default ReactSelect;
