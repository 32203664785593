import { displayDateTimeFormat } from "constants/formats";
import moment from "moment";
import React from "react";


export const FactUnprocessedColumns =[
    {
        header: 'FileName',
        accessorKey: 'Filename',
        size: 120,
        enableColumnFilter :false,
        enableSorting:false,
      },
      {
        header: 'Tag',
        accessorKey: 'Tag',
        size: 120
      },
      {
        header: 'Externaldesc',
        accessorKey: 'Externaldesc',
        size: 140
      },
      {
        header: 'Created On',
        accessorKey: 'Createdon',
        enableColumnFilter :false,
        size: 140,
        Cell: ({ cell }: any) => (
          <div role='link' tabIndex={0}>
            {moment.utc(cell.value).format(displayDateTimeFormat)}
          </div>
        )
      },
      // {
      //   header: 'Fact Type',
      //   accessorKey: 'FactType',
      //   size: 120
      // },
      // {
      //   header: 'Metadata Flag',
      //   accessorKey: 'Metadataflag',
      //   size: 140
      // }
]