import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import DashboardContainer from 'shared/components/DashboardContainer-component/DashboardContainer';
import './HomeView.scss';
import PieChartCard from 'shared/components/PieChartCard';
import LineChartCard from 'shared/components/LineChartCard';
import AreaChartCard from 'shared/components/AreaChartCard';
import TasksDueHome from 'shared/components/TasksDueHome';
import NewEDDropdown from 'shared/components/NewEDDropdown';
import EDDatePicker from 'shared/components/EDDatePicker';
import MetaService from 'services/MetaService/meta.service';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { MetaData } from 'models/MetaData/metaData.model';
import ProfileService from 'services/ProfileService/profile.service';

const OperationGraph = () => {
  const { getUserName } = ProfileService();

  const { fetchMeta, loading } = MetaService();

  const [countryMeta, setCountryMeta] = useState<MetaData[]>([]);

  const [startDate, setStartDate] = React.useState<string>();

  const [endDate, setEndDate] = React.useState<string>();

  const [country, setCountry] = useState<string>('');

  const handleCountryChange = (value: string) => {
    setCountry(value);
  };

  useEffect(() => {
    fetchMeta(ApiRoutes.COUNTRY_META, setCountryMeta, MetaData);
  }, []);

  const data1 = [
    { name: 'Rejected', value: 10 },
    { name: 'Accepted', value: 60 },
    { name: 'Pending', value: 12 }
  ];
  const data2 = [
    { name: 'Completed', value: 10 },
    { name: 'Rejected', value: 16 }
  ];
  const data3 = [
    { name: 'Group B', value: 60 },
    { name: 'Group A', value: 10 }
  ];

  return (
    <DashboardContainer>
      <Container fluid className='home-view'>
        {/* Top Row */}
        <Row>
          <Col sm={12} className='top-row-container'>
            <Col sm={6}>
              <div className='home-page-title'>
                <h2>Welcome {getUserName()}!!</h2>
                <p>Below are the analysis for this week.</p>
              </div>
            </Col>
            <Col sm={6}>
              <div className='filters-container'>
                {/* <span className='filter-text'>Filters:</span> */}
                <div className='filter-container'>
                  <EDDatePicker
                    onDateSelected={(startDate, endDate) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                  />
                </div>
                <div className='filter-container'>
                  <NewEDDropdown
                    loading={loading}
                    optionItems={countryMeta}
                    value={country}
                    currentItemIndex={2}
                    resetFunc={() => setCountry('')}
                    labelText='Country'
                    defaultValue='All(22)'
                    updateValue={handleCountryChange}
                  />
                </div>
              </div>
            </Col>
          </Col>
        </Row>
        {/* Pie Cards Row */}
        <Row className='pie-row'>
          <Col sm={4} className='pl-0'>
            <PieChartCard
              data={data1}
              cardTitle='Files Sent For Approval'
              totalValue={82}
              footer1Heading='Rejected'
              footer1Value={10}
              footer2Heading='Approved'
              footer2Value={60}
              footer3Heading='Pending'
              footer3Value={12}
            />
          </Col>
          <Col sm={4}>
            <PieChartCard
              data={data2}
              cardTitle='Tasks Received'
              totalValue={26}
              footer1Heading='Completed'
              footer1Value={10}
              footer2Heading='Pending'
              footer2Value={16}
            />
          </Col>
          <Col sm={4} className='pr-0'>
            <PieChartCard
              data={data3}
              cardTitle='Feed Delivery'
              totalValue={70}
              footer1Heading='Due'
              footer1Value={10}
              footer2Heading='Available'
              footer2Value={60}
            />
          </Col>
        </Row>
        {/* Line Chart Row */}
        <Row className='line-chart-row'>
          <Col sm={8} className='pl-0'>
            <LineChartCard startDate={startDate} endDate={endDate} country={country} />
          </Col>
          <Col sm={4} className='pr-0'>
            <TasksDueHome />
          </Col>
        </Row>
        {/* Area Chart Row */}
        <Row className='area-chart-row'>
          <Col sm={12} className='pl-0 pr-0'>
            <AreaChartCard startDate={startDate} endDate={endDate} country={country} />
          </Col>
        </Row>
      </Container>
    </DashboardContainer>
  );
};

export default OperationGraph;
