import { ConfidenceMapping } from 'models/ConfidenceMapping/confidenceMapping.model';
import { HarmonisationStatus } from 'models/HarmonisationStatus/harmonisationStatus.model';
import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import moment from 'moment';

const DashboardService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [volatilityMetrics, setVolatilityMetrics] = useState<any>();
  const [volatilityUniqueColumns, setVolatilityUniqueColumns] = useState<string[]>([]);
  const [harmonisedStatus, setHarmonisedStatus] = useState<HarmonisationStatus[]>([]);
  const [confidenceMapping, setConfidenceMapping] = useState<ConfidenceMapping[]>([]);
  const [totalRequests, setTotalRequests] = useState<number>(0);

  const fetchVolatilityMetrics = async (params?: {
    filter_by_provider?: string;
    start_date?: string;
    end_date?: string;
    filter_by_country?: string;
  }) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.DASHBOARD_VOLATILITY_METRICS, { params });
      const unsortedObj = response.data.mappings;
      let data: any = [];
      const sortedKeys = Object.keys(unsortedObj).sort();
      const uniqueColumns = response.data.unique_column;
      let total = 0;
      sortedKeys.forEach((key: string) => {
        uniqueColumns.forEach((uniqueItem: string) => {
          if (!(uniqueItem in unsortedObj[key])) {
            unsortedObj[key][uniqueItem] = 0;
          }
          if (unsortedObj[key]['name']) unsortedObj[key]['name'] = moment(unsortedObj[key]['name']).format(`D MMM,YY`);
          total += unsortedObj[key][uniqueItem];
        });
        data.push(unsortedObj[key]);
      });
      setTotalRequests(total);

      setVolatilityUniqueColumns(uniqueColumns);
      setVolatilityMetrics(data);
      setLoading(false);
    } catch (error) {}
  };

  const fetchFileHarmonisationStatus = async (params?: {
    filter_by_provider?: string;
    start_date?: string;
    end_date?: string;
    filter_by_country?: string;
  }) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.DASHBOARD_FILE_HARMONISATION);
      const status = deserialize(HarmonisationStatus, response.data) as unknown as HarmonisationStatus[];
      setHarmonisedStatus(status);
      setLoading(false);
    } catch (error) {}
  };

  const fetchConfidenceMapping = async (params?: {
    filter_by_provider?: string;
    start_date?: string;
    end_date?: string;
    filter_by_country?: string;
  }) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.DASHBOARD_CONFIDENCE_MAPPING);
      const data = deserialize(ConfidenceMapping, response.data) as ConfidenceMapping[];
      setConfidenceMapping(data);
      let total = 0;
      data.forEach((confidence) => {
        total += confidence?.high ?? 0;
        total += confidence?.medium ?? 0;
        total += confidence?.low ?? 0;
      });
      setTotalRequests(total);
      setLoading(false);
    } catch (error) {
      console.error('Error is ', error);
    }
  };

  return {
    loading,
    volatilityMetrics,
    volatilityUniqueColumns,
    confidenceMapping,
    harmonisedStatus,
    fetchVolatilityMetrics,
    fetchFileHarmonisationStatus,
    fetchConfidenceMapping,
    totalRequests
  };
};

export default DashboardService;
