export const periodMetadataColumn = [
  { header: 'ID', accessorKey: 'Id', minWidth: 100, enableResizing: true, enableColumnDragging: true, enableEditing: false },

  { header: 'Data Provider', accessorKey: 'Data_Provider', minWidth: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'Country', accessorKey: 'Country1', minWidth: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'MarketName', accessorKey: 'MarketName', minWidth: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'Direct Indirect', accessorKey: 'Direct_Indirect', minWidth: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'Source File Path', accessorKey: 'Source_File_Path', maxSize: 400, enableResizing: true, enableColumnDragging: true },

  { header: 'FileType', accessorKey: 'FileType', minWidth: 150, enableResizing: true, enableColumnDragging: true },

  { header: 'Periodicity', accessorKey: 'Periodi', minWidth: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'ISO Country', accessorKey: 'ISOCountry', minWidth: 130, enableResizing: true, enableColumnDragging: true },

  { header: 'File Split based On Dimensions', accessorKey: 'FileSplitbasedOnDimensions', minWidth: 220, enableResizing: true, enableColumnDragging: true },

  { header: 'Filepath', accessorKey: 'Filepath', minWidth: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'Date Format', accessorKey: 'DateFormat', minWidth: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'Week Start Day Country', accessorKey: 'WeekStartDayCountry', minWidth: 200, enableResizing: true, enableColumnDragging: true },

  { header: 'Date For Period', accessorKey: 'DateForPeriod', minWidth: 200, enableResizing: true, enableColumnDragging: true },
]
