export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,

  //MS Graph API
  MS_GRAPH_BASE_URL: 'https://graph.microsoft.com/v1.0/',
  MS_GRAPH_USER_DETAILS: 'me',
  MS_GRAPH_USER_PHOTO: 'me/photo/$value',

  LIBRARY: 'library',
  VALIDATION_DASHBOARD: 'smart-mapping/dashboard',
  SMART_MAPPING_LIST: 'smart-mapping',
  SMART_MAPPING_LIST_COUNT: 'smart-mapping/count',
  INDIVIDUAL_SMART_MAPPING: 'smart-mapping/:id',
  INDIVIDUAL_SMART_MAPPING_POS: 'smart-mapping/pos/:id',
  SMART_MAPPING_MAPPED: 'smart-mapping/:id/:key',
  SMART_MAPPING_MAPPED_COUNT: 'smart-mapping/:id/:key/count',
  SMART_MAPPING_UN_MAPPED: 'smart-mapping/:id/low',
  SMART_MAPPING_UN_PROCESSED_RECORDS: 'smart-mapping/:id/product/unprocessed',
  SMART_MAPPING_UN_PROCESSED_FACT: 'smart-mapping/fact/details/unprocessed',
  SMART_MAPPING_UN_PROCESSED_FACT_COUNT: 'smart-mapping/fact/details/unprocessed/count',
  SMART_MAPPING_UN_PROCESSED_MARKET: 'smart-mapping/:id/market/unprocessed',
  SMART_MAPPING_UN_PROCESSED_RECORDS_COUNT: 'smart-mapping/:id/product/unprocessed/count',
  SMART_MAPPING_UN_PROCESSED_MARKET_COUNT: 'smart-mapping/:id/market/unprocessed/count',
  SMART_MAPPING_UN_PROCESSED_RECORDS_DOWNLOAD: 'smart-mapping/:id/unprocessed/download',
  SMART_MAPPING_LOW_RECORD_SUGGESTIONS: 'smart-mapping/:id/suggestion',
  COUNTRY_META: 'meta/country',
  PROVIDER_META: 'meta/provider',
  CATEGORY_META: 'meta/category',
  DQ_PROVIDER_META: 'meta/dq-provider',
  DQ_DATASET_META: 'meta/dq-dataset',
  VOLATILITY_LIST: 'file-volatility',
  VOLATILITY_LIST_COUNT: 'file-volatility/count',
  VOLATILITY_DASHBOARD: 'file-volatility/dashboard/',
  INIDVIDUAL_FILE_VOLATILITY: 'file-volatility/:id',
  VOLATILITY_MAPPING: 'file-volatility/:id/mappings',
  VOLATILITY_MAPPING_DETAILED: 'file-volatility/:id/details',
  VOLATILITY_COUNTRY_META: 'smart-mapping/meta/country',
  VOLATILITY_PROVIDER_META: 'smart-mapping/meta/provider',
  VOLATILITY_CATEGORY_META: 'smart-mapping/meta/category',
  PROFILES: 'auth/profile',
  DASHBOARD_VOLATILITY_METRICS: 'dashboard/volatility-file-metrics',
  DASHBOARD_FILE_HARMONISATION: 'dashboard/file-harmonization-status',
  DASHBOARD_CONFIDENCE_MAPPING: 'dashboard/confidence-level',
  UPDATE_TARGET_COLUMN: 'file-volatility/:id/target-column',
  HOME_PROVIDER_META: 'dashboard/meta/provider',

  //fact routes
  VALIDATION_FACT: 'smart-mapping/fact/summary',
  VALIDATION_FACT_COUNT: 'smart-mapping/fact/summary/count',
  CATEGORY_FACT: 'smart-mapping/fact/meta/category',
  COUNTRY_FACT: 'smart-mapping/fact/meta/country',
  PROVIDER_FACT: 'smart-mapping/fact/meta/provider',
  VALIDATION_FACT_DETAIL: 'smart-mapping/fact/details',
  VALIDATION_FACT_DETAIL_COUNT: 'smart-mapping/fact/details/count',
  FILE_VOLATILITY_MAPPING_FACT: 'file-volatility/fact',
  FILE_VOLATILITY_MAPPING_PERIOD: 'file-volatility/period',
  FACT_FILE_DETAIL: 'smart-mapping/fact/',
  FILE_VOLATILITY_MAPPING_FACT_LOW: 'smart-mapping/fact/details/low-map', //external description fetch,
  VALIDATION_FACT_DETAIL_UPDATE: 'smart-mapping/fact/details/low',
  UPDATE_FV_VALUES: 'file-volatility',

  // Period
  VALIDATION_PERIOD_MAPPED_DETAILS: 'smart-mapping/:id/period/mapped',
  VALIDATION_PERIOD_MAPPED_DETAILS_COUNT: 'smart-mapping/:id/period/mapped/count',
  VALIDATION_MARKET_MAPPED_DETAILS: 'smart-mapping/:id/market/:confidenceLevel',
  VALIDATION_MARKET_MAPPED_DETAILS_COUNT: 'smart-mapping/:id/market/:confidenceLevel/count',

  VALIDATION_RMS_MAPPED_DETAILS: 'smart-mapping/other-rms/:id/detail',
  VALIDATION_RMS_MAPPED_DETAILS_COUNT: 'smart-mapping/other-rms/:id/detail/count',

  // sideBar remapping
  FACT_DROPDOWN: 'remapping/fact/',
  PRODUCT_DROPDOWN: 'remapping/product/',
  Market_DROPDOWN: 'remapping/market/',
  PERIOD_DROPDOWN: 'remapping/period/',

  // sideBar remapping POS
  PRODUCT_DROPDOWN_POS: 'remapping/pos/product/',
  PERIOD_DROPDOWN_POS: 'remapping/pos/period/',
  MARKET_DROPDOWN_POS: 'remapping/pos/market/',
  FACT_DROPDOWN_POS: 'remapping/pos/fact/',

  // sideBar remapping RMS
  PERIOD_DROPDOWN_RMS: 'remapping/other-rms/period/',
  MARKET_DROPDOWN_RMS: 'remapping/other-rms/market/',
  FACT_DROPDOWN_RMS: 'remapping/other-rms/fact/',
  PRODUCT_DROPDOWN_RMS: 'remapping/other-rms/product/',

  // Dq checks
  DQ_CHECKS: 'dq-checks',
  DQ_CHECKS_COUNT: 'dq-checks/count',
  DQ_CHECKS_DELIVERY_PERIOD_META: 'dq-checks/meta/delivery-period',
  DQ_CHECKS_DELIVERY_COUNTRY_META: 'dq-checks/meta/country',
  DQ_CHECKS_DELIVERY_PROVIDER_META: 'dq-checks/meta/data-provider',
  DQ_CHECKS_DELIVERY_CATEGORY_META: 'dq-checks/meta/category',
  DQ_CHECKS_TOTAL_CELL: 'dq-checks/card/total-cells',
  DQ_CHECKS_TOTAL_FILES: 'dq-checks/card/total-files',
  DQ_CHECKS_FV_SUCCESS_RATE: 'dq-checks/card/fv-success-rate',
  DQ_CHECKS_AVERAGE_FV_ERROR: 'dq-checks/card/average-fv-error',
  DQ_CHECKS_DQ_SCORE: 'dq-checks/card/dq-score',
  DQ_CHECKS_TOTAL_DQ_SCORE: 'dq-checks/card/total-dq-score',
  DQ_CHECKS_REPORT: 'dq-checks/report',
  DOWNLOAD_FV_LOAD_STATISTICS: 'file-volatility/download/records',

  //Download
  DOWNLOAD_PRODUCT_EXCEL: 'smart-mapping/:id/download/product',
  DOWNLOAD_FACT_EXCEL: 'smart-mapping/:id/download/fact',
  DOWNLOAD_MARKET_EXCEL: 'smart-mapping/:id/download/market',
  DOWNLOAD_PERIOD_EXCEL: 'smart-mapping/:id/download/period',

  // Download
  DOWNLOAD_MARKET_RMS_EXCEL: 'smart-mapping/other-rms/download/Market',
  DOWNLOAD_FACT_RMS_EXCEL: 'smart-mapping/other-rms/download/Fact',
  DOWNLOAD_PERIOD_RMS_EXCEL: 'smart-mapping/other-rms/download/Period',
  DOWNLOAD_PRODUCT_RMS_EXCEL: 'smart-mapping/other-rms/download/Product',

  //POS
  SMART_MAPPING_POS_PRODUCT: 'smart-mapping/product/pos/:id/:confidence',
  SMART_MAPPING_POS_PRODUCT_COUNT: 'smart-mapping/product/pos/:id/:confidence/count',
  SMART_MAPPING_POS_PRODUCT_LOW_SUGGESTION: 'smart-mapping/product/pos/low/:id/suggestion',
  SMART_MAPPING_POS_PERIOD: 'smart-mapping/period/pos/:id/:confidence',
  SMART_MAPPING_POS_PERIOD_COUNT: 'smart-mapping/period/pos/:id/:confidence/count',
  SMART_MAPPING_POS_MARKET: 'smart-mapping/market/pos/:id/:confidence',
  SMART_MAPPING_POS_MARKET_COUNT: 'smart-mapping/market/pos/:id/:confidence/count',
  SMART_MAPPING_POS_FACT: 'smart-mapping/fact/pos/:id/:confidence',
  SMART_MAPPING_POS_FACT_COUNT: 'smart-mapping/fact/pos/:id/:confidence/count',
  DOWNLOAD_POS_PRODUCT_EXCEL: 'smart-mapping/pos/:id/download/product',
  DOWNLOAD_POS_PERIOD_EXCEL: 'smart-mapping/pos/:id/download/period',
  DOWNLOAD_POS_MARKET_EXCEL: 'smart-mapping/pos/:id/download/market',
  DOWNLOAD_POS_FACT_EXCEL: 'smart-mapping/pos/:id/download/fact',

  //Admin Configuration
  SML_PCAT: '/admin/sml-pcat',
  SML_PCAT_COUNT: '/admin/sml-pcat/count',
  SML_PCAT_CATEGORY_META: '/meta/sml-pcat/category',
  SML_PCAT_MARKET_META: '/meta/sml-pcat/market',
  SML_PCAT_SEGMENT_META: '/meta/sml-pcat/segment',

  //Critical Attribute
  CRITICAL_ATTRIBUTE: '/admin/critical-attributes',
  CRITICAL_ATTRIBUTE_COUNT: '/admin/critical-attributes/count',
  CRITICAL_ATTRIBUTE_GLOBAL_META: '/admin/critical-attributes/global',
  CRITICAL_ATTRIBUTE_LOCAL_META: '/admin/critical-attributes/local',
  CRITICAL_ATTRIBUTE_MARKET_META: '/admin/critical-attributes/market',
  TRIGGER_CA_PIPELINE: '/file-volatility/ca-pipeline-trigger',

  //FACT
  FACT_META: 'admin/fact',
  FACT_META_COUNT: '/admin/fact/count',
  FACT_META_CELL: '/admin/fact/meta/cell',
  FACT_META_COUNTRY_NAME: '/admin/fact/meta/country',
  FACT_META_MARKET_NAME: '/admin/fact/meta/market',

  //Cell Control
  CELL_CONTROL: '/admin/cell-control',
  CELL_CONTROL_COUNT: '/admin/cell-control/count',
  CELL_CONTROL_STATUS: '/admin/cell-control/status',
  CELL_CONTROL_CATEGORY_META: '/meta/cell-control/category',
  CELL_CONTROL_PROVIDER_META: '/meta/cell-control/provider',
  CELL_CONTROL_COUNTRY_META: '/meta/cell-control/country',

  //Market Metadata
  MARKET_META: 'admin/market',
  MARKET_META_COUNT: '/admin/market/count',

  // POC
  SML_PCAT_POC: 'admin/poc/sml-pcat',
  SML_PCAT_POC_COUNT: 'admin/poc/sml-pcat/count',
  SML_PCAT_POC_INSERT: 'admin/poc/sml-pcat/bulkcreate',
 
   //PERIOD
   PERIOD_META: '/admin/period',
   PERIOD_META_COUNT: '/admin/period/count',

  // PRODUCT
  SMART_MAPPING_PRODUCT_NIELSEN:'smart-mapping/product/mapped',
  SMART_MAPPING_PRODUCT_POS:'smart-mapping/product/pos/mapped',
  SMART_MAPPING_PRODUCT_NIELSEN_COUNT:'smart-mapping/product/mapped/count',
  SMART_MAPPING_PRODUCT_POS_COUNT:'smart-mapping/product/pos/mapped/count',
  SMART_MAPPING_PRODUCT_NIELSEN_DOWNLOAD:'smart-mapping/product/mapped/download',
  SMART_MAPPING_PRODUCT_POS_DOWNLOAD:'smart-mapping/product/pos/mapped/download',
  
  SMART_MAPPING_UNPROCESSED_PRODUCT_NIELSEN:'smart-mapping/product/unprocessed',
  SMART_MAPPING_UNPROCESSED_PRODUCT_NIELSEN_COUNT:'smart-mapping/product/unprocessed/count',
  SMART_MAPPING_UNPROCESSED_PRODUCT_POS:'smart-mapping/product/pos/unprocessed',
  SMART_MAPPING_UNPROCESSED_PRODUCT_POS_COUNT:'smart-mapping/product/pos/unprocessed/count',
  SMART_MAPPING_UNPROCESSED_PRODUCT_NIELSEN_DOWNLOAD:'smart-mapping/product/unprocessed/download',
  SMART_MAPPING_UNPROCESSED_PRODUCT_POS_DOWNLOAD:'smart-mapping/product/pos/unprocessed/download',
  
  // PERIOD
  SMART_MAPPING_PERIOD_NIELSEN:'smart-mapping/period/mapped',
  SMART_MAPPING_PERIOD_POS:'smart-mapping/period/pos/mapped',
  SMART_MAPPING_PERIOD_NIELSEN_COUNT:'smart-mapping/period/mapped/count',
  SMART_MAPPING_PERIOD_POS_COUNT:'smart-mapping/period/pos/mapped/count',
  SMART_MAPPING_PERIOD_NIELSEN_DOWNLOAD:'smart-mapping/period/mapped/download',
  SMART_MAPPING_PERIOD_POS_DOWNLOAD:'smart-mapping/period/pos/mapped/download',

  // MARKET
  SMART_MAPPING_MARKET_NIELSEN:'smart-mapping/market/mapped',
  SMART_MAPPING_MARKET_POS:'smart-mapping/market/pos/mapped',
  SMART_MAPPING_MARKET_NIELSEN_COUNT:'smart-mapping/market/mapped/count',
  SMART_MAPPING_MARKET_POS_COUNT:'smart-mapping/market/pos/mapped/count', 
  SMART_MAPPING_MARKET_NIELSEN_DOWNLOAD:'smart-mapping/market/mapped/download',
  SMART_MAPPING_MARKET_POS_DOWNLOAD:'smart-mapping/market/pos/mapped/download',

  SMART_MAPPING_UNPROCESSED_MARKET_NIELSEN:'smart-mapping/market/unprocessed',
  SMART_MAPPING_UNPROCESSED_MARKET_NIELSEN_COUNT:'smart-mapping/market/unprocessed/count',
  SMART_MAPPING_UNPROCESSED_MARKET_NIELSEN_DOWNLOAD:'smart-mapping/market/unprocessed/download',

  // FACT
  SMART_MAPPING_FACT_NIELSEN:'smart-mapping/fact/mapped',
  SMART_MAPPING_FACT_POS:'smart-mapping/fact/pos/mapped',
  SMART_MAPPING_FACT_NIELSEN_COUNT:'smart-mapping/fact/mapped/count',
  SMART_MAPPING_FACT_POS_COUNT:'smart-mapping/fact/pos/mapped/count',  
  SMART_MAPPING_FACT_NIELSEN_DOWNLOAD:'smart-mapping/fact/mapped/download',
  SMART_MAPPING_FACT_POS_DOWNLOAD:'smart-mapping/fact/pos/mapped/download',

  SMART_MAPPING_UNPROCESSED_FACT_NIELSEN:'smart-mapping/fact/unprocessed',
  SMART_MAPPING_UNPROCESSED_FACT_NIELSEN_COUNT:'smart-mapping/fact/unprocessed/count',
  SMART_MAPPING_UNPROCESSED_FACT_NIELSEN_DOWNLOAD:'smart-mapping/fact/unprocessed/download'
};
