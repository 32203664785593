import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import './Breadcrumb-component.scss'
import leftIcon from 'assets/icons/left-icon.svg';
import BackArrow from 'assets/icons/back-arrow.svg';

interface linkObj {
  link: string
  active: boolean,
  onClick?: ()=>void
}
interface Props {
  links: linkObj[]
  section?:boolean
}
const BreadcrumbComponent: React.FC<Props> = ({ links, section }: Props) => {
  return (
    <div>
      <Breadcrumb className='breadcrumb-component'>
        {section ? <img className='card-template-header-left-icon' src={BackArrow} alt='back-arrow' /> :<img className='card-template-header-left-icon' src={leftIcon} alt='left-icon' />}
        {links.map((item, index) => {
          return (
            item.active ? <Breadcrumb.Item onClick={item?.onClick} key={index} active className='breadcrumb-component-item'>{item.link}</Breadcrumb.Item> :
              <Breadcrumb.Item key={index} onClick={item?.onClick} className='breadcrumb-component-item'>{item.link}</Breadcrumb.Item>
          );
        })}


      </Breadcrumb>
    </div>
  );
}

export default BreadcrumbComponent;
