import React from 'react';

export const FactMappedColumn = [
  {
    header: 'External',
    accessorKey: 'External',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'External Long Description',
        accessorkey: 'Externaldesc',
        size: 220,
        id: 'Externaldesc'
      },
      // {
      //   header: 'External Tag',
      //   accessorKey: 'Tag',
      //   size: 300
      // },
      {
        header: 'External Short Description',
        accessorKey: 'Short',
        size: 300
      },
      // {
      //   header: 'Currency',
      //   accessorKey: 'Currency',
      //   size: 120
      // },
      // {
      //   header: 'Precision',
      //   accessorKey: 'Precision',
      //   size: 120
      // },
      // {
      //   header: 'Denominator',
      //   accessorKey: 'Denominator',
      //   size: 120
      // },
      // {
      //   header: 'Unique TAG',
      //   accessorKey: 'Uniqueidentifier',
      //   size: 120
      // }
    ]
  },
  {
    header: 'Internal',
    accessorKey: 'internal',
    enableColumnFilter: false,
    enableSorting: false,
    columns: [
      {
        header: 'Internal Fact Description',
        accessorKey: 'Harmonizedname',
        size: 240
      },
      {
        header: 'Internal Fact Type',
        accessorKey: 'Facttype',
        size: 200
      }
    ]
  }
];
