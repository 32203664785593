import { displayDateTimeFormat } from "constants/formats";
import moment from "moment";
import React from "react";


export const MarketUnprocessedColumns = [
    {
      header: 'FileName',
      accessorKey: 'FileName',
      minWidth:300,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: 'Tag',
      accessorKey: 'Tag',
      minWidth:300,
    },
    {
      header: 'Long',
      accessorKey: 'Long',
      minWidth:300,
    },
    {
      header: 'HierName',
      accessorKey: 'HierName',
      minWidth:300,
    },
    // {
    //   header: 'HierLevelName',
    //   accessorKey: 'HierLevelName',
    //   minWidth:300,
    // },
    // {
    //   header: 'Country',
    //   accessorKey: 'Country',
    //   minWidth:200,
    // },
    // {
    //   header: 'Category',
    //   accessorKey: 'Category',
    //   minWidth:200,
    // },
    // {
    //   header: 'Cell',
    //   accessorKey: 'Cell',
    //   minWidth:280,
    // },
    // {
    //   header: 'Created On',
    //   accessorKey: 'Createdon',
    //   enableColumnFilter :false,
    //   minWidth:240,
    //   Cell: ({ cell }: any) => (
    //     <div role='link' tabIndex={0}>
    //       {moment.utc(cell.value).format(displayDateTimeFormat)}
    //     </div>
    //   )
    // }
  ];